<template>
  <div @click="pause" style="height: 100%">
    <vue-aliplayer-v2
        :style="`height: 100%`"
        ref="VueAliplayerV2"
        v-bind="$attrs"
        v-on="$listeners"
    />
  </div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2';

export default {
  name: 'videoCom',
  props: ['height', 'cover'],
  components: { VueAliplayerV2: VueAliplayerV2.Player },
  data() {
    return {
      cssLink: {
        required: false,
        type: [String],
        default: `https://g.alicdn.com/de/prismplayer/2.16.0/skins/default/aliplayer-min.css`,
      },
      scriptSrc: {
        required: false,
        type: [String],
        default: `https://g.alicdn.com/de/prismplayer/2.16.0/aliplayer-min.js`,
      },
    };
  },
  mounted() {
    this.$refs.VueAliplayerV2.setCover(this.cover);
    console.log(this.$refs.VueAliplayerV2, 'this.$refs.VueAliplayerV2');
    // this.$refs.VueAliplayerV2.on('play', () => {
    //   console.log('this.$refs.VueAliplayerV2--->Play');
    // });
  },
  methods: {
    pause() {
      console.log(this.$refs.VueAliplayerV2.getStatus());
      // this.$refs.VueAliplayerV2.pause();
    },
    // mouseover() {
    //   const playBigBtn = document.querySelector('.prism-big-play-btn');
    //   playBigBtn.style = 'display:block';
    // },
    // mouseout() {
    //   const playBigBtn = document.querySelector('.prism-big-play-btn');
    //   playBigBtn.style = 'display:none';
    // },
  },
};
</script>

<style scoped>

</style>
