<template>
  <van-image-preview v-model="popupShow" :images="images" position="bottom" :closeable="true"
                     @click-overlay="overlayHide" @closed="overlayHide" :overlay-style="{opacity:1}"
                     :title-top="$t(titleTop)">
    <!--   <div class="swipeBannerContainer">-->
    <!--     <van-swipe :autoplay="3000" indicator-color="white">-->
    <!--       <van-swipe-item v-for="(item,index) in banners" :key="index">-->
    <!--         <img :src="item.imgUrl" @click="openTab(item)"/>-->
    <!--         <div v-if="item.title" class="title">{{ item.title }}</div>-->
    <!--       </van-swipe-item>-->
    <!--     </van-swipe>-->
    <!--   </div>-->
  </van-image-preview>
</template>

<script>
// import Popup from '@/components/popup/popup';
import { mapGetters } from 'vuex';
import { getBanners } from '@/api';

export default {
  name: 'bannerModel.vue',
  components: {
    // Popup,
  },
  data() {
    return {
      popupShow: false,
      banners: [],
      titleTop: '',
    };
  },
  computed: {
    images() {
      return this.banners.map(item => item.imgUrl);
    },
    ...mapGetters(['currentRoomId']),
  },
  methods: {
    overlayShow(type) {
      console.log(type, 'khiugftdr');
      if (type === 'hytq') {
        this.titleTop = 'header.Privileges';
      } else {
        this.titleTop = 'header.Profit';
      }
      this.popupShow = true;
      if (this.currentRoomId) {
        getBanners({ roomId: this.currentRoomId, extraFlag: type })
            .then((res) => {
              this.banners = res.data;
            });
      }
    },
    overlayHide() {
      this.banners = [];
      this.popupShow = false;
    },
    openTab(data) {
      if (data.link) {
        window.open(data.link, '_blank');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.swipeBannerContainer {
  padding: 20px;
}

img {
  width: 100%;
  height: 200px;
}

.title {
  bottom: 0px;
  left: 0;
  right: 0;
  position: absolute;
  height: 40px;
  text-indent: 20px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1111;
}
</style>
