<template>
  <MyDialog :width="'800px'" :diaVisible="dialog.isShow" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="bannerContainer">
        <div v-if="$i18n.locale === 'zh'">
          <h1 class="title">关于 YCHpro</h1><br>
          <div class="content">
            YCHpro成立于2016年，是全球领先的金融产品在线交易服务商，为交易者提供外汇、贵金属、指数等金融衍生品交易服务。YCHpro寓意着非凡的关怀。它将致力于打造全球领先的社交网络平台、多语言金融直播平台，为全球客户提供多元化的交易工具与策略服务。用户参与的不仅仅是金融投资，更是一个交流知识、分享经验、表达情感和想法的共享平台。YCHpro 始终坚持以客户需求为原则，努力为客户提供有温度、有速度、有深度的专业服务。
          </div>
          <br>
          <div class="content">
            YCHpro 中文频道直播：<br> 周一到周五 <br>
            2:30PM--凌晨 0:00（新加波时间）<br>
            6:30AM--4:00PM (GMT）
          </div>
        </div>
        <div v-if="$i18n.locale === 'en'">
          <h1 class="title">About YCHpro</h1><br>
          <div class="content">
            Founded in 2016, YCHpro is the world's leading online trading service provider of financial products, providing traders with financial derivatives trading services such as foreign exchange, precious metals, indices, etc. YCHpro signifies extraordinary care. It will be committed to building the world's leading social network platform, multi-language financial live platform, and provide diversified trading tools and strategy services for global customers. Users participate not only in financial investment, but also a sharing platform for exchanging knowledge, sharing experience, and expressing emotions and ideas.YCHpro always adheres to the principle of customer demand, and strives to provide customers with professional services with temperature, speed and depth.
          </div>
<!--          <div class="content">-->
<!--            YCHpro YC Channel (English)：<br> Monday to Friday <br>-->
<!--            2:30PM&#45;&#45;00:00AM(Singapore time)<br>-->
<!--            10:30AM&#45;&#45;17:00PM(GMT)-->
<!--          </div>-->
        </div>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import {mapGetters} from 'vuex';
import {getBanners} from '@/api';

export default {
  name: 'aboutModel',
  components: {MyDialog},
  data() {
    return {
      dialog: {
        isShow: false,
      },
      banners: [],
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  methods: {
    // 关闭dialog
    close() {
      this.dialog.isShow = false;
      this.banners = [];
    },
    // 确定dialog
    show(type) {
      this.dialog.isShow = true;
      if (this.currentRoomId) {
        getBanners({roomId: this.currentRoomId, extraFlag: type})
            .then((res) => {
              this.banners = res.data;
            });
      }
    },
    openTab(data) {
      console.log(data);
      // window.location
      if (data.link) {
        window.open(data.link, '_blank');
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  background: #712327;

  .el-dialog__body {
  }
}

.container {
  height: 800px;
  //width: 800px;
}

.bannerContainer {
  //width: 1200px;
  height: 400px;
  max-height: 800px;

  img {
    height: 100%;
    object-fit: contain;
  }

  .content {
    text-indent: 2em;
    font-size: 16px;
    color: #fff;
    line-height: 34px;
  }

  .title {
    color: #fff;
    text-align: center;
  }
}

/deep/ .el-carousel {
  //height: 190px;
  height: 100%;

  .el-carousel__container {
    height: 100%;

    .el-carousel__item {

      .bannerItemBox {
        position: relative;
        width: 100%;
        height: 100%;

        .title {
          bottom: 0px;
          left: 0;
          right: 0;
          position: absolute;
          height: 40px;
          text-indent: 20px;
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #fff;
          background: rgba(0, 0, 0, 0.6);
          z-index: 1111;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      //  //display: flex;
      //  //flex-direction: column;
      //  //justify-content: center;
      //  cursor: pointer;
      //

    }
  }

  .el-carousel__indicator {
    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }

    //padding: 0px;

  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  //background-color: #d3dce6;
}
</style>
