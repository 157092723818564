<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'100%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('header.Activity')">
    <div class="H5_listItemBox">
      <LoadPageList :params="{roomId:currentRoomId}" v-if="popupShow" :reqHandle="getActivityList">
        <template slot-scope="{itemData,$index}">
          <div @click="showDetail(itemData)" class="H5_listItem" :key="$index">
<!--            <div class="H5_dealTime">-->
<!--              <div class="H5_dealTime-t">{{ fomartDate(itemData.createTime, 'hh:mm') }}</div>-->
<!--              <div class="H5_dealTime-b">{{ fomartDate(itemData.createTime, 'YYYY.MM.DD') }}</div>-->
<!--            </div>-->
            <div v-if="itemData.cover" style="flex: 1;">
              <img  :src="itemData.cover" class="cover"  alt="cover"/>
            </div>
            <div class="H5_listItem-r" v-else>
              <p class="H5_roomTitle">{{ itemData.title }}</p>
              <div class="H5_describe">{{ itemData.summary }}</div>
            </div>
          </div>
        </template>
      </LoadPageList>
    </div>
    <van-dialog v-model="show" :title="$t('fixRight.Customer')">
      <img :src="roomInfo.qrCode" class="qrCode">
    </van-dialog>
  </Popup>
</template>

<script>
import Popup from '@/components/popup/popup';
import {getActivityList, getTradingInsider} from '@/api';
import { fomartDate } from 'utils/utils';
import LoadPageList from '@/components/loadPageList';
import { mapGetters } from 'vuex';

export default {
  name: 'ActivityList',
  components: {
    Popup,
    LoadPageList,
  },
  data() {
    return {
      popupShow: false,
      show: false,
    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'roomInfo', 'userInfo']),
  },
  methods: {
    getActivityList,
    getTradingInsider,
    fomartDate,
    showDetail(data) {
      this.$emit('showDetail', data);
      // if (this.userInfo.level >= 2) {
      //   this.$emit('showDetail', data);
      // } else {
      //   this.show = true;
      // }
    },
    overlayShow() {
      this.popupShow = true;
    },
    overlayHide() {
      this.popupShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.H5_dealTitle {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  padding-left: 20px;
}

.H5_listItemBox {
  //height: 100%;
  //overflow-y: auto;
  flex: 1;
  overflow: auto;
  padding: 0 15px;
}

.H5_listItem {
  background: #0c0c0d;
  display: flex;
  align-items: center;
  border-radius: 6px;
  //padding: 12px;
  margin-bottom: 15px;
  .cover{
    width: 100%;
    height: 120px;
    //margin-right: 20px;
  }
  .H5_dealTime {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    margin-right: 12px;
    background-color: #712327;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .H5_dealTime-t {
      font-size: 22px;
      color: #fff;
    }

    .H5_dealTime-b {
      font-size: 12px;
      color: #bababa;
      margin-top: 5px;
    }
  }
}

.H5_listItem-r {
  height: 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 12px;
  .H5_roomTitle {
    color: #fff;
    font-weight: 500;
  }

  .H5_describe {
    font-size: 12px;
    line-height: 14px;
    color: #bababa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}


</style>
<style>
.van-dialog__header {
  color: #000;
}

.van-dialog__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrCode {
  width: 200px;
  height: 200px;
}
</style>
