import 'assets/style/global.css';
import Vue from 'vue';
import App from './player.vue';
import AppH5 from './payerH5/playerH5.vue';
import eventBus from 'utils/_eventBus';
import store from '@/store';
import '@/assets/icons';
import i18n from '@/locales/i18n';
import { isMobile } from '@/utils/utils';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/index.css';
import 'vant/lib/index.css';
// import VConsole from 'vconsole';

import {
  Button,
  Select,
  Dialog,
  Input,
  Slider,
  Message,
  Card,
  Popover,
  Avatar,
  Tabs,
  TabPane,
  Option,
  Checkbox,
  InputNumber,
  MessageBox,
  Tooltip,
  Radio,
  Link,
  Carousel,
  CarouselItem,
  Form,
  FormItem,
  RadioGroup,
  RadioButton,
  Tag,
  Upload,
  InfiniteScroll,
  Row,
  Col,
  Image,
  // infinite-scroll
} from 'element-ui';
import {
  Popup,
  Icon,
  Button as VButton,
  Tab,
  Tabs as VTabs,
  Swipe,
  SwipeItem,
  ImagePreview,
  Dialog as VDialog,
  NoticeBar,
  Overlay,
} from 'vant';
import { getUserIp } from '@/api';
import CustomerService from 'utils/customerService';
import { getLanguage } from 'utils/common';
import VueLuckyCanvas from '@lucky-canvas/vue'

// new VConsole();
// vConsole;
document.title = i18n.t(isMobile() ? 'appSiteTitle' : 'siteTitle');


/**
 *  重写ElementUI的Message
 */
const showMessage = Symbol('showMessage');

class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single);
  }

  warning(options, single = true) {
    this[showMessage]('warning', options, single);
  }

  info(options, single = true) {
    this[showMessage]('info', options, single);
  }

  error(options, single = true) {
    this[showMessage]('error', options, single);
  }

  [showMessage](type, options) {
    Message[type](options);
  }
}

Vue.use(Button);
Vue.use(Tag);
Vue.use(Select);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Overlay);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Slider);
Vue.use(Input);
Vue.use(Card);
Vue.use(Popover);
Vue.use(Avatar);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(InputNumber);
Vue.use(Tooltip);
Vue.use(Radio);
Vue.use(Link);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Upload);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(InfiniteScroll);
Vue.use(VueLuckyCanvas)

// h5
Vue.use(Icon);
Vue.use(Popup);
Vue.use(VButton);
Vue.use(Tab);
Vue.use(VTabs);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(ImagePreview);
Vue.use(NoticeBar);
Vue.use(VDialog);
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = new DonMessage();

Vue.prototype.$eventBus = eventBus;

Vue.prototype.$isMobile = isMobile();

Vue.config.productionTip = false;

const language = localStorage.getItem('language');
if (language) {
  i18n.locale = language;
}

new CustomerService().init(getLanguage());

getUserIp()
  .then((res) => {
    store.state.userIp = res.data;
  });

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})


new Vue({
  i18n,
  store,
  render: h => h(isMobile() ? AppH5 : App),
}).$mount('#app');
