<template>
  <Popup v-model="popupShow" position="right"  :closeable="true" :stylePopup="{height:'100%',width:'50%' }"
         @click-overlay="overlayHide" :overlay-style="{'z-index':1998}">
    <div class="H5_menuBox">
      <div class="H5_menuBox-item" @click="clickHandle(LOGIN)" v-if="!userInfo.nickName || tourist">
        {{ $t('header.Sign in') }} / {{ $t('header.Sign up') }}
      </div>
      <div class="H5_menuBox-item" v-for="(item,index) in headerTags" :key="index"
           @click="clickHandle(item.key)">{{ $t(item.type) }}
      </div>

      <div class="H5_menuBox-item" @click="changeLang">
        {{ $t('lang') }} <i class="H5_langChange-icon el-icon-sort"></i>
      </div>
      <div class="H5_menuBox-item">
        IP:{{ userIp }}
      </div>
      <div class="H5_menuBox-item">
        {{ $t('header.Remaining duration') }}：<TimeTest  ref="timeTestRef" :remainTime="freeTime" @countDowmEnd="countDowmEnd"></TimeTest>
      </div>

    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/popup/popup';
import TimeTest from '@/components/time/index.vue';
import {
  BROADCASTING_ROOM,
  CLASS_SCHEDULE,
  MEMBERSHIP_PRIVILEGES,
  PROFIT_STATISTICS,
  TRANSACTION_PARAMETERS,
  LOGIN, ACTIVITY, ABOUT, CALENDAR,
} from 'constants/sys';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'menuPopup.vue',
  components: {
    Popup,TimeTest
  },
  data() {
    return {
      popupShow: false,
      LOGIN,
      headerTags: [
        // {
        //   type: 'header.Broadcast',
        //   key: BROADCASTING_ROOM,
        // },
        {
          type: 'header.Curriculum',
          key: CLASS_SCHEDULE,
        },
        {
          type: 'header.Privileges',
          key: MEMBERSHIP_PRIVILEGES,
        },
        {
          type: 'header.Parameter',
          key: TRANSACTION_PARAMETERS,
        },
        {
          type: 'header.Profit',
          key: PROFIT_STATISTICS,
        },
        {
          type: 'header.Activity',
          key: ACTIVITY,
        },
        {
          type: 'header.Calendar',
          key: CALENDAR,
        },
        {
          type: 'header.About',
          key: ABOUT,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userIp: 'userIp',
    }),
    ...mapState(['tourist','freeTime']),
    ...mapGetters(['userInfo']),
  },
  methods: {
    countDowmEnd() {
      this.$eventBus.$emit('freeTimeEnd');
    },
    overlayHide() {
      this.popupShow = false;
    },
    overlayShow() {
      this.popupShow = true;
    },
    clickHandle(type) {
      this.$emit('menuType', type);
      this.overlayHide();
    },
    changeLang() {
      switch (this.$i18n.locale) {
        case 'en':
          this.$i18n.locale = 'zh';
          localStorage.setItem('language', 'zh');
          break;
        case 'zh':
          this.$i18n.locale = 'en';
          localStorage.setItem('language', 'en');
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.H5_menuBox {
  padding: 20px 0 0 20px;

  .H5_menuBox-item {
    padding-bottom: 30px;
  }
}

.H5_langChange-icon {
  transform: rotate(90deg);
}
</style>
