<template>
  <div>
    <LoginModel ref="loginModelRef"/>
    <TipsModel ref="tipsModelRef" @clickHandle="tipsModelClick"/>
    <ClassScheduleModel ref="classScheduleModelRef"/>
    <PickUpSignalModel ref="pickUpSignalModelRef"/>
    <LiveListModel ref="liveListModelRef"/>
    <DealModel @showDetail="showDetail" ref="dealModelRef"/>
    <DealDetailModel ref="dealDetailModelRef"/>
    <UserCenter ref="userCenterRef"/>
    <OpenAnAccountModel ref="openAnAccountRef"/>
    <BannerModel ref="bannerModelRef"/>
    <AboutModel ref="aboutModelRef"/>
    <ChatModel ref="chatModelRef"/>
    <ActivityModel @showDetail="showActivityDetail" ref="activityModelRef"/>
    <CalendarModel ref="calendarModelRef"/>
    <LuckyModel ref="luckyModelRef"/>
  </div>
</template>

<script>
import LoginModel from '@/components/model/pc/loginModel.vue';
import TipsModel from '@/components/model/pc/tipsModel.vue';
import ClassScheduleModel from '@/components/model/pc/classScheduleModel';
import PickUpSignalModel from '@/components/model/pc/pickUpSignalModel';
import LiveListModel from '@/components/model/pc/liveListModel';
import BannerModel from '@/components/model/pc/bannerModel';
import {
  LOGIN,
  REGISTER,
  CLASS_SCHEDULE,
  MEMBERSHIP_PRIVILEGES,
  TRANSACTION_PARAMETERS,
  PROFIT_STATISTICS,
  BROADCASTING_ROOM,
  PICK_UP_SIGNAL, USER_INFO, VIP_REGISTER_TIP,
  OPEN_ACCOUNT, TRANSACTION_PARAMETERS_DETAIL, PRIVATE_CHAT, ACTIVITY, ABOUT, CALENDAR, LUCKY_ACTIVITY,
  // USER_INFO,
}
  from 'constants/sys';
import DealModel from '@/components/model/pc/dealModel';
import DealDetailModel from '@/components/model/pc/dealDetailModel';
import UserCenter from '@/components/model/pc/userCenter';
import OpenAnAccountModel from '@/components/model/pc/openAnAccountModel';
import { mapGetters } from 'vuex';
import ChatModel from '@/components/model/pc/chatModel';
import {localStorage} from "utils/storage";
import ActivityModel from "@/components/model/pc/activityModel.vue";
import AboutModel from "@/components/model/pc/aboutModel.vue";
import CalendarModel from "@/components/model/pc/calendarModel.vue";
import LuckyModel from "@/components/model/pc/luckyModel.vue";

export default {
  components: {
    LuckyModel,
    CalendarModel,
    AboutModel,
    ActivityModel,
    ChatModel,
    OpenAnAccountModel,
    DealDetailModel,
    UserCenter,
    DealModel,
    PickUpSignalModel,
    ClassScheduleModel,
    LoginModel,
    TipsModel,
    LiveListModel,
    BannerModel,
  },
  data() {
    return {
      LoginModelShow: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    showDetail(d) {
      const {tourist} = localStorage.get('authData') || {};
      if (tourist) {
        this.$refs.loginModelRef.show(REGISTER);
        return
      }
      if (this.userInfo.level >= 3) {
        this.show(TRANSACTION_PARAMETERS_DETAIL, d.id);
      } else {
        layer.msg(this.$t('CantGetSignal tips'));
        // window.ECHAT.customMiniChat();
        window.open('https://t.me/XC600broker1', '_blank');

      }
    },
    showActivityDetail(d){
      this.show(TRANSACTION_PARAMETERS_DETAIL, d.id);
    },
    show(type, data) {
      console.log(data, type);
      switch (type) {
        case LOGIN:
        case REGISTER:
          this.$refs.loginModelRef.show(type);
          break;
        case CLASS_SCHEDULE:
          this.$refs.classScheduleModelRef.show();
          break;
        case BROADCASTING_ROOM:
          this.$refs.liveListModelRef.show();
          break;
        case TRANSACTION_PARAMETERS:
          this.$refs.dealModelRef.show();
          break;
        case PICK_UP_SIGNAL:
          this.$refs.pickUpSignalModelRef.show();
          break;
        case USER_INFO:
          this.$refs.userCenterRef.show();
          break;
        case VIP_REGISTER_TIP:
          // this.$refs.tipsModelRef.show();
          break;
        case OPEN_ACCOUNT:
          this.$refs.openAnAccountRef.show();
          break;
        case TRANSACTION_PARAMETERS_DETAIL:
          this.$refs.dealDetailModelRef.show(data);
          break;
        case MEMBERSHIP_PRIVILEGES:
        case PROFIT_STATISTICS:
          this.$refs.bannerModelRef.show(type);
          break;
        case PRIVATE_CHAT:
          this.$refs.chatModelRef.show(data);
          break;
        case ACTIVITY:
          this.$refs.activityModelRef.show();
          break;
        case ABOUT:
          this.$refs.aboutModelRef.show();
          break;
        case CALENDAR:
          this.$refs.calendarModelRef.show();
          break;
        case LUCKY_ACTIVITY:
          this.$refs.luckyModelRef.show();
          break;
      }
    },
    tipsModelClick(type) {
      this.show(type);
    },
  },
};
</script>

<style>
</style>
