<template>
  <Popup class="H5_Popup" v-model="popupShow" :closeable="true" :overlay="false"
         @click-overlay="overlayHide" @click-close-icon="overlayHide"
         :overlay-style="{opacity:0.1,'z-index':1998}">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <div class="H5_title">
        <div :class="[handleSignShow ? 'H5_sgin-one' : 'H5_sgin-two']" @click="()=>{handleSignShow=true}">
          {{ $t('login.Sign in') }}
        </div>
        <div :class="[!handleSignShow ? 'H5_sgin-one' : 'H5_sgin-two']" @click="()=>{handleSignShow=false}">
          {{ $t('login.Sign up') }}
        </div>
      </div>
      <template v-if="handleSignShow">
        <el-form-item prop="userName">
          <el-input v-model="loginForm.username" type="text" auto-complete="off"
                    :placeholder="$t('login.userName')"/>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" :type="passwordShow ? 'text' : 'password'" auto-complete="off"
                    :placeholder="$t('login.EnterPassword')">
            <template #suffix>
              <img class="H5_passwordImg" @click="passwordShow = !passwordShow"
                   :src="passwordShow? require('@/assets/img/eyes.png') : require('@/assets/img/eye-open.png')"
              />
            </template>
          </el-input>
        </el-form-item>
        <div class="H5_forget" v-if="handleSignShow">{{ $t('login.forgetPassword') }}</div>
        <el-form-item>
          <el-button :loading="loading" type="primary" style="width: 100%" @click="loginHandle">
            {{ $t('login.Sign in') }}
          </el-button>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop="username">
          <el-input
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              :placeholder="$t('login.userName')"
          />
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
              v-model="loginForm.phone"
              type="text"
              auto-complete="off"
              :placeholder="$t('login.phoneNumber')"
          >
            <el-select slot="prepend" style="width: 100px" v-model="loginForm.countryCode">
              <el-option
                  v-for="(item,index) in countryCodes"
                  :key="index"
                  :label="item.label"
                  :value="index">
                <div class="H5_countryOption">
                  <img class="H5_countryFlag" :src="getImgCode(item.short.toLowerCase())"/>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}
                      </span>
                </div>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
              auto-complete="off"
              v-model="loginForm.password"
              :type="passwordShow ? 'text' : 'password'"
              :placeholder="$t('login.EnterPassword')"
          >
            <template #suffix>
              <img class="H5_passwordImg"
                   @click="passwordShow = !passwordShow"
                   :src="passwordShow?eyeIcon : eyeOpenIcon"
              />
            </template>
          </el-input>
        </el-form-item>
        <!--        <el-form-item class="H5_codeFormContainer" prop="emailCode" v-if="!handleSignShow">-->
        <!--          <el-row :gutter="5">-->
        <!--            <el-col :span="14">-->
        <!--              <el-input-->
        <!--                  v-model="loginForm.emailCode"-->
        <!--                  auto-complete="off"-->
        <!--                  :placeholder="$t('login.VerificationCode')"-->
        <!--              />-->
        <!--            </el-col>-->
        <!--            <el-col :span="10">-->
        <!--              <CountDowm ref="countDowmRef">-->
        <!--                <template slot-scope="{disabled,massage}">-->
        <!--                  <el-button-->
        <!--                      type="primary"-->
        <!--                      :disabled="!loginForm.email"-->
        <!--                      @click="sendCode"-->
        <!--                      class="codeBtn"-->
        <!--                  >-->
        <!--                    {{ massage }}-->
        <!--                  </el-button>-->
        <!--                </template>-->
        <!--              </CountDowm>-->
        <!--            </el-col>-->
        <!--          </el-row>-->
        <!--        </el-form-item>-->
<!--
        <el-checkbox v-model="loginForm.agreement" style="margin-bottom: 5px;">
          {{ $t('login.Agreement') }}
        </el-checkbox>
-->

        <el-form-item>
          <el-button
              :disabled="!loginForm.agreement"
              @click="registerHandle"
              type="primary"
              :loading="regLoading"
              style="width: 100%"
          >
            {{ $t('login.Sign up') }}
          </el-button>
        </el-form-item>
      </template>
    </el-form>

    <div style="font-weight: bold">
      {{ $t("login.tips") }}
    </div>

  </Popup>
</template>

<script>
import Popup from '@/components/popup/popup';
import countryList from 'utils/countryList';
import eyeIcon from 'assets/img/eyes.png';
import eyeOpenIcon from 'assets/img/eye-open.png';
// import CountDowm from '@/components/countDowm';
import { getCaptchaEmail, userRegister } from '@/api';
import { mapActions, mapMutations } from 'vuex';
import * as mutationTypes from 'constants/mutation-types';
import { REGISTER } from 'constants/sys';
import { localStorage } from 'utils/storage';

export default {
  name: 'loginModel.vue',
  components: {
    Popup,
    // CountDowm,
  },
  data() {
    return {
      eyeIcon,
      eyeOpenIcon,
      popupShow: false,
      handleSignShow: true,
      passwordShow: false,
      regLoading: false,
      codeUrl: '',
      loading: false,
      // 注册开关
      register: false,
      redirect: undefined,
      loginForm: {
        countryCode: 0,
        username: '',
        password: '',
        agreement: true,
        phone: '',
        email: '',
        emailCode: '1234',
        uuid: '',
      },

    };
  },
  computed: {
    loginRules() {
      return {
        username: [
          { required: true, trigger: 'blur', message: this.$t('login.userName') },
        ],
        password: [
          { required: true, trigger: 'blur', message: this.$t('login.EnterPassword') },
        ],
        phone: [{ required: true, trigger: 'change', message: this.$t('login.phoneNumber') }],
      };
    },
    countryCodes() {
      const list = countryList;

      console.log(this.$t('lang'), '$t(\'lang\')');
      // const type = this.$t('lang') === '中文' ? 'zh' : 'en';
      return list.map(item => ({
        label: `${item.value}`,
        ...item,
      }));
    },
  },
  methods: {
    ...mapActions(['userLogin']),
    ...mapMutations([mutationTypes.SET_TOKEN]),
    getImgCode(name) {
      const path = `${name}.png`;
      return `https://ychpro.oss-ap-southeast-1.aliyuncs.com/common/flag/${path}`;
    },
    overlayShow(type) {
      this.handleSignShow = type !== REGISTER;
      if (this.handleSignShow) {
        const loginData = localStorage.get('loginData') || {};
        this.loginForm = {
          ...this.loginForm,
          ...loginData,
        };
      }
      this.popupShow = true;
    },
    overlayHide() {
      this.popupShow = false;
    },
    registerHandle() {
      this.$refs.loginForm.validate((v) => {
        console.log(v);
        if (v) {
          this.regLoading = true;
          const countryCode = this.countryCodes[this.loginForm.countryCode].value;
          userRegister({ ...this.loginForm, phone: `${countryCode}${this.loginForm.phone}` })
              .then((res) => {
                this.regLoading = false;
                console.log(res);
                if (res.code === 200) {
                  this.handleSignShow = true;
                }
              });
        }
      });
    },
    loginHandle() {
      this.loading = true;
      const { username, password } = this.loginForm;
      this.userLogin({ username, password })
          .then((res) => {
            if (res.code === 200) {
              this.cancel();
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    // 取消dialog
    cancel() {
      this.popupShow = false;
      // this.$emit('hide');
    },
    sendCode() {
      getCaptchaEmail({ email: this.loginForm.email })
          .then((res) => {
            if (res.code === 200) {
              this.$refs.countDowmRef.getCode();
            }
          });
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.H5_Popup {
  //height: 450px;
  background-color: #fff !important;
  color: black;
  min-width: 330px;
  border-radius: 16px;
  padding: 10px 30px 20px 30px;
  z-index: 2001 !important;
}

.H5_title {
  display: flex;
  //width: 78%;
  //justify-content: space-between;
  align-items: center;
  margin: 0 0 30px 0;

  .H5_sgin-one {
    margin-right: 17px;
    cursor: pointer;
    font-size: 24px;
    color: #d51820;
  }

  .H5_sgin-two {
    margin-right: 17px;
    cursor: pointer;
    font-size: 20px;
    color: #303133;
  }
}

.H5_forget {
  font-size: 12px;
  color: #767676;
  margin-left: 5px;
}

.H5_button {
  width: 100%;
  margin: 40px 0 70px;
  border-radius: 5px;
}

.H5_countryOption {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .H5_countryFlag {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

}

.H5_codeFormContainer {
  .H5_codeBtn {
    width: 100%;
    //margin-left: 10px;
  }
}

.H5_passwordImg {
  height: 100%;
  width: 40px;
  padding: 10px;
  cursor: pointer;
}


.H5_forget {
  font-size: 12px;
  color: #767676;
  margin: 0 0 30px 0;
}

/deep/ .el-checkbox__label {
  font-size: 12px;
  // color: #767676;
}

/deep/ .el-form-item {
  margin-bottom: 20px;
}


</style>
<style>
/deep/ .el-select-dropdown {
  z-index: 3000 !important;
}
</style>
