<template>
  <MyDialog :width="'800px'" :diaVisible="dialog.isShow" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="bannerContainer">
        <div id="economicCalendarWidget"></div>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import {mapGetters} from 'vuex';
import {getBanners} from '@/api';

export default {
  name: 'calendarModel',
  components: {MyDialog},
  data() {
    return {
      dialog: {
        isShow: false,
      },
      banners: [],
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {
    // 关闭dialog
    close() {
      this.dialog.isShow = false;
      this.banners = [];
    },
    // 确定dialog
    show(type) {

      this.dialog.isShow = true;
      this.$nextTick(()=>{
        const script = document.createElement('script')
        script.async = true
        script.type = 'text/javascript'
        script.dataset.type = 'calendar-widget'
        script.src = 'https://www.tradays.com/c/js/widgets/calendar/widget.js?v=13'
        script.innerHTML = JSON.stringify({
          width: '100%',
          height: '100%',
          mode: '2',
          lang: this.$i18n.locale,
          theme: 1
        })
        document.getElementById('economicCalendarWidget').appendChild(script)
      })
    },
    openTab(data) {

    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  background: #712327;

  .el-dialog__body {
  }
}

.container {
  height: 800px;
  //width: 800px;
}

.bannerContainer {
  //width: 1200px;
  height: 400px;
  max-height: 800px;

  img {
    height: 100%;
    object-fit: contain;
  }

  .content {
    text-indent: 2em;
    font-size: 16px;
    color: #fff;
    line-height: 34px;
  }

  .title {
    color: #fff;
    text-align: center;
  }
}

/deep/ .el-carousel {
  //height: 190px;
  height: 100%;

  .el-carousel__container {
    height: 100%;

    .el-carousel__item {

      .bannerItemBox {
        position: relative;
        width: 100%;
        height: 100%;

        .title {
          bottom: 0px;
          left: 0;
          right: 0;
          position: absolute;
          height: 40px;
          text-indent: 20px;
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #fff;
          background: rgba(0, 0, 0, 0.6);
          z-index: 1111;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      //  //display: flex;
      //  //flex-direction: column;
      //  //justify-content: center;
      //  cursor: pointer;
      //

    }
  }

  .el-carousel__indicator {
    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }

    //padding: 0px;

  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  //background-color: #d3dce6;
}
</style>
