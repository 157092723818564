<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'80%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('header.ParameterDetail')">
    <div class="detailContainer">
      <div class="title van-ellipsis">
        {{ dataForm.title }}
      </div>
      <div class="subTit">
        <span class="time">{{ dataForm.createTime }}</span>
        <span class="readNum">{{ $t('readNum') }}:{{ readNum }}</span>
      </div>
      <div class="detailContainer_content" v-html="dataForm.content">
      </div>
    </div>
  </Popup>
</template>

<script>

import { getTradingInsiderInfo } from '@/api';
import { mapGetters } from 'vuex';
import { fomartDate } from 'utils/utils';
import Popup from '@/components/popup/popup';

export default {
  name: 'dealDetailModel',
  components: { Popup },
  data() {
    return {
      popupShow: false,
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
    readNum() {
      return (this.dataForm.readNum || 0) + (this.dataForm.initReaderNum || 0);
    },
  },
  methods: {
    fomartDate,
    show() {
      this.visible = true;
    },
    overlayShow(id) {
      this.popupShow = true;
      getTradingInsiderInfo({ id })
          .then((res) => {
            this.dataForm = res.data;
          });
    },
    overlayHide() {
      this.popupShow = false;
    },
    imgTagAddStyle(htmlstr) {
      let newContent = htmlstr.replace(/<img[^>]*>/gi, (match) => {
        match = match.replace(/style="[^"]+"/gi, '')
            .replace(/style='[^']+'/gi, '');
        match = match.replace(/width="[^"]+"/gi, '')
            .replace(/width='[^']+'/gi, '');
        match = match.replace(/height="[^"]+"/gi, '')
            .replace(/height='[^']+'/gi, '');
        return match;
      });
      newContent = newContent.replace(/style="[^"]+"/gi, (match) => {
        match = match.replace(/width:[^;]+;/gi, 'max-width:100%;')
            .replace(/width:[^;]+;/gi, 'max-width:100%;');
        return match;
      });
      newContent = newContent.replace(/<br[^>]*\/>/gi, '');
      // eslint-disable-next-line no-useless-escape
      newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;margin:8px 0;"');
      return newContent;
    },
  },
};
</script>

<style scoped lang="less">
.detailContainer {
  padding: 0 20px;

  .title {
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  .subTit {
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #BABABA;
    display: flex;
    justify-content: space-between;
  }

  &_content {
    //word-break: break-all;
    height: 400px;
    overflow-y: auto;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
  }

}


</style>
<style>
.detailContainer img {
  width: 100%;
}
</style>
