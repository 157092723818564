/*
 * @Description: vuex-mutations
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2022-02-15 18:06:33
 */
import * as mutationTypes from 'constants/mutation-types';
import {localStorage} from 'utils/storage.js';
// import { IS_TOURIST, TOKEN } from 'constants/mutation-types';
import {
    ADD_CHAT_HISTORY,
    ADD_CURRENT_USER_CHAT_MESSAGE, ADD_PRIVATE_CHAT_MESSAGE,
    CLEAR_CHAT_HISTORY,
    DEL_CHAT_HISTORY,
} from '@/components/chat/constants/chat';
import {TOKEN} from 'constants/mutation-types';
import {fomartDate} from 'utils/utils';

export function handlePrivateChatMsg(messageData, userId) {
    return {
        id: messageData.messageId,
        name: messageData.nickname,
        avatar: messageData.avatar,
        self: messageData.userId === userId,
        content: {
            text: messageData.messageBodys[0].messageBody,
            imageUrl: messageData.messageBodys[0].messageBody,
        },
        type: messageData.messageBodys[0].messageType?.toLocaleLowerCase(),
        time: fomartDate(messageData.messageTimeStamp, 'YYYY-MM-DD HH:mm:ss'),

    };
}

export default {
    // 设置sdkAppId
    [mutationTypes.SET_SDK_APP_ID](state, value) {
        state.sdkAppId = value;
    },
    // 设置userSig
    [mutationTypes.SET_USER_SIG](state, value) {
        state.userSig = value;
    },
    // 设置播放域名
    [mutationTypes.SET_PLAYER_DOMAIN](state, value) {
        state.playerDomain = value;
    },
    // 更新直播间ID
    [mutationTypes.SET_ROOM_ID](state, value) {
        localStorage.set('roomId', value);
        state.roomId = value;
    },
    // 更新直播间昵称
    [mutationTypes.SET_ROOM_NAME](state, value) {
        state.roomName = value;
    },
    // 更新用户信息
    [mutationTypes.UPDATE_USER_INFO](state, data) {
        state.userInfo = data;
    },
    // 设置主播用户ID
    [mutationTypes.SET_ANCHOR_USER_ID](state, data) {
        state.anchorUserId = data;
    },
    // 更新直播间阶段
    [mutationTypes.UPDATE_LIVE_STAGE](state, value) {
        state.liveStage = value;
    },
    // 更新播放线路
    [mutationTypes.UPDATE_LINE_TYPE](state, value) {
        state.lineType = value;
    },
    // 更新流播放状态
    [mutationTypes.UPDATE_PLAY_STATE](state, value) {
        state.playState = value;
    },
    // 更新使用的摄像头设备
    [mutationTypes.UPDATE_ACTIVE_CAMERA](state, device) {
        state.activeCamera = device;
    },
    // 更新使用的麦克风设备
    [mutationTypes.UPDATE_ACTIVE_MICROPHONE](state, device) {
        state.activeMicrophone = device;
    },
    // 更新使用的扬声器设备
    [mutationTypes.UPDATE_ACTIVE_SPEAKER](state, device) {
        state.activeSpeaker = device;
    },
    // 更新是否设置本地流镜像
    [mutationTypes.UPDATE_SET_MIRROR](state, value) {
        state.isSetMirror = value;
    },
    // 更新是否设置本地流镜像
    [mutationTypes.UPDATE_OPEN_BEAUTY](state, value) {
        state.isOpenBeauty = value;
    },
    // 更新视频参数
    [mutationTypes.UPDATE_VIDEO_PROFILE](state, data) {
        state.videoProfile = data;
    },
    // 更新美颜参数
    [mutationTypes.UPDATE_BEAUTY_PARAM](state, data) {
        state.beautyParam = data;
    },
    // 更新是否录制直播
    [mutationTypes.UPDATE_RECORD_LIVE](state, value) {
        state.isRecordLive = value;
    },
    // 更新视频mute状态
    [mutationTypes.UPDATE_VIDEO_STATE](state, value) {
        state.isVideoMuted = value;
    },
    // 更新音频mute状态
    [mutationTypes.UPDATE_AUDIO_STATE](state, value) {
        state.isAudioMuted = value;
    },
    // 更新音量大小
    [mutationTypes.UPDATE_AUDIO_LEVEL](state, value) {
        state.audioLevel = value;
    },
    // 更新上行网络等级
    [mutationTypes.UPDATE_UPLINK_NETWORK_LEVEL](state, value) {
        state.uplinkQualityLevel = value;
    },
    // 更新横竖屏
    [mutationTypes.UPDATE_LAYOUT](state, value) {
        state.layout = value;
    },
    // 设置当前浏览器是否支持 webRTC
    [mutationTypes.SET_IS_SUPPORT_WEBRTC](state, value) {
        state.isSupportWebRTC = value;
    },
    // 设置token
    [mutationTypes.SET_TOKEN](state, value) {
        // const tourist = Number(value.isTourist || false);
        state.token = value.token;
        state.tourist = value.isTourist;
        localStorage.set('authData', {token: value.token, tourist: value.isTourist});
        localStorage.set(TOKEN, value.token);
    },
    [mutationTypes.SET_ROOM_INFO](state, value) {
        state.roomInfo = {
            ...value,
            // living: true,
            // playUrl: 'https://gcalic.v.myalicdn.com/gc/hsxkssqdzrqj_1/index.m3u8?contentid=2820180516001',
            // playUrl: 'artc://pull.ychuipro.com/1677655229566365698/1677655229566365698?auth_key=1688907286-0-0-fed9d226400494d0d819ac0f76e072fe',
        };
    },
    [mutationTypes.SET_FREE_TIME](state, value) {
        state.freeTime = value;

    },
    // 聊天列表
    [ADD_CURRENT_USER_CHAT_MESSAGE](state, messageData) {
        state.chatList.push({
            messageId: 0,
            nickname: state.userInfo.user.nickName,
            avatar: state.userInfo.user.avatar || 'http://forex-yc.oss-ap-southeast-1.aliyuncs.com/2023/06/06/e0dd74c8cd684aa6a64df316810a5b54.png',
            levelIcon: 'http://forex-yc.oss-ap-southeast-1.aliyuncs.com/2023/06/06/84e366585b494cef94653dc57a53fa4a.png',
            level: state.userInfo.user.avatar.level,
            messageTimeStamp: Date.now(),
            messageType: 'WELCOME',
            messageBodys: messageData,
            status: 'PASS',
        });
    },
    [ADD_PRIVATE_CHAT_MESSAGE](state, messageData) {
        if (!state.privateFromUserId) {
            state.privateFromUserId = messageData.userId;
        } else {
            state.privateChatList.push(handlePrivateChatMsg(messageData, state.userInfo.user.userId));
        }
    },
    // [ADD_PRIVATE_CHAT_MESSAGE](state, messageData) {
    //   state.privateFromUserId = messageData.userId;
    //   state.privateChatList.push(handlePrivateChatMsg(messageData, state));
    // },
    [ADD_CHAT_HISTORY](state, value) {
        console.log('roomId===>',state.roomId)

        if (value.list.length > 0) {
            value.list.forEach(msg=>{
                const exists = state.chatList.some(item => item.messageId === msg.messageId);
                if (!exists && msg.roomId===state.roomId) {
                    console.log('存入chatList')
                    // state.chatList.unshift(value.list);
                    state.chatList.push(msg)
                }
            })
        } else {
            // state.chatList.unshift(value.list);
        }


        // if (!value.type) {
        //     if (value.list.length > 0) {
        //         value.list.forEach(msg=>{
        //             const exists = state.chatList.some(item => item.messageId === msg.messageId);
        //             if (!exists && msg.roomId===state.roomId) {
        //                 console.log('存入chatList')
        //                 // state.chatList.unshift(value.list);
        //                 state.chatList.push(msg)
        //             }
        //         })
        //     } else {
        //         // state.chatList.unshift(value.list);
        //     }
        // } else {
        //     console.log('else 存入chatList')
        //     state.chatList = state.chatList.concat(value.list);
        // }
    },
    // 删除单条聊天记录
    [DEL_CHAT_HISTORY](state, index) {
        state.chatList.splice(index, 1);
    },
    // 清空聊天记录
    [CLEAR_CHAT_HISTORY](state) {
        state.chatList = [];
    },
    // 设置领取信号
    [mutationTypes.SET_LASTSIGNAL](state, lastsignalData) {
        state.lastSignalData = lastsignalData;
    },
    // 设置用户来源
    [mutationTypes.SET_USER_SOURCE](state, source) {
        state.userSource = source;
    },
};
