import axios from 'axios';
import {Message} from 'element-ui';
import {TOKEN, UPDATE_IP_STATUS} from 'constants/mutation-types';
import * as mutationTypes from "constants/mutation-types";

const service = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://ychuipro.com/' : 'https://ychuipro.com/',
    timeout: 15000, // request timeout
});

service.interceptors.request.use(
    (config) => {
        // console.log(localStorage.getItem(TOKEN), 'localStorage.get(TOKEN)');
        // console.log(localStorage.getItem(TOKEN), 'localStorage.getItem(TOKEN)');
        config.headers[TOKEN] = localStorage.getItem(TOKEN)
            ?.replace(/"/g, '') ?? '-1';
        return config;
    },
    error => Promise.reject(error),
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        if (response.data.code === 401) {
            // localStorage.clear();
        }
        if (response.data.code === 500) {
            Message.error(response.data.msg);
        }
        if (response.data.code === 300) {
            //ip被封
            Message.error(response.data.msg);
            window.location.href = 'invalid.html?ip='+response.data.msg
        }
        return response.data;
    },
    (error) => {
        console.log(error, '===>');
        return Promise.reject(error);
    },
);

export default service;
