import {
  getUserInfo,
  getLiveRoomInfo,
  updateUnerInfo,
  userLoign,
  getTempUserToken,
  getRemainingViewingTime, getLastSignal, getPrivateChatMessages,
} from '@/api';
import * as mutationTypes from 'constants/mutation-types';
import socket from 'utils/socket';
import { SET_ROOM_ID, SET_TOKEN } from 'constants/mutation-types';
import { localStorage } from 'utils/storage';
import { debounce } from 'lodash-es';
import { CLEAR_CHAT_HISTORY } from '@/components/chat/constants/chat';
import i18n from '@/locales/i18n';
import { handlePrivateChatMsg } from '@/store/mutations';
// import CustomerService from 'utils/customerService';

export default {
  async userLogin({ commit, dispatch, state }, data) {
    if (data.autoLoad) {
      data = localStorage.get('loginData');
      if (!data) {
        return false;
      }
    } else {
      localStorage.set('loginData', data);
    }
    const loginRes = await userLoign({ ...data, source: state.userSource, roomId: state.roomId });
    commit(mutationTypes.SET_TOKEN, { token: loginRes.data.token, tourist: false });
    setTimeout(() => {
      dispatch('getUserInfo');
    }, 500);
    return loginRes;
  },
  getUserInfo({ commit, dispatch }) {
    getUserInfo()
      .then((res) => {
        commit(mutationTypes.UPDATE_USER_INFO, res.data);
        dispatch('initCahtRoom');
      });
  },
  async getTempUserInfo({ commit, dispatch, state }) {
    const { tourist, token } = localStorage.get('authData');
    if (!token || tourist) {
      const tempUserTokenRes = await getTempUserToken({ roomId: state.roomId, source: state.userSource });
      if (tempUserTokenRes.code === 200) {
        // const freeTimeRes = await getRemainingViewingTime({roomId : state.roomId});
        if (tempUserTokenRes.data) {
          const { nickname, tempUserId } = tempUserTokenRes.data;
          commit(mutationTypes.UPDATE_USER_INFO, { user: { nickName: nickname, userId: tempUserId } });
          commit(SET_TOKEN, { token: tempUserTokenRes.data.token, isTourist: true });
          // const freeTimeNum = Number(freeTimeRes.data);
          // commit(mutationTypes.SET_FREE_TIME, freeTimeNum);
          dispatch('initCahtRoom');
          // if (freeTimeNum > 0) {
          //   dispatch('initCahtRoom');
          // }
        }
      } else {
        commit(mutationTypes.SET_FREE_TIME, -1);
      }
    }
  },
  getRoomInfo({ commit, state, dispatch },password) {
    getLiveRoomInfo({ id: state.roomId ,password:password || ''})
      .then((res) => {
        commit(mutationTypes.SET_ROOM_INFO, res.data);
        if (res.data.language) {
          i18n.locale = res.data.language === '中文' ? 'zh' : 'en';
          // new CustomerService().init(i18n.locale);
        }
        dispatch('getLastsignal');
      });
  },
  async changeUserInfo({ state, dispatch }, data) {
    const { nickname, nickName, avatar, password, emailCode, email } = data || {};
    const res = await updateUnerInfo({ nickname: nickName || nickname, avatar, password, emailCode, email });
    if (res.code === 200) {
      dispatch('getUserInfo');
    }
    return res;
  },
  async changeLiveRoom({ dispatch, commit, state }, roomId) {
    // const freeTimeRes = state.tourist ? await getRemainingViewingTime({roomId : state.roomId}) : -1;
    commit(mutationTypes.SET_ROOM_INFO, null);
    commit(CLEAR_CHAT_HISTORY, null);
    commit(SET_ROOM_ID, roomId);
    // if (freeTimeRes > 0) {
    //   dispatch('initCahtRoom', roomId);
    // }
    dispatch('initCahtRoom', roomId);
  },
  // initCahtRoom({ state }, roomId) {
  //   socket.onClose();
  //   debounce(() => {
  //     socket.Init(roomId || state.roomId);
  //   }, 500, { trailing: true })();
  // },
  // eslint-disable-next-line key-spacing
  initCahtRoom: debounce(({ commit,state }, roomId) => {

    socket.onClose();
    debounce(() => {
      socket.Init(roomId || state.roomId);
    }, 500, { trailing: true })();

     getRemainingViewingTime({roomId : state.roomId}).then(res=>{
       commit(mutationTypes.SET_FREE_TIME, -1);
       setTimeout(()=>{
         commit(mutationTypes.SET_FREE_TIME, res.data);
       },500)
     })

  }),
  logOut({ dispatch, commit }) {
    commit(mutationTypes.UPDATE_USER_INFO, { user: {} });
    commit(SET_TOKEN, { token: '', isTourist: true });
    dispatch('getTempUserInfo');
    socket.onClose();
  },
  getLastsignal({ state }) {
    getLastSignal({ roomId: state.roomId })
      .then((res) => {
        state.lastSignalData = res.data || { content: '' };
      });
  },
  getPrivateChatMsgList({ state }) {
    const { userId } = state.userInfo.user;
    const { privateFromUserId } = state;
    state.privateChatList = [];
    getPrivateChatMessages({ fromUserId: userId, toUserId: privateFromUserId, pageSize: 99999, pageNum: 1 })
      .then(res => {
        state.privateChatList = [...res.rows.map(item => handlePrivateChatMsg(item, userId)), ...state.privateChatList];
      });
  },
};
