export default [
  {
    short: 'CN',
    zh: '中国',
    en: 'China',
    value: '86',
  },
  {
    short: 'AD',
    zh: '安道尔共和国',
    en: 'Andorra',
    value: '376',
  },
  {
    short: 'AE',
    zh: '阿拉伯联合酋长国',
    en: 'UnitedArabEmirates',
    value: '971',
  },
  {
    short: 'AF',
    zh: '阿富汗',
    en: 'Afghanistan',
    value: '93',
  },
  {
    short: 'AG',
    zh: '安提瓜和巴布达',
    en: 'AntiguaandBarbuda',
    value: '1268',
  },
  {
    short: 'AI',
    zh: '安圭拉岛',
    en: 'Anguilla',
    value: '1264',
  },
  {
    short: 'AL',
    zh: '阿尔巴尼亚',
    en: 'Albania',
    value: '355',
  },
  {
    short: 'AM',
    zh: '阿美尼亚',
    en: 'Armenia',
    value: '374',
  },
  {
    short: 'SH',
    zh: '阿森松',
    en: 'Ascension',
    value: '247',
  },
  {
    short: 'AO',
    zh: '安哥拉',
    en: 'Angola',
    value: '244',
  },
  {
    short: 'AR',
    zh: '阿根廷',
    en: 'Argentina',
    value: '54',
  },
  {
    short: 'AT',
    zh: '奥地利',
    en: 'Austria',
    value: '43',
  },
  {
    short: 'AU',
    zh: '澳大利亚',
    en: 'Australia',
    value: '61',
  },
  {
    short: 'AZ',
    zh: '阿塞拜疆',
    en: 'Azerbaijan',
    value: '994',
  },
  {
    short: 'BB',
    zh: '巴巴多斯',
    en: 'Barbados',
    value: '1246',
  },
  {
    short: 'BD',
    zh: '孟加拉国',
    en: 'Bangladesh',
    value: '880',
  },
  {
    short: 'BE',
    zh: '比利时',
    en: 'Belgium',
    value: '32',
  },
  {
    short: 'BF',
    zh: '布基纳法索',
    en: 'Burkina-faso',
    value: '226',
  },
  {
    short: 'BG',
    zh: '保加利亚',
    en: 'Bulgaria',
    value: '359',
  },
  {
    short: 'BH',
    zh: '巴林',
    en: 'Bahrain',
    value: '973',
  },
  {
    short: 'BI',
    zh: '布隆迪',
    en: 'Burundi',
    value: '257',
  },
  {
    short: 'BJ',
    zh: '贝宁',
    en: 'Benin',
    value: '229',
  },
  {
    short: 'BL',
    zh: '巴勒斯坦',
    en: 'Palestine',
    value: '970',
  },
  {
    short: 'BM',
    zh: '百慕大群岛',
    en: 'BermudaIs.',
    value: '1441',
  },
  {
    short: 'BN',
    zh: '文莱',
    en: 'Brunei',
    value: '673',
  },
  {
    short: 'BO',
    zh: '玻利维亚',
    en: 'Bolivia',
    value: '591',
  },
  {
    short: 'BR',
    zh: '巴西',
    en: 'Brazil',
    value: '55',
  },
  {
    short: 'BS',
    zh: '巴哈马',
    en: 'Bahamas',
    value: '1242',
  },
  {
    short: 'BW',
    zh: '博茨瓦纳',
    en: 'Botswana',
    value: '267',
  },
  {
    short: 'BY',
    zh: '白俄罗斯',
    en: 'Belarus',
    value: '375',
  },
  {
    short: 'BZ',
    zh: '伯利兹',
    en: 'Belize',
    value: '501',
  },
  {
    short: 'CA',
    zh: '加拿大',
    en: 'Canada',
    value: '1',
  },
  {
    short: '',
    zh: '开曼群岛',
    en: 'CaymanIs.',
    value: '1345',
  },
  {
    short: 'CF',
    zh: '中非共和国',
    en: 'CentralAfricanRepublic',
    value: '236',
  },
  {
    short: 'CG',
    zh: '刚果',
    en: 'Congo',
    value: '242',
  },
  {
    short: 'CH',
    zh: '瑞士',
    en: 'Switzerland',
    value: '41',
  },
  {
    short: 'CK',
    zh: '库克群岛',
    en: 'CookIs.',
    value: '682',
  },
  {
    short: 'CL',
    zh: '智利',
    en: 'Chile',
    value: '56',
  },
  {
    short: 'CM',
    zh: '喀麦隆',
    en: 'Cameroon',
    value: '237',
  },
  {
    short: 'CO',
    zh: '哥伦比亚',
    en: 'Colombia',
    value: '57',
  },
  {
    short: 'CR',
    zh: '哥斯达黎加',
    en: 'CostaRica',
    value: '506',
  },
  {
    short: 'CU',
    zh: '古巴',
    en: 'Cuba',
    value: '53',
  },
  {
    short: 'CY',
    zh: '塞浦路斯',
    en: 'Cyprus',
    value: '357',
  },
  {
    short: 'CZ',
    zh: '捷克',
    en: 'CzechRepublic',
    value: '420',
  },
  {
    short: 'DE',
    zh: '德国',
    en: 'Germany',
    value: '49',
  },
  {
    short: 'DJ',
    zh: '吉布提',
    en: 'Djibouti',
    value: '253',
  },
  {
    short: 'DK',
    zh: '丹麦',
    en: 'Denmark',
    value: '45',
  },
  {
    short: 'DO',
    zh: '多米尼加共和国',
    en: 'DominicaRep.',
    value: '1890',
  },
  {
    short: 'DZ',
    zh: '阿尔及利亚',
    en: 'Algeria',
    value: '213',
  },
  {
    short: 'EC',
    zh: '厄瓜多尔',
    en: 'Ecuador',
    value: '593',
  },
  {
    short: 'EE',
    zh: '爱沙尼亚',
    en: 'Estonia',
    value: '372',
  },
  {
    short: 'EG',
    zh: '埃及',
    en: 'Egypt',
    value: '20',
  },
  {
    short: 'ES',
    zh: '西班牙',
    en: 'Spain',
    value: '34',
  },
  {
    short: 'ET',
    zh: '埃塞俄比亚',
    en: 'Ethiopia',
    value: '251',
  },
  {
    short: 'FI',
    zh: '芬兰',
    en: 'Finland',
    value: '358',
  },
  {
    short: 'FJ',
    zh: '斐济',
    en: 'Fiji',
    value: '679',
  },
  {
    short: 'FR',
    zh: '法国',
    en: 'France',
    value: '33',
  },
  {
    short: 'GA',
    zh: '加蓬',
    en: 'Gabon',
    value: '241',
  },
  {
    short: 'GB',
    zh: '英国',
    en: 'UnitedKiongdom',
    value: '44',
  },
  {
    short: 'GD',
    zh: '格林纳达',
    en: 'Grenada',
    value: '1809',
  },
  {
    short: 'GE',
    zh: '格鲁吉亚',
    en: 'Georgia',
    value: '995',
  },
  {
    short: 'GF',
    zh: '法属圭亚那',
    en: 'FrenchGuiana',
    value: '594',
  },
  {
    short: 'GH',
    zh: '加纳',
    en: 'Ghana',
    value: '233',
  },
  {
    short: 'GI',
    zh: '直布罗陀',
    en: 'Gibraltar',
    value: '350',
  },
  {
    short: 'GM',
    zh: '冈比亚',
    en: 'Gambia',
    value: '220',
  },
  {
    short: 'GN',
    zh: '几内亚',
    en: 'Guinea',
    value: '224',
  },
  {
    short: 'GR',
    zh: '希腊',
    en: 'Greece',
    value: '30',
  },
  {
    short: 'GT',
    zh: '危地马拉',
    en: 'Guatemala',
    value: '502',
  },
  {
    short: 'GU',
    zh: '关岛',
    en: 'Guam',
    value: '1671',
  },
  {
    short: 'GY',
    zh: '圭亚那',
    en: 'Guyana',
    value: '592',
  },
  {
    short: 'HK',
    zh: '香港(中国)',
    en: 'Hongkong',
    value: '852',
  },
  {
    short: 'HN',
    zh: '洪都拉斯',
    en: 'Honduras',
    value: '504',
  },
  {
    short: 'HT',
    zh: '海地',
    en: 'Haiti',
    value: '509',
  },
  {
    short: 'HU',
    zh: '匈牙利',
    en: 'Hungary',
    value: '36',
  },
  {
    short: 'ID',
    zh: '印度尼西亚',
    en: 'Indonesia',
    value: '62',
  },
  {
    short: 'IE',
    zh: '爱尔兰',
    en: 'Ireland',
    value: '353',
  },
  {
    short: 'IL',
    zh: '以色列',
    en: 'Israel',
    value: '972',
  },
  {
    short: 'IN',
    zh: '印度',
    en: 'India',
    value: '91',
  },
  {
    short: 'IQ',
    zh: '伊拉克',
    en: 'Iraq',
    value: '964',
  },
  {
    short: 'IR',
    zh: '伊朗',
    en: 'Iran',
    value: '98',
  },
  {
    short: 'IS',
    zh: '冰岛',
    en: 'Iceland',
    value: '354',
  },
  {
    short: 'IT',
    zh: '意大利',
    en: 'Italy',
    value: '39',
  },
  {
    short: '',
    zh: '科特迪瓦',
    en: 'IvoryCoast',
    value: '225',
  },
  {
    short: 'JM',
    zh: '牙买加',
    en: 'Jamaica',
    value: '1876',
  },
  {
    short: 'JO',
    zh: '约旦',
    en: 'Jordan',
    value: '962',
  },
  {
    short: 'JP',
    zh: '日本',
    en: 'Japan',
    value: '81',
  },
  {
    short: 'KE',
    zh: '肯尼亚',
    en: 'Kenya',
    value: '254',
  },
  {
    short: 'KG',
    zh: '吉尔吉斯坦',
    en: 'Kyrgyzstan',
    value: '331',
  },
  {
    short: 'KH',
    zh: '柬埔寨',
    en: 'Kampuchea(Cambodia)',
    value: '855',
  },
  {
    short: 'KP',
    zh: '朝鲜',
    en: 'NorthKorea',
    value: '850',
  },
  {
    short: 'KR',
    zh: '韩国',
    en: 'Korea',
    value: '82',
  },
  {
    short: 'KT',
    zh: '科特迪瓦共和国',
    en: 'RepublicofIvoryCoast',
    value: '225',
  },
  {
    short: 'KW',
    zh: '科威特',
    en: 'Kuwait',
    value: '965',
  },
  {
    short: 'KZ',
    zh: '哈萨克斯坦',
    en: 'Kazakstan',
    value: '327',
  },
  {
    short: 'LA',
    zh: '老挝',
    en: 'Laos',
    value: '856',
  },
  {
    short: 'LB',
    zh: '黎巴嫩',
    en: 'Lebanon',
    value: '961',
  },
  {
    short: 'LC',
    zh: '圣卢西亚',
    en: 'St.Lucia',
    value: '1758',
  },
  {
    short: 'LI',
    zh: '列支敦士登',
    en: 'Liechtenstein',
    value: '423',
  },
  {
    short: 'LK',
    zh: '斯里兰卡',
    en: 'SriLanka',
    value: '94',
  },
  {
    short: 'LR',
    zh: '利比里亚',
    en: 'Liberia',
    value: '231',
  },
  {
    short: 'LS',
    zh: '莱索托',
    en: 'Lesotho',
    value: '266',
  },
  {
    short: 'LT',
    zh: '立陶宛',
    en: 'Lithuania',
    value: '370',
  },
  {
    short: 'LU',
    zh: '卢森堡',
    en: 'Luxembourg',
    value: '352',
  },
  {
    short: 'LV',
    zh: '拉脱维亚',
    en: 'Latvia',
    value: '371',
  },
  {
    short: 'LY',
    zh: '利比亚',
    en: 'Libya',
    value: '218',
  },
  {
    short: 'MA',
    zh: '摩洛哥',
    en: 'Morocco',
    value: '212',
  },
  {
    short: 'MC',
    zh: '摩纳哥',
    en: 'Monaco',
    value: '377',
  },
  {
    short: 'MD',
    zh: '摩尔多瓦',
    en: 'Moldova,Republicof',
    value: '373',
  },
  {
    short: 'MG',
    zh: '马达加斯加',
    en: 'Madagascar',
    value: '261',
  },
  {
    short: 'ML',
    zh: '马里',
    en: 'Mali',
    value: '223',
  },
  {
    short: 'MM',
    zh: '缅甸',
    en: 'Burma',
    value: '95',
  },
  {
    short: 'MN',
    zh: '蒙古',
    en: 'Mongolia',
    value: '976',
  },
  {
    short: 'MO',
    zh: '澳门（中国）',
    en: 'Macao',
    value: '853',
  },
  {
    short: 'MS',
    zh: '蒙特塞拉特岛',
    en: 'MontserratIs',
    value: '1664',
  },
  {
    short: 'MT',
    zh: '马耳他',
    en: 'Malta',
    value: '356',
  },
  {
    short: '',
    zh: '马里亚那群岛',
    en: 'MarianaIs',
    value: '1670',
  },
  {
    short: '',
    zh: '马提尼克',
    en: 'Martinique',
    value: '596',
  },
  {
    short: 'MU',
    zh: '毛里求斯',
    en: 'Mauritius',
    value: '230',
  },
  {
    short: 'MV',
    zh: '马尔代夫',
    en: 'Maldives',
    value: '960',
  },
  {
    short: 'MW',
    zh: '马拉维',
    en: 'Malawi',
    value: '265',
  },
  {
    short: 'MX',
    zh: '墨西哥',
    en: 'Mexico',
    value: '52',
  },
  {
    short: 'MY',
    zh: '马来西亚',
    en: 'Malaysia',
    value: '60',
  },
  {
    short: 'MZ',
    zh: '莫桑比克',
    en: 'Mozambique',
    value: '258',
  },
  {
    short: 'NA',
    zh: '纳米比亚',
    en: 'Namibia',
    value: '264',
  },
  {
    short: 'NE',
    zh: '尼日尔',
    en: 'Niger',
    value: '977',
  },
  {
    short: 'NG',
    zh: '尼日利亚',
    en: 'Nigeria',
    value: '234',
  },
  {
    short: 'NI',
    zh: '尼加拉瓜',
    en: 'Nicaragua',
    value: '505',
  },
  {
    short: 'NL',
    zh: '荷兰',
    en: 'Netherlands',
    value: '31',
  },
  {
    short: 'NO',
    zh: '挪威',
    en: 'Norway',
    value: '47',
  },
  {
    short: 'NP',
    zh: '尼泊尔',
    en: 'Nepal',
    value: '977',
  },
  {
    short: 'NR',
    zh: '瑙鲁',
    en: 'Nauru',
    value: '674',
  },
  {
    short: 'NZ',
    zh: '新西兰',
    en: 'NewZealand',
    value: '64',
  },
  {
    short: 'OM',
    zh: '阿曼',
    en: 'Oman',
    value: '968',
  },
  {
    short: 'PA',
    zh: '巴拿马',
    en: 'Panama',
    value: '507',
  },
  {
    short: 'PE',
    zh: '秘鲁',
    en: 'Peru',
    value: '51',
  },
  {
    short: 'PF',
    zh: '法属玻利尼西亚',
    en: 'FrenchPolynesia',
    value: '689',
  },
  {
    short: 'PG',
    zh: '巴布亚新几内亚',
    en: 'PapuaNewCuinea',
    value: '675',
  },
  {
    short: 'PH',
    zh: '菲律宾',
    en: 'Philippines',
    value: '63',
  },
  {
    short: 'PK',
    zh: '巴基斯坦',
    en: 'Pakistan',
    value: '92',
  },
  {
    short: 'PL',
    zh: '波兰',
    en: 'Poland',
    value: '48',
  },
  {
    short: 'PR',
    zh: '波多黎各',
    en: 'PuertoRico',
    value: '1787',
  },
  {
    short: 'PT',
    zh: '葡萄牙',
    en: 'Portugal',
    value: '351',
  },
  {
    short: 'PY',
    zh: '巴拉圭',
    en: 'Paraguay',
    value: '595',
  },
  {
    short: 'QA',
    zh: '卡塔尔',
    en: 'Qatar',
    value: '974',
  },
  {
    short: '',
    zh: '留尼旺',
    en: 'Reunion',
    value: '262',
  },
  {
    short: 'RO',
    zh: '罗马尼亚',
    en: 'Romania',
    value: '40',
  },
  {
    short: 'RU',
    zh: '俄罗斯',
    en: 'Russia',
    value: '7',
  },
  {
    short: 'SA',
    zh: '沙特阿拉伯',
    en: 'SaudiArabia',
    value: '966',
  },
  {
    short: 'SB',
    zh: '所罗门群岛',
    en: 'SolomonIs',
    value: '677',
  },
  {
    short: 'SC',
    zh: '塞舌尔',
    en: 'Seychelles',
    value: '248',
  },
  {
    short: 'SD',
    zh: '苏丹',
    en: 'Sudan',
    value: '249',
  },
  {
    short: 'SE',
    zh: '瑞典',
    en: 'Sweden',
    value: '46',
  },
  {
    short: 'SG',
    zh: '新加坡',
    en: 'Singapore',
    value: '65',
  },
  {
    short: 'SI',
    zh: '斯洛文尼亚',
    en: 'Slovenia',
    value: '386',
  },
  {
    short: 'SK',
    zh: '斯洛伐克',
    en: 'Slovakia',
    value: '421',
  },
  {
    short: 'SL',
    zh: '塞拉利昂',
    en: 'SierraLeone',
    value: '232',
  },
  {
    short: 'SM',
    zh: '圣马力诺',
    en: 'SanMarino',
    value: '378',
  },
  {
    short: 'AS',
    zh: '东萨摩亚(美)',
    en: 'SamoaEastern',
    value: '684',
  },
  {
    short: 'WS',
    zh: '西萨摩亚',
    en: 'SanMarino',
    value: '685',
  },
  {
    short: 'SN',
    zh: '塞内加尔',
    en: 'Senegal',
    value: '221',
  },
  {
    short: 'SO',
    zh: '索马里',
    en: 'Somali',
    value: '252',
  },
  {
    short: 'SR',
    zh: '苏里南',
    en: 'Surizh',
    value: '597',
  },
  {
    short: 'ST',
    zh: '圣多美和普林西比',
    en: 'SaoTomeandPrincipe',
    value: '239',
  },
  {
    short: 'SV',
    zh: '萨尔瓦多',
    en: 'EISalvador',
    value: '503',
  },
  {
    short: 'SY',
    zh: '叙利亚',
    en: 'Syria',
    value: '963',
  },
  {
    short: 'SZ',
    zh: '斯威士兰',
    en: 'Swaziland',
    value: '268',
  },
  {
    short: 'TD',
    zh: '乍得',
    en: 'Chad',
    value: '235',
  },
  {
    short: 'TG',
    zh: '多哥',
    en: 'Togo',
    value: '228',
  },
  {
    short: 'TH',
    zh: '泰国',
    en: 'Thailand',
    value: '66',
  },
  {
    short: 'TJ',
    zh: '塔吉克斯坦',
    en: 'Tajikstan',
    value: '992',
  },
  {
    short: 'TM',
    zh: '土库曼斯坦',
    en: 'Turkmenistan',
    value: '993',
  },
  {
    short: 'TN',
    zh: '突尼斯',
    en: 'Tunisia',
    value: '216',
  },
  {
    short: 'TO',
    zh: '汤加',
    en: 'Tonga',
    value: '676',
  },
  {
    short: 'TR',
    zh: '土耳其',
    en: 'Turkey',
    value: '90',
  },
  {
    short: 'TT',
    zh: '特立尼达和多巴哥',
    en: 'TrinidadandTobago',
    value: '1809',
  },
  {
    short: 'TW',
    zh: '台湾（中国）',
    en: 'Taiwan',
    value: '886',
  },
  {
    short: 'TZ',
    zh: '坦桑尼亚',
    en: 'Tanzania',
    value: '255',
  },
  {
    short: 'UA',
    zh: '乌克兰',
    en: 'Ukraine',
    value: '380',
  },
  {
    short: 'UG',
    zh: '乌干达',
    en: 'Uganda',
    value: '256',
  },
  {
    short: 'US',
    zh: '美国',
    en: 'UnitedStatesofAmerica',
    value: '1',
  },
  {
    short: 'UY',
    zh: '乌拉圭',
    en: 'Uruguay',
    value: '598',
  },
  {
    short: 'UZ',
    zh: '乌兹别克斯坦',
    en: 'Uzbekistan',
    value: '233',
  },
  {
    short: 'VC',
    zh: '圣文森特岛',
    en: 'SaintVincent',
    value: '1784',
  },
  {
    short: 'VE',
    zh: '委内瑞拉',
    en: 'Venezuela',
    value: '58',
  },
  {
    short: 'VN',
    zh: '越南',
    en: 'Vietnam',
    value: '84',
  },
  {
    short: 'YE',
    zh: '也门',
    en: 'Yemen',
    value: '967',
  },
  {
    short: 'YU',
    zh: '南斯拉夫',
    en: 'Yugoslavia',
    value: '381',
  },
  {
    short: 'ZA',
    zh: '南非',
    en: 'SouthAfrica',
    value: '27',
  },
  {
    short: 'ZM',
    zh: '赞比亚',
    en: 'Zambia',
    value: '260',
  },
  {
    short: 'ZR',
    zh: '扎伊尔',
    en: 'Zaire',
    value: '243',
  },
  {
    short: 'ZW',
    zh: '津巴布韦',
    en: 'Zimbabwe',
    value: '263',
  },
];
