// 消息类型
export const MESSAGETYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  ZAN: 'ZAN',
  FLOWERS: 'FLOWERS',
  EMOJI: 'EMOJI',
  AT_TEXT: 'AT_TEXT',
  AV_CHAT_ROOM: 'AV_CHAT_ROOM',
  PRIVATE: 'PRIVATE',
  WELCOME: 'WELCOME',
  SYSTEM_NOTICE: 'SYSTEM_NOTICE',
  FULL_SCREAM: 'FULL_SCREAM',
};

// 增加聊天记录
export const ADD_CHAT_HISTORY = 'ADD_CHAT_HISTORY';
// 增加当前用户发送的信息
export const ADD_CURRENT_USER_CHAT_MESSAGE = 'ADD_CURRENT_USER_CHAT_MESSAGE';
// 删除聊天记录
export const DEL_CHAT_HISTORY = 'DEL_CHAT_HISTORY';
// 清空聊天记录
export const CLEAR_CHAT_HISTORY = 'CLEAR_CHAT_HISTORY';
