<template>
  <div>
    <MenuPopup ref="menuPopupRef" @menuType="menuType"></MenuPopup>
    <LoginModel ref="loginModelRef"/>
    <!--    <TipsModel ref="tipsModelRef" @clickHandle="tipsModelClick"/>-->
    <ClassScheduleModel ref="classScheduleModelRef"/>
    <!--    <PickUpSignalModel ref="pickUpSignalModelRef"/>-->
    <LiveListModel ref="liveListModelRef"/>
    <DealModel @showDetail="showDetail" ref="dealModelRef"/>
    <!--    <UserCenter ref="userCenterRef"/>-->
    <PickUpSignalModel ref="pickUpSignalModelRef"/>
    <DealDetailModel ref="dealDetailModelRef"/>
    <BannerModel ref="bannerModelRef"/>
    <CenterHome ref="userCenterRef" @changeAmend="changeAmend"></CenterHome>
    <!-- 头像修改 -->
    <AvatarAmend ref="avatarAmendRef"></AvatarAmend>
    <Customer ref="customerRef"></Customer>
    <ChatModel ref="chatModelRef"/>
    <ActivityList @showDetail="showActivityDetail" ref="activityListRef"/>
    <AboutModel ref="aboutModelRef"></AboutModel>
    <CalendarModel ref="calendarModelRef"></CalendarModel>
    <LuckyModel ref="luckyModelRef"></LuckyModel>
  </div>
</template>

<script>
import Customer from '@/payerH5/components/model/app/Customer.vue';
import AvatarAmend from '@/payerH5/components/model/app/avatarAmend.vue';
import MenuPopup from '@/payerH5/components/model/app/menuPopup';
import LoginModel from '@/payerH5/components/model/app/loginModel.vue';
// import TipsModel from '@/components/model/pc/tipsModel.vue';
import ClassScheduleModel from '@/payerH5/components/model/app/classScheduleModel';
import PickUpSignalModel from '@/payerH5/components/model/app/pickUpSignalModel';
import LiveListModel from '@/payerH5/components/model/app/liveListModel';
import BannerModel from '@/payerH5/components/model/app/bannerModel';
import DealModel from '@/payerH5/components/model/app/dealModel';
// import UserCenter from '@/components/model/pc/userCenter';
// import PickUpSignalModel from '@/payerH5/components/model/app/openAnAccountModel';
import {
  LOGIN,
  REGISTER,
  CLASS_SCHEDULE,
  MEMBERSHIP_PRIVILEGES,
  TRANSACTION_PARAMETERS,
  PROFIT_STATISTICS,
  BROADCASTING_ROOM,
  PICK_UP_SIGNAL,
  TRANSACTION_PARAMETERS_DETAIL,
  // VIP_REGISTER_TIP,
  // OPEN_ACCOUNT,
  MENUPOPUP,
  USER_INFO,
  CUSTOMER, PRIVATE_CHAT, ACTIVITY, ABOUT, CALENDAR, LUCKY_ACTIVITY,
}
  from 'constants/sys';
import DealDetailModel from '@/payerH5/components/model/app/dealDetailModel';
import { mapGetters } from 'vuex';
import CenterHome from '@/payerH5/components/model/app/centerHome.vue';
import ChatModel from '@/payerH5/components/model/app/chatModel.vue';
import {localStorage} from "utils/storage";
import ActivityList from "@/payerH5/components/model/app/activityListModel.vue";
import AboutModel from "@/payerH5/components/model/app/aboutModel.vue";
import CalendarModel from "@/payerH5/components/model/app/calendarModel.vue";
import LuckyModel from "@/payerH5/components/model/app/luckyModel.vue";

export default {
  components: {
    LuckyModel,
    CalendarModel,
    AboutModel,
    ActivityList,
    ChatModel,
    AvatarAmend,
    DealDetailModel,
    MenuPopup,
    DealModel,
    LiveListModel,
    ClassScheduleModel,
    PickUpSignalModel,
    BannerModel,
    LoginModel,
    CenterHome,
    Customer,
  },
  data() {
    return {
      LoginModelShow: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    changeAmend() {
      this.$refs.avatarAmendRef.overlayShow();
    },
    showDetail(d) {
      const {tourist} = localStorage.get('authData') || {};
      if (tourist) {
        this.$refs.dealModelRef.overlayHide();
        this.$refs.loginModelRef.overlayShow(REGISTER);
        return
      }
      if (this.userInfo.level >= 3) {
        //overlayHide

        this.show(TRANSACTION_PARAMETERS_DETAIL, d.id);
      }else{
        layer.msg(this.$t('CantGetSignal tips'));
        setTimeout(()=>{
          // window.ECHAT.customMiniChat();
          window.open('https://t.me/XC600broker1', '_blank');

        },2000)
      }
    },
    showActivityDetail(d){
      this.show(TRANSACTION_PARAMETERS_DETAIL, d.id);
    },
    menuType(e) {
      this.show(e);
    },
    show(type, data) {
      switch (type) {
        case LOGIN:
        case REGISTER:
          this.$refs.loginModelRef.overlayShow(type);
          break;
        case CLASS_SCHEDULE:
          this.$refs.classScheduleModelRef.overlayShow();
          break;
        case BROADCASTING_ROOM:
          this.$refs.liveListModelRef.overlayShow();
          break;
        case TRANSACTION_PARAMETERS:
          this.$refs.dealModelRef.overlayShow();
          break;
        case TRANSACTION_PARAMETERS_DETAIL:
          this.$refs.dealDetailModelRef.overlayShow(data);
          break;
          // case PICK_UP_SIGNAL:
          //   this.$refs.pickUpSignalModelRef.show();
          //   break;
        case USER_INFO:
          this.$refs.userCenterRef.overlayShow();
          break;
          // case VIP_REGISTER_TIP:
          //   this.$refs.tipsModelRef.show();
          //   break;
        case PICK_UP_SIGNAL:
          this.$refs.pickUpSignalModelRef.overlayShow();
          break;
        case MEMBERSHIP_PRIVILEGES:
        case PROFIT_STATISTICS:
          this.$refs.bannerModelRef.overlayShow(type);
          break;
        case MENUPOPUP:
          this.$refs.menuPopupRef.overlayShow();
          break;
        case PRIVATE_CHAT:
          this.$refs.chatModelRef.overlayShow(data || {});
          break;
        case CUSTOMER:
          // this.$refs.customerRef.overlayShow();
          // window.ECHAT.customMiniChat();
          window.open('https://t.me/XC600broker1', '_blank');

          break;
        case ACTIVITY:
          // this.$refs.customerRef.overlayShow();
          this.$refs.activityListRef.overlayShow();
          break;
        case ABOUT:
          // this.$refs.customerRef.overlayShow();
          this.$refs.aboutModelRef.overlayShow();
          break;
        case CALENDAR:
          this.$refs.calendarModelRef.overlayShow();
          break;
        case LUCKY_ACTIVITY:
          this.$refs.luckyModelRef.overlayShow();
          break;
      }
    },
    tipsModelClick(type) {
      this.show(type);
    },
  },
};
</script>

<style>
</style>
