<template>
  <van-popup  :position="position" :style="stylePopup" :close-on-click-overlay="false"
             v-on="$listeners"  :closeable="closeable || false" v-bind="$attrs">
        <div class="H5_dealTitle">{{titleTop}}</div>
        <slot></slot>
  </van-popup>
</template>

<script>
export default {
  name: 'popup.vue',
  props: ['position', 'stylePopup', 'closeable', 'titleTop'],
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.van-popup{
  background-color: #1A1A1C;
  color: #fff;
}
.H5_dealTitle{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  padding-left: 20px;
}
</style>
