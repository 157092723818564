<template>
  <MyDialog :diaVisible="dialog.isShow" width="450px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="headBox">
        <div class="headBox-t">
          <img :src="userInfo.avatar||avatar" alt="" class="headImg">
          <i class="el-icon-edit-outline" @click="amendChange('avatarAmend')"></i>
        </div>
        <div class="headText">{{ userInfo.nickName }} &nbsp;
<!--          <i class="el-icon-edit-outline" style="color: #BABABA;" @click="amendChange('nicknameAmend')"></i>-->
        </div>
      </div>
      <el-form label-width="80px" :model="userInfo">
        <el-form-item label="邮箱:">
          <el-input disabled v-model="userInfo.email"></el-input>
          <el-button type="primary" plain @click="amendChange('emailAmend')">修改</el-button>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input disabled></el-input>
          <el-button type="primary" plain @click="amendChange('passwordAmend')">修改</el-button>
        </el-form-item>
        <el-form-item label="登录账号:">
          <el-input disabled v-model="userInfo.userName"></el-input>
        </el-form-item>
        <div style="text-align: center">
          <el-button type="primary" size="small" @click="logOutHandle">{{ $t('loginOut') }}</el-button>
        </div>
      </el-form>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import avatar from '@/assets/img/avatar.png';

export default {
  name: 'TipsModel',
  components: { MyDialog },
  data() {
    return {
      avatar,
      dialog: {
        isShow: false,
      },
      formLabelAlign: {
        name: '',
        region: '',
        type: '',
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions(['logOut']),
    logOutHandle() {
      this.logOut();
      this.close();

      setTimeout(()=>{
          location.reload()
      })

    },
    close() {
      this.dialog.isShow = false;
    },
    show() {
      this.dialog.isShow = true;
    },
    amendChange(val) {
      this.$emit('changeAmend', val);
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
}

.el-icon-edit-outline {
  cursor: pointer;
}

.headBox {
  text-align: center;
  margin: 20px 0 40px 0;

  .headBox-t {
    position: relative;

    .headImg {
      width: 60px;
      height: 60px;
      border-radius: 20px;
    }

    .el-icon-edit-outline {
      font-size: 12px;
      background: #fff;
      position: absolute;
      border-radius: 20px;
      padding: 2px;
      bottom: 0;
      right: 42%;
    }
  }

  .headText {
    font-size: 14px;
    color: #fff;
    margin-top: 15px;
  }
}

.el-form-item {
  /deep/ .el-form-item__label {
    color: #fff;
  }

  /deep/ .el-form-item__content {
    display: flex;

    .el-input__inner {
      background-color: #0c0c0d;
      border-color: #0c0c0d;
      color: #bababa;
    }

    .el-button {
      margin-left: 8px;
    }

    .el-button--primary.is-plain {
      background-color: #0c0c0d;
      border-color: #c3312c;
    }
  }
}
</style>
