<template>
  <div class="liveingBox">
    <el-carousel v-if="courseListLen" loop class="liveingContainer" trigger="click" height="190px">
      <el-carousel-item :key="item.id" v-for="item in courseList"  >
        <div class="liveingContainer_item" @click="handleClick(item.roomId)">
          <!--        <img :src="item.cover" class="liveingContainer_item__top">-->
          <div class="liveingContainer_item__bottom">
            <div class="title_container">
              <div class="left">
                <div class="title">
                  {{ item.title }}
                </div>
                <el-tag class="tag" v-if="item.facilitator" effect="plain" size="small">{{ item.facilitator }}</el-tag>
              </div>
              <div class="right">
                ({{ item.beginTime }}-{{ item.endTime }})
              </div>
            </div>
            <div class="subTitle">
              {{ item.teacherName }}
            </div>
            <div class="content">
              {{ item.remark }}
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div v-else-if="courseEmptyCoverListLen" >
<!--      {{ $t('noData') }}-->
      <el-carousel v-if="courseEmptyCoverListLen" loop class="liveingContainer" indicator-position="none" autoplay  height="190px">
        <el-carousel-item :key="item.id" v-for="item in banners">
          <img :src="item.imgUrl" style="width: 100%;height: 190px"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else class="liveingContainer notData">{{ $t('noData') }}</div>
  </div>

</template>

<script>
import {getBanners, getCurrentLiveCourseList} from '@/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LiveingRoomBanner',
  data() {
    return {
      courseList: [],
      banners: [],
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
    courseListLen() {
      return this.courseList.length || 0;
    },
    courseEmptyCoverListLen() {
      return this.banners.length || 0;
    },
  },
  watch: {
    currentRoomId: {
      handler(id) {
        if (id) {
          // 获取无正在直播中课程时的封面
          getBanners({ roomId: id, extraFlag: 'live_empty_cover' })
            .then((res) => {
              this.banners = res.data;
            });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    getCurrentLiveCourseList()
      .then((res) => {
        this.courseList = res.data;
      });
  },
  methods: {
    ...mapActions(['changeLiveRoom']), // 获取直播间列表
    handleClick(id) {
      console.log(this.currentRoomId, id);
      if (this.currentRoomId !== id) {
        this.changeLiveRoom(id);
      }
    },
  },

};
</script>

<style scoped lang="less">
.liveingBox{
  flex: 1;
}
.liveingContainer {
  //flex: 1;
  //overflow-y: auto;
  //max-width: 320px;
  //background: #f00;
  background: rgba(27,27,29,1);
  &_item {
    //margin-bottom: 12px;
    height: 190px;
    font-size: 0;

    &__top {
      height: 94px;
      width: 100%;
    }

    &__bottom {
      //background: rgba(27, 27, 29);
      height: 100%;
      background: rgba(27, 27, 29);
      padding: 13px 12px 16px;
      color: #FFFFFF;

      .title_container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .left {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          width: 0;
          margin-right: 10px;

          .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: #FFFFFF;
            opacity: 1;
            margin-right: 6px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .tag {
            display: flex;
            align-items: center;
            padding: 1px 4px;
            background: rgba(60, 28, 30);
            border-radius: 2px;
            font-size: 12px;
            font-weight: 300;
            color: #FFFFFF;
          }
        }

        .right {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
        }

      }

      .subTitle {
        margin-bottom: 8px;
        width: 109px;
        height: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #D51820;
        opacity: 1;
      }

      .content {
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
      }
    }
  }

}

.notData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 190px;
  color: #fff;
}

/deep/ .el-carousel__indicators {
  .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  //padding: 0px;

}
</style>
