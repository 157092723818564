<template>
    <div :class="['H5_chatItemContainer',isAt?'AV_CHAT_ROOM':'']">
        <div class="H5_top">
            <div class="H5_info">
                <img :src="datas.avatar" class="H5_avatar" alt="">
                <div class="H5_level" v-if="datas.levelIcon">
                    <img :src="datas.levelIcon"/>
                </div>
                <div class="H5_name" :style="`color:${datas.nicknameColor}`">{{ datas.nickname }}</div>
                <div class="H5_time">{{ fomartDate(datas.messageTimeStamp, 'MM-DD HH:mm:ss') }}</div>
                <!--        <div class="area">中国</div>-->
            </div>
            <div class="H5_operation" v-if="isAdmin">
                <div class="H5_operation_item" v-for="(item,index) in opts" :key="index">
                    {{ $t(item.type) }}
                </div>
            </div>
        </div>
        <div class="H5_content" :style="`color:${datas.messageColor}` ">
            <template v-for="(item,index) in datas.messageBodys">
                <div :key="index" v-if="item.messageType===MESSAGETYPE.TEXT" v-viewer
                     v-html="item.messageBody && imgTagAddStyle(item.messageBody)"></div>
                <div :key="index" v-else-if="item.messageType===MESSAGETYPE.AT_TEXT"
                     v-viewer
                     v-html="item.messageBody && imgTagAddStyle(item.messageBody)"></div>
                <img
                        class="H5_emoji_img"
                        :key="index"
                        v-else-if="item.messageType===MESSAGETYPE.EMOJI"
                        :src="item.messageBody"
                        width="20"
                        height="20"
                />
                <el-image
                        v-else
                        :key="index"
                        :src="item.messageBody"
                        :preview-src-list="[item.messageBody]"
                        :class="['H5_chat_img',`H5_${item.messageType}`]"
                />
            </template>

        </div>
    </div>
</template>

<script>
import logo from 'assets/img/logo.png';
import assistantIcon from 'assets/img/assistant.png';
// import memberLevel1 from '@/assets/img/memberLevel_1.png';
// import memberLevel2 from '@/assets/img/memberLevel_2.png';
// import memberLevel3 from '@/assets/img/memberLevel_3.png';
// import memberLevel4 from '@/assets/img/memberLevel_4.png';
// import memberLevel5 from '@/assets/img/memberLevel_5.png';
// import memberLevel6 from '@/assets/img/memberLevel_6.png';
// import memberLevel7 from '@/assets/img/memberLevel_7.png';
import {MESSAGETYPE} from './constants/chat';
import {fomartDate} from 'utils/utils';
import {mapGetters} from 'vuex';

export default {
    H5_name: 'chatItem',
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        },
        datas: {
            type: Object,
            defaulr: {},
        },
    },
    data() {
        return {
            MESSAGETYPE,
            logo,
            assistantIcon,
            levelIcon: [],
            opts: [
                {type: 'chatItem.Allow'},
                {type: 'chatItem.Refuse'},
                {type: 'chatItem.Delete'},
            ],
        };
    },
    computed: {
        ...mapGetters(['userInfo']),
        isAt() {
            return this.datas.atUserId === this.userInfo.userId;
        },

        previewImgs: () => {
            console.log(this.datas.messageBody, 'this.datas.messageBody');
            try {
                return this.datas.messageBody.filter(item => item.messageType === MESSAGETYPE.IMAGE);
            } catch (e) {
                return [];
            }
        },
    },
    methods: {
        fomartDate,
        imgTagAddStyle(htmlstr) {
            let newContent = htmlstr.replace(/<img[^>]*>/gi, (match, capture) => {
                match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                return match;
            });
            newContent = newContent.replace(/style="[^"]+"/gi, (match, capture) => {
                match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
                return match;
            });
            newContent = newContent.replace(/<br[^>]*\/>/gi, '');
            // eslint-disable-next-line no-useless-escape
            newContent = newContent.replace(/\<img/gi, '<img class="im-item-preview" style="max-width:100%;height:auto;display:inline-block;margin:8px 0;"');

            return newContent;
        },
    },
};
</script>

<style scoped lang="less">
.H5_chatItemContainer {
  padding: 16px 5px 10px 5px;
  //border-bo: 1px solid #393939;
  border-bottom: 1px solid #393939;

  .H5_top {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .H5_info {
      display: flex;
      align-items: center;
      flex: 1;

      .H5_avatar {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 12px;
        object-fit: contain;
      }

      .H5_name {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
        font-size: 15px;
        font-weight: 400;
        color: #BABABA;
        margin-right: 8px;
      }

      .H5_level {
        margin-right: 6px;
        width: 66px;
        height: 22px;

        img {
          width: 60px;
          height: 20px;
        }
      }

      .H5_time {
        //flex: 1;
        //text-align: right;
        //margin-right: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #767676;
      }

      .area {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #D51820;
        margin-right: 10px;
      }
    }

    .H5_operation {
      display: inline-flex;

      &_item {
        padding: 3px 6px;
        background: #602427;
        border-radius: 2px;
        margin-right: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }


  .H5_content {
    //display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    padding-left: 48px;
    word-break: break-all;
    //color: #9FFB55;
    color: #fff;
    opacity: 1;

    .H5_emoji_img {
      width: 20px;
      height: 20px;
    }

    .H5_chat_img {
      max-width: 80%;
    }

    img, span {
      display: inline-flex;
      align-items: flex-end;
    }

    .H5_FLOWERS {
      width: 110px;
      height: 22px;
    }

    .H5_ZAN {
      width: 144px;
      height: 18px;
    }
  }
}

@keyframes flicker {
  50% {
    box-shadow: inset 0 0 26px -8px rgb(255 0 0);
  }
}

.AV_CHAT_ROOM {
  animation: flicker .5s 10;
  animation-iteration-count: 10;
}

</style>
