<template>
<div v-infinite-scroll="getList"
     :infinite-scroll-disabled="disabled"
     infinite-scroll-immediate='false'>
  <slot :itemData="item" :$index="index" v-for="(item,index) in list" ></slot>
  <div style="text-align: center">
    <p v-if="loading">加载中...</p>
    <p v-if="noMore">没有更多了</p>
  </div>
</div>
</template>

<script>

export default {
  name: 'index',
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    reqHandle: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      list: [],
      offset: 0,
      loading: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  computed: {
    noMore() {
      return this.offset >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.reqHandle({ ...this.params, pageSize: this.pageSize, pageNum: this.pageNum })
        .then((res) => {
          if (res.code === 200) {
            this.total = res.total;
            this.list = this.list.concat(res.rows);
            this.pageNum += 1;
            this.offset += 10;
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style scoped>

</style>
