<template>
  <MyDialog :diaVisible="dialog.isShow" @close="close" :noFooter="true">
    <template #dia_Content>
      <!-- 添加的内容 -->
      <div class="login">
        <div style="width: 50%;text-align: center;padding-top: 25px">
          <img class="loginLogo" src="@/assets/img/loginLogo.png"/>
          <img class="loginImg" src="@/assets/img/loginImg.png"/>
        </div>
        <el-card>
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
            <div class="title">
              <div :class="[handleSignShow ? 'sgin-one' : 'sgin-two']" @click="()=>{handleSignShow=true}">
                {{ $t('login.Sign in') }}
              </div>
              <div :class="[!handleSignShow ? 'sgin-one' : 'sgin-two']" @click="()=>{handleSignShow=false}">
                {{ $t('login.Sign up') }}
              </div>
            </div>
            <template v-if="handleSignShow">
              <el-form-item prop="username">
                <el-input v-model="loginForm.username" type="text" auto-complete="off"
                          :placeholder="$t('login.userName')"/>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="loginForm.password" :type="passwordShow ? 'text' : 'password'" auto-complete="off"
                          :placeholder="$t('login.EnterPassword')">
                  <template #suffix>
                    <img class="passwordImg" @click="passwordShow = !passwordShow"
                         :src="passwordShow? require('@/assets/img/eyes.png') : require('@/assets/img/eye-open.png')"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <div class="forget" v-if="handleSignShow">{{ $t('login.forgetPassword') }}</div>
              <el-form-item>
                <el-button :loading="loading" type="primary" style="width: 100%" @click="loginHandle">
                  {{ $t('login.Sign in') }}
                </el-button>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item prop="username">
                <el-input
                    v-model="loginForm.username"
                    type="text"
                    auto-complete="off"
                    :placeholder="$t('login.userName')"
                />
              </el-form-item>
              <el-form-item prop="phone">
                <el-input
                    v-model="loginForm.phone"
                    type="text"
                    auto-complete="off"
                    :placeholder="$t('login.phoneNumber')"
                >
                  <el-select slot="prepend" style="width: 100px" v-model="loginForm.countryCode">
                    <el-option
                        v-for="(item,index) in countryCodes"
                        :key="index"
                        :label="item.label"
                        :value="index">
                      <div class="countryOption">
                        <img class="countryFlag" :src="getImgCode(item.short.toLowerCase())"/>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}
                      </span>
                      </div>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <!--              <el-form-item prop="email">-->
              <!--                <el-input-->
              <!--                    v-model="loginForm.email"-->
              <!--                    type="text"-->
              <!--                    auto-complete="off"-->
              <!--                    :placeholder="$t('login.email')"-->
              <!--                />-->
              <!--              </el-form-item>-->
              <el-form-item prop="password">
                <el-input
                    auto-complete="off"
                    v-model="loginForm.password"
                    :type="passwordShow ? 'text' : 'password'"
                    :placeholder="$t('login.EnterPassword')"
                >
                  <template #suffix>
                    <img class="passwordImg"
                         @click="passwordShow = !passwordShow"
                         :src="passwordShow?eyeIcon : eyeOpenIcon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <!--              <el-form-item class="codeFormContainer" prop="emailCode" v-if="!handleSignShow">-->
              <!--                <el-row :gutter="5">-->
              <!--                  <el-col :span="14">-->
              <!--                    <el-input-->
              <!--                        v-model="loginForm.emailCode"-->
              <!--                        auto-complete="off"-->
              <!--                        :placeholder="$t('login.VerificationCode')"-->
              <!--                    />-->
              <!--                  </el-col>-->
              <!--                  <el-col :span="10">-->
              <!--                    <CountDowm ref="countDowmRef">-->
              <!--                      <template slot-scope="{disabled,massage}">-->
              <!--                        <el-button-->
              <!--                            type="primary"-->
              <!--                            :disabled="!loginForm.email"-->
              <!--                            @click="sendCode"-->
              <!--                            class="codeBtn"-->
              <!--                        >-->
              <!--                          {{ massage }}-->
              <!--                        </el-button>-->
              <!--                      </template>-->
              <!--                    </CountDowm>-->
              <!--                  </el-col>-->
              <!--                </el-row>-->
              <!--              </el-form-item>-->
<!--              <el-checkbox v-model="loginForm.agreement" style="margin-bottom: 5px;">
                {{ $t('login.Agreement') }}
              </el-checkbox>-->

              <el-form-item>
                <el-button
                    :disabled="!loginForm.agreement"
                    @click="registerHandle"
                    type="primary"
                    :loading="regLoading"
                    style="width: 100%"
                >
                  {{ $t('login.Sign up') }}
                </el-button>
              </el-form-item>
            </template>
          </el-form>

          <div style="font-weight: bold">
            {{ $t("login.tips") }}
          </div>

        </el-card>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { mapActions, mapMutations } from 'vuex';
import * as mutationTypes from 'constants/mutation-types';
import eyeIcon from '@/assets/img/eyes.png';
import eyeOpenIcon from '@/assets/img/eye-open.png';
import { REGISTER } from 'constants/sys';
import { getCaptchaEmail, userRegister } from '@/api';
// import CountDowm from '@/components/countDowm';
import countryList from 'utils/countryList';
import { localStorage } from 'utils/storage';
// CountDowm
export default {
  name: 'LoginModel',
  components: { MyDialog },
  data() {
    return {
      eyeIcon,
      eyeOpenIcon,
      handleSignShow: true,
      dialog: {
        isShow: false,
      },
      passwordShow: false,
      codeUrl: '',
      loginForm: {
        countryCode: 0,
        username: '',
        password: '',
        agreement: true,
        phone: '',
        email: '',
        emailCode: '1234',
        uuid: '',
      },
      loading: false,
      regLoading: false,
      // 注册开关
      register: false,
      redirect: undefined,
      dialogVisible: false,
    };
  },
  computed: {
    loginRules() {
      return {
        username: [
          { required: true, trigger: 'blur', message: this.$t('login.userName') },
        ],
        password: [
          { required: true, trigger: 'blur', message: this.$t('login.EnterPassword') },
        ],
        phone: [{ required: true, trigger: 'change', message: this.$t('login.phoneNumber') }],
      };
    },
    countryCodes() {
      const list = countryList;

      console.log(this.$t('lang'), '$t(\'lang\')');
      // const type = this.$t('lang') === '中文' ? 'zh' : 'en';
      return list.map(item => ({
        label: `${item.value}`,
        ...item,
      }));
    },
  },
  mounted() {
    console.log(this.countryCode);
  },
  methods: {
    ...mapActions(['userLogin']),
    ...mapMutations([mutationTypes.SET_TOKEN]),
    getImgCode(name) {
      const path = `${name}.png`;
      return `https://ychpro.oss-ap-southeast-1.aliyuncs.com/common/flag/${path}`;
    },
    // 关闭dialog
    close() {
      this.dialog.isShow = false;
    },
    // 确定dialog
    show(type) {
      console.log(type);
      this.handleSignShow = type !== REGISTER;
      if (this.handleSignShow) {
        const loginData = localStorage.get('loginData') || {};
        console.log(loginData, 'loginData');
        this.loginForm = {
          ...this.loginForm,
          ...loginData,
        };
      }
      console.log('MEMBERSHIP_PRIVILEGES');
      this.dialog.isShow = true;
    },
    // 取消dialog
    cancel() {
      this.dialog.isShow = false;
      this.$emit('hide');
    },
    registerHandle() {
      this.regLoading = true;
      const countryCode = this.countryCodes[this.loginForm.countryCode].value;
      userRegister({ ...this.loginForm, phone: `${countryCode}${this.loginForm.phone}` })
          .then((res) => {
            this.regLoading = false;
            console.log(res);
            if (res.code === 200) {
              this.handleSignShow = true;
            }
          });
    },
    loginHandle() {
      this.loading = true;
      const { username, password } = this.loginForm;
      this.userLogin({ username, password })
          .then((res) => {
            if (res.code === 200) {
              this.cancel();
                setTimeout(function(){
                    location.reload();
                }, 1000);
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    sendCode() {
      getCaptchaEmail({ email: this.loginForm.email })
          .then((res) => {
            if (res.code === 200) {
              this.$refs.countDowmRef.getCode();
            }
          });
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
  },
};
</script>

<style lang="less">
.countryOption {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .countryFlag {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

}

</style>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #712327;
}

.login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //height: 392px;

  .loginLogo {
    height: 40px;
  }


  .loginImg {
    width: 350px;
  }

  .el-card {
    width: 350px;
    height: 100%;

    .title {
      display: flex;
      width: 78%;
      //justify-content: space-between;
      align-items: center;
      margin: 10px 0 20px 0;

      .sgin-one {
        margin-right: 34px;
        cursor: pointer;
        font-size: 24px;
        color: #d51820;
      }

      .sgin-two {
        margin-right: 34px;
        cursor: pointer;
        font-size: 16px;
        color: #bababa;
      }
    }

    .codeFormContainer {
      .codeBtn {
        width: 100%;
        //margin-left: 10px;
      }
    }

    .passwordImg {
      height: 100%;
      width: 40px;
      padding: 10px;
      cursor: pointer;
    }

    .forget {
      word-break: break-word;
      font-size: 12px;
      color: #767676;
      margin: 0 0 30px 0;
    }

    /deep/ .el-checkbox__label {
      font-size: 12px;
      // color: #767676;
    }
  }

  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
}
</style>
