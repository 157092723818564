<template>
  <Popup v-model="popupShow"  position="bottom"  :closeable="true" :stylePopup="{height:'60%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('header.Broadcast')">
      <div class="H5_listItemBox">
        <LoadPageList :params="{}" :reqHandle="getLiveRoomList">
          <template slot-scope="{itemData,$index}">
            <div class="H5_listItem" @click="handleClick(itemData.id)" :key="$index">
              <img class="H5_imgItem" :src="itemData.cover" alt="">
              <div class="H5_listItem-r">
                <p class="H5_roomTitle van-multi-ellipsis--l2">
                  {{ itemData.name }}
<!--                  <span class="H5_EnglishBox">{{ itemData.language }}</span>-->
                </p>
                <p class="teacherName">{{ itemData.teacherName }}</p>
<!--                <div class="H5_describe">{{ itemData.remark }}</div>-->
<!--                <div class="H5_text">{{ $t('liveList.lecturer') }}：-->
<!--                  <span>{{ itemData.teacherName }}</span>-->
<!--                </div>-->
<!--                <div class="H5_text">{{ $t('liveList.introduct') }}：-->
<!--                  <span>{{ itemData.teacherRemark }}</span>-->
<!--                </div>-->
              </div>
            </div>
          </template>
        </LoadPageList>
      </div>
  </Popup>
</template>

<script>
import Popup from '@/components/popup/popup';
import { mapActions, mapGetters } from 'vuex';
import LoadPageList from '@/components/loadPageList';
import { getLiveRoomList } from '@/api';
export default {
  name: 'liveListModel.vue',
  components: {
    Popup,
    LoadPageList,
  },
  data() {
    return {
      popupShow: false,
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  methods: {
    getLiveRoomList,
    ...mapActions(['changeLiveRoom']), // 获取直播间列表
    overlayShow() {
      this.popupShow = true;
    },
    overlayHide() {
      this.popupShow = false;
    },
    handleClick(id) {
      if (this.currentRoomId !== id) {
        this.changeLiveRoom(id);
      }
      this.overlayHide();
    },
  },
};
</script>

<style lang="less" scoped>
.H5_dealTitle{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  padding-left: 20px;
}
.H5_listItemBox {
  //height: 450px;
  //overflow-y: auto;
  flex: 1;
  overflow: auto;
  padding: 0 15px;
}

.H5_listItem {
  cursor: pointer;
  background: #0c0c0d;
  display: flex;
  //align-items: center;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 15px;

  .H5_imgItem {
    width: 176px;
    height: 102px;
    border-radius: 6px;
    margin-right: 17px;
  }
}

.H5_listItem-r {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .H5_roomTitle {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;

    .H5_EnglishBox {
      border: 1px solid rgba(213, 24, 32, 0.502);
      background: #350e10;
      font-size: 12px;
      border-radius: 2px;
      margin-left: 5px;
      padding: 0 2px;
      font-weight: 300;
    }
  }
  .teacherName{
    font-size: 14px;
    font-weight: 400;
    color: #BABABA;
  }
  .H5_describe {
    font-size: 12px;
    line-height: 14px;
    color: #bababa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .H5_text {
    color: #fff;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    span {
      color: #bababa;
    }
  }
}
</style>
