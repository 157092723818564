<template>
  <MyDialog width="1044px" :diaVisible="visible" :noFooter="true" @close="close">
    <div class="courseContainer">
      <div class="headerContainer">
        <el-radio-group v-model="radio1">
          <el-radio-button :label="fomartDate(item, 'YYYY-MM-DD')" v-for="(item,index) in weekDateList" :key="index">
            <div class="btnContainer">
              <div class="btnContainer_week">{{ $t(fomartDate(item, 'dd')) }}</div>
              <div class="btnContainer_day">{{ fomartDate(item, 'MM-DD') }}</div>
            </div>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="courseList">
        <div class="courseList_item" v-for="item in courseData[radio1]" :key="item.id">
          <img :src="item.cover" class="courseList_item-cover"/>
          <div class="courseList_item_info">
            <div class="courseList_item_info-time">
              {{ item.beginTime }} - {{ item.endTime }}
              <el-tag v-if="item.facilitator" effect="plain" size="small">{{ item.facilitator }}</el-tag>
            </div>
            <div class="courseList_item_info-teacherName">
              {{ item.teacherName }}
            </div>
            <div class="courseList_item_info-Introduction">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog';
import { mapGetters } from 'vuex';
import { getCourseList } from '@/api';
import { fomartDate } from 'utils/utils';

export default {
  name: 'classScheduleModel',
  components: { MyDialog },
  data() {
    return {
      visible: false,
      radio1: '',
      weekDateList: [],
      courseData: {},
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  mounted() {
    this.radio1 = this.fomartDate(Date.now(), 'YYYY-MM-DD');
  },
  methods: {
    fomartDate,
    getCourseInfo(id) {
      getCourseList({ roomId: id })
          .then((res) => {
            if (res.code === 200) {
              this.weekDateList = Object.keys(res.data)
                  .sort();
              this.courseData = res.data;
            }
          });
    },
    show() {
      this.visible = true;
      this.getCourseInfo(this.currentRoomId);
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  background: #1A1A1C;
  position: relative;
  height: 750px;
}

.courseContainer {
  height: 710px;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;

  .btnContainer {
    &_week {
      margin-bottom: 4px;
      line-height: 16px;
      font-size: 14px;
      font-weight: 400;
    }

    &_day {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  /deep/ .is-active {
    .el-radio-button__inner {
      background-color: #712327;
      //border-color: #712327;
      color: #D51820;
      border-radius: 6px;
    }
  }

  /deep/ .el-radio-button__inner {
    background-color: #0C0C0D;
    border-color: #0C0C0D;
    padding: 7px 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #BABABA;
    border: none !important;
  }
}

.courseList {
  flex: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(4, 230px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding-top: 32px;

  &_item {
    width: 230px;
    border-radius: 12px;
    //overflow: hidden;
    font-size: 0;

    &-cover {
      height: 130px;
      width: 100%;
      opacity: 1;
      border-radius: 6px 6px 0px 0px;
    }

    &_info {
      border-radius: 0 0 12px 12px;
      padding: 16px 9px 40px;
      //background: rgba(113,35,39,0.39);
      background: #0C0C0D;

      &-time {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        min-height: 24px;
        color: #FFFFFF;
        margin-bottom: 11px;

        /deep/ .el-tag {
          background-color: transparent;
          margin-left: 4px;
          font-size: 12px;
          font-weight: 300;
          color: #FFFFFF;
          border: 1px solid rgba(213, 24, 32, 0.502);
        }
      }

      &-teacherName {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #D51820;
        margin-bottom: 4px;
      }

      &-Introduction {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        min-height: 48px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        //min-height: 16px;
        color: #BABABA;
      }
    }
  }
}
</style>
