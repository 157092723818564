<template>
  <MyDialog :diaVisible="dialog.isShow" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="container" v-if="dialog.isShow">
        <iframe src="https://secure.cwgvu.com/#/signup/23544/F3/B0"></iframe>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';

export default {
  name: 'openAnAccountModel',
  components: { MyDialog },
  data() {
    return {
      dialog: {
        isShow: false,
      },
    };
  },
  methods: {
    // 关闭dialog
    close() {
      this.dialog.isShow = false;
    },
    // 确定dialog
    show() {
      this.dialog.isShow = true;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  background: #712327;

  .el-dialog__body {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.container {
  height: 600px;
  /*width: 800px;*/
}
</style>
