<template>
  <div class="wrapper" ref="scroll">
    <!--    <div class="list">-->
    <ChatItem :datas="item" v-for="(item,index) in chatList" :key="index"/>
    <!--    </div>-->
  </div>
</template>

<script>
import ChatItem from '@/payerH5/components/appChat/chatItem';
import {mapGetters, mapMutations} from 'vuex';
import { throttle } from 'lodash-es';
import {DEL_CHAT_HISTORY} from "@/components/chat/constants/chat";

export default {
  name: 'chatList',
  components: { ChatItem },
  props: {
    isScroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bs: null,
    };
  },
  computed: {
    ...mapGetters(['chatList']),
  },
  mounted() {
    // this.init();
    this.$eventBus.$on('deleteMessage', (e) => {
      const index = this.chatList.findIndex(v=>v.messageId == e.messageId)
      //监听消息删除事件
      this[DEL_CHAT_HISTORY](index)
    });
  },
  methods: {
    ...mapMutations([DEL_CHAT_HISTORY]),
    scrollToEnd: throttle(function () {
      if (this.isScroll) {
        this.$refs.scroll.scrollTo(0, this.$refs.scroll.scrollHeight);
      }
    }, 100, { trailing: true }),
  },
  init() {
    // this.$refs.scroll.onscroll = (e) => {
    //   console.log(e.target.scrollTop);
    //   console.log(e.target.clientHeight);
    //   console.log(e.target.__proto__);
    //   const { clientHeight } = e.target;
    //   const { scrollTop } = e.target;
    //   const { scrollHeight } = e.target;
    //   console.log(clientHeight + scrollTop, scrollHeight, 'clientHeight + scrollTop, scrollHeight');
    //   if (clientHeight + scrollTop === scrollHeight) {
    //     console.log('竖向滚动条已经滚动到底部');
    //   }
    // };
  },
};
</script>

<style scoped lang="less">
.wrapper {
  //height: 500px;
  flex: 1;
  overflow: auto;
  padding: 0 20px;
  background: rgba(27,27,29);
  .list {
    padding: 0 20px;
  }
}
</style>
