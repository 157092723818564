<template>
  <div style="height: 100%">
    <vue-aliplayer-v2
        v-if="roomInfo.living && source"
        :style="`height: ${height}px`"
        ref="VueAliplayerV2"
        :source="source"
        v-bind="$attrs"
        v-on="$listeners"
    />
    <div class="noLivIng" :style="`--noLivingBg:url(${roomInfo.cover})`" v-else>
      <img :src="showCover"/>
    </div>
  </div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2';
import { mapGetters } from 'vuex';

export default {
  name: 'videoCom',
  props: ['height', 'cover', 'source'],
  components: { VueAliplayerV2: VueAliplayerV2.Player },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['roomInfo']),
    showCover(){
      if(this.roomInfo.freeTimeEnd){
        return this.roomInfo.cover
      }
      if(this.roomInfo.roomPermission && this.roomInfo.limitPlayer){
        console.log(123)
        return this.roomInfo.roomPermission.tipsBg
      }else{
        return this.roomInfo.cover
      }
      // return this.roomInfo.roomPermission ? this.roomInfo.roomPermission.tipsBg : this.roomInfo.cover
      // return (this.roomInfo.roomPermission && this.roomInfo.living) ? this.roomInfo.roomPermission.tipsBg : this.roomInfo.cover

    },
  },
  mounted() {
    this.$refs.VueAliplayerV2?.setCover(this.showCover);
  },
  methods: {
    pause() {
      console.log(this.$refs.VueAliplayerV2.getStatus());
      // this.$refs.VueAliplayerV2.pause();
    },
  },
};
</script>

<style scoped lang="less">
.noLivIng {
  font-size: 0;
  height: 100%;
  width: 100%;
  background: #1A1A1C;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>
