import request from 'utils/request';

/**
 * 用户登录
 * @param {Object} params {username:string,password:string}
 * @returns {AxiosPromise}
 */
export function userLoign(params) {
  return request('/api/player/login', { method: 'post', params });
}

/**
 * 用户注册
 * @param {Object} params {email:string,password:string,phone:string,emailCode:string}
 * @returns {AxiosPromise}
 */
export function userRegister(params) {
  return request('/api/player/register', { method: 'post', params });
}

/**
 * 获取轮播
 * @param {Object} params {extraFlag:string,roomId:string}
 */
export function getBanners(params) {
  return request('/api/player/getSwiperList', { method: 'get', params });
}

/**
 * 获取游客token
 * @param Object params {roomId:string}
 */
export function getTempUserToken(params) {
  return request('/api/player/getTempUserToken', { method: 'get', params });
}

/**
 * 获取直播间列表
 * @param {Object} params {pageSize:number,pageNum:number,orderByColumn:string,isAsc:string}
 * @returns
 */
export function getLiveRoomList(params) {
  return request('/api/player/getRoomList', { method: 'get', params });
}

/**
 * 获取直播间详情
 * @param {Object} params {id:string}
 * @returns
 */
export function getLiveRoomInfo(params) {
  return request('/api/player/getRoomInfo', { method: 'get', params });
}

/**
 * 获取课程列表
 * @param {Object} params {roomId:number}
 * @returns
 */
export function getCourseList(params) {
  return request('/api/player/getCourseList', { method: 'get', params });
}

/**
 * 获取交易内参列表
 * @param {Object} params {roomId:number,pageSize:number,pageNum:number,orderByColumn:string,isAsc:string}
 * @returns
 */
export function getTradingInsider(params) {
  return request('/api/player/getTradingInsider', { method: 'get', params });
}

/**
 * 获取活动列表
 * @param {Object} params {roomId:number,pageSize:number,pageNum:number,orderByColumn:string,isAsc:string}
 * @returns
 */
export function getActivityList(params) {
  return request('/api/player/getActivityList', { method: 'get', params });
}
/**
 * 获取活动详情
 * @param {Object} params {roomId:number,pageSize:number,pageNum:number,orderByColumn:string,isAsc:string}
 * @returns
 */
export function getActivityInfo(params) {
  return request('/api/player/getActivityInfo', { method: 'get', params });
}

/**
 * 查询交易信号列表
 * @param {Object} params {date:YYYY-MM-dd,teacherId:number }
 * @returns
 */
export function getSignalList(params) {
  return request('/api/player/signalList', { method: 'get', params });
}

/**
 * 获取最新的交易信号列表
 * @param params {Object} params {roomId:number}
 * @returns {AxiosPromise}
 */
export function getLastSignal(params) {
  return request('/api/player/lastSignal', { method: 'get', params });
}

/**
 * 校验tooken合法
 * @returns {AxiosPromise}
 */
export function checkToken() {
  return request('/api/player/checkToken', { method: 'get' });
}

/**
 * 获取用户信息
 * @returns {AxiosPromise}
 */
export function getUserInfo(params) {
  return request('/api/getInfo', { method: 'get', params });
}

/**
 * 文件上传
 * @param file 文件
 * @returns {AxiosPromise}
 */
export function upLoadImg(formData) {
  console.log(formData);
  return request('/api/system/oss/upload', { method: 'post', data: formData });
}

/**
 * 获取邮箱验证码
 * @param {Object} params {email:string}
 * @returns {AxiosPromise}
 */
export function getCaptchaEmail(params) {
  return request('/api/captchaEmail', { method: 'get', params });
}

/**
 * 获取当前用户邮箱验证码
 * @returns {AxiosPromise}
 */
export function getEmailCode() {
  return request('/api/player/getEmailCode', { method: 'get' });
}

/**
 * 修改用户信息
 * @param {Object} params {avatar:string,nickname:string,password:string,emailCode:string}
 * @returns {AxiosPromise}
 */
export function updateUnerInfo(params) {
  return request('/api/player/updateProfile', { method: 'post', params });
}

/**
 * 获取直播倒计时
 * @param params
 * @returns {AxiosPromise}
 */
export function getRemainingViewingTime(params) {
  return request('/api/player/getRemainingViewingTime', { method: 'get', params });
}


/**
 * 查询滚动公告列表
 * @param params {Object} params {roomId:number}
 * @returns {AxiosPromise}
 */
export function getNoticeList(params) {
  return request('/api/player/getNoticeList', { method: 'get', params });
}

/**
 * 获取正在直播的课程表
 * @returns {AxiosPromise}
 */
export function getCurrentLiveCourseList() {
  return request('/api/player/getCurrentLiveCourseList', { method: 'get' });
}

/**
 * 获取交易内参详细信息
 * @param params {id:number}
 * @returns {AxiosPromise}
 */
export function getTradingInsiderInfo(params) {
  return request('/api/player/getTradingInsiderInfo', { method: 'get', params });
}

export function getUserIp() {
  return request('/api/player/getUserIp', { method: 'post' });
}

export function getOnlineCount(roomId) {
  const params = {
    roomId,
  };
  return request('/api/player/getOnlineCount', { method: 'get', params });
}

/**
 * 获取私聊消息接口
 * @param Object  params {fromUserId:"",toUserId:"",pageSize:"",pageNum:""}
 * @returns {AxiosPromise}
 */
export function getPrivateChatMessages(params) {
  return request('/api/player/privateHistory', { method: 'get', params });
}

export function checkIpStatus() {
  return request('/api/player/checkIp', { method: 'get' });
}

export function imHistoryMessage(params) {
  return request('/api/player/imMessageHistory', { method: 'get',params });
}
export function checkLuckyActivity(params) {
  return request('/api/player/checkLuckyActivity', { method: 'get',params });
}
export function getLuckyActivityInfo(params) {
  return request('/api/player/getLuckyActivityInfo', { method: 'get',params });
}
export function doActionLucky(params) {
  return request('/api/player/doActionLucky', { method: 'post',params });
}
