<template>
  <MyDialog :diaVisible="dialogIsShow" width="548px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="listItemBox">
        <LoadPageList :params="{}" v-if="dialogIsShow" :reqHandle="getLiveRoomList">
          <template slot-scope="{itemData,$index}">
            <div class="listItem" @click="handleClick(itemData.id)" :key="$index">
              <img class="imgItem" :src="itemData.cover" alt="">
              <div class="listItem-r">
                <p class="roomTitle">
                  {{ itemData.name }}
                  <!--                  <span class="EnglishBox">{{ itemData.language }}</span>-->
                </p>
                <p class="teacherName">
                  {{ itemData.teacherName }}
                </p>
                <!--                <div class="describe">{{ itemData.remark }}</div>-->
                <!--                <div class="text">{{ $t('liveList.lecturer') }}：-->
                <!--                  <span>{{ itemData.teacherName }}</span>-->
                <!--                </div>-->
                <!--                <div class="text">{{ $t('liveList.introduct') }}：-->
                <!--                  <span>{{ itemData.teacherRemark }}</span>-->
                <!--                </div>-->
              </div>
            </div>
          </template>
        </LoadPageList>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import LoadPageList from '@/components/loadPageList/index.vue';
import { getLiveRoomList } from '@/api';

export default {
  name: 'LiveListModel',
  components: { MyDialog, LoadPageList },
  data() {
    return {
      dialogIsShow: false,
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  mounted() {

  },
  methods: {
    getLiveRoomList,
    ...mapActions(['changeLiveRoom']), // 获取直播间列表
    handleClick(id) {
      if (this.currentRoomId !== id) {
        this.changeLiveRoom(id);
      }
      this.close();
    },
    show() {
      this.dialogIsShow = true;
    },
    close() {
      this.dialogIsShow = false;
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
  position: relative;
}

.listItemBox {
  height: 450px;
  overflow-y: auto;
}

.listItem {
  cursor: pointer;
  background: #0c0c0d;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 15px;

  .imgItem {
    width: 300px;
    height: 146px;
    border-radius: 6px;
    margin-right: 12px;
  }
}

.listItem-r {
  height: 100px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  .roomTitle {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 21px;
  }
  .teacherName {
    font-size: 14px;
    font-weight: 400;
    color: #BABABA;
  }

  .describe {
    font-size: 12px;
    line-height: 14px;
    color: #bababa;
  }

  .text {
    color: #fff;
    font-size: 12px;

    span {
      color: #bababa;
    }
  }
}
</style>
