<template>
  <!--  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'90vh' ,zIndex:9999}"-->
  <!--         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"-->
  <!--  >-->

  <!--  <van-overlay :show="popupShow">-->
  <!--  -->
  <div class="chatModel" v-if="popupShow">
    <div class="closeIcon" @click="overlayHide">
      <van-icon name="close" color="#333" size="30"/>
    </div>
    <m-chat
        v-if="privateFromUserId"
        ref="mChat"
        :messages="privateChatList"
        @submit="submit"
        height="80vh"
        :openBases="['text','emoji']"
        :openExtends="['image']"
        :config="{imagePreviewConfig:{closeable:true}}"
    >
    </m-chat>
  </div>
  <!--  </van-overlay>-->

  <!--  </Popup>-->
</template>

<script>
import MyDialog from '@/components/dialog/myDialog';
import MChat from '@maybecode/m-chat';
import { fomartDate } from 'utils/utils';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Popup from '@/components/popup/popup';
import socket from 'utils/socket';
import { ADD_PRIVATE_CHAT_MESSAGE } from '@/components/chat/constants/chat';
import { upLoadImg } from '@/api';

export default {
  name: 'ChatModel',
  components: {
    Popup,
    MChat,
  },
  data() {
    return {
      firstLoad: true,
      popupShow: false,
      weekDateList: [],
      courseData: {},
    };
  },
  computed: {
    ...mapState(['privateFromUserId']),
    ...mapGetters(['privateChatList']),
  },
  watch: {
    privateFromUserId: {
      handler(v) {
        if (v) {
          this.getPrivateChatMsgList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['getPrivateChatMsgList']),
    ...mapMutations([ADD_PRIVATE_CHAT_MESSAGE]),
    fomartDate,
    overlayShow(data) {
      this.popupShow = true;
      this[ADD_PRIVATE_CHAT_MESSAGE](data);
    },
    overlayHide() {
      this.popupShow = false;
    },
    submit(e) {
      console.log(e);
      if(e.type==='image'){
        const dataForm = new FormData()
        dataForm.append('file',e.content.file)
        upLoadImg(dataForm).then(res=>{
          socket.Send({
            messageBodys: [
              {
                messageType: e.type.toLocaleUpperCase(),
                messageBody: res.data.url,
              },
            ],
            privateMessage: true,
            userId: this.privateFromUserId,
            zanAndFlowers: false,
          });
        })
      }else {
        socket.Send({
          messageBodys: [
            {
              messageType: e.type.toLocaleUpperCase(),
              messageBody: e.content,
            },
          ],
          privateMessage: true,
          userId: this.privateFromUserId,
          zanAndFlowers: false,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.chatModel {
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  //top: 0;
  bottom: 0;
  background: #fff;

  .closeIcon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;
  }
}

/deep/ .m-chat-wrap {
  //flex: 1;

  .m-chat-msg-wrap {
    //flex: 1;
  }

  .m-chat-input {
    color: #000
  }

  .chat-message-content {
    color: #000;
  }
}
</style>
