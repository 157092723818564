<template>
  <MyDialog :diaVisible="dialogIsShow" width="730px" @close="close" :noFooter="true">
    <m-chat
        ref="mChat"
        v-if="privateFromUserId"
        :messages="privateChatList"
        @submit="submit"
        height="70vh"
        :openBases="['text','emoji']"
        :openExtends="['image']"
        :config="{imagePreviewConfig:{closeable:true}}"
    >
    </m-chat>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog';
import MChat from '@maybecode/m-chat';
import { fomartDate } from 'utils/utils';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ADD_PRIVATE_CHAT_MESSAGE } from '@/components/chat/constants/chat';
import socket from 'utils/socket';
import { upLoadImg } from '@/api';

export default {
  name: 'ChatModel',
  components: { MyDialog, MChat },
  data() {
    return {
      dialogIsShow: false,
    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'roomInfo', 'userInfo', 'privateChatList']),
    ...mapState(['privateFromUserId']),
  },
  watch: {
    privateFromUserId: {
      handler(v) {
        if (v) {
          this.getPrivateChatMsgList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['getPrivateChatMsgList']),
    ...mapMutations([ADD_PRIVATE_CHAT_MESSAGE]),
    fomartDate,
    showDetail(data) {
      this.$emit('showDetail', data);
    },
    close() {
      this.dialogIsShow = false;
    },
    show(data) {
      console.log('=====>---');
      this.dialogIsShow = true;
      this[ADD_PRIVATE_CHAT_MESSAGE](data);
    },
    submit(e) {
      console.log(e);
      if (e.type === 'image') {
        const dataForm = new FormData();
        dataForm.append('file', e.content.file);
        upLoadImg(dataForm)
            .then(res => {
              socket.Send({
                messageBodys: [
                  {
                    messageType: e.type.toLocaleUpperCase(),
                    messageBody: res.data.url,
                  },
                ],
                privateMessage: true,
                userId: this.privateFromUserId,
                zanAndFlowers: false,
              });
            });
      } else {
        socket.Send({
          messageBodys: [
            {
              messageType: e.type.toLocaleUpperCase(),
              messageBody: e.content,
            },
          ],
          privateMessage: true,
          userId: this.privateFromUserId,
          zanAndFlowers: false,
        });
      }

    },
  },
};
</script>

<style scoped lang="less">
/deep/ .m-chat-wrap {
  .m-chat-emoji {
    .m-chat-emoji-item {
      flex-basis: 5% !important;;
      margin: 10px !important;;
      font-size: 30px !important;;
    }
  }

  .m-chat-grid {
    .m-chat-grid-item {
      .comment-extend-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px !important;;
        width: 110px !important;;
        height: 90px !important;;
        border-radius: 10px !important;;
      }

      .m-chat-grid_item_text {
        font-size: 20px !important;
        margin: 12px 0 !important;
      }
    }
  }

  .m-chat-comment-extend {
    height: 200px;
    padding: 40px 30px;

    .comment-extend-icon {
      width: 30px;
      height: 30px;
      font-size: 30px;
    }

    .m-chat-grid_item_text {
      font-size: 15px;
      margin: 5px 0;
    }
  }

  .m-chat-comment-main {
    padding: 10px !important;
    height: 50px !important;

    .m-chat-main-left {
      padding: 0;
    }

    .m-chat-form {
      input {
        padding: 0;
        font-size: 18px;
        height: 50px;
        margin: 0 10px;
      }
    }

    .submit-btn {
      font-size: 16px;
      padding: 10px 20px;
      margin-left: 10px;
    }

    .m-chat-input-options {
      padding: 0;
    }

    .c-icon {
      width: 50px;
      height: 50px;
      font-size: 50px !important;
    }
  }
}

/deep/ .m-chat-msg-wrap {
  //background: #f00;
  .pulldown-wrapper {

  }

  .m-chat-comment-main {
    padding: 10px;
  }

  .m-chat-msg-time {
    font-size: 14px;
    margin: 10px 0;
  }

  .box-people {
    .chat-message-name {
      font-size: 14px !important;
    }

    .chat-message-content_wrap {
      .contentWrap {
        padding: 5px 20px !important;
        font-size: 16px !important;
        line-height: 20px !important;
        min-height: 40px !important;
        border-radius: 5px !important;
      }
    }
  }

  .box-self {
    padding: 10px 5px;
  }

  .chat-message-main {
    padding: 0 20px;
  }

  .chat-message {
    padding: 10px 10px;
    padding-right: 20px;

    .van-image {
      width: 50px !important;
      height: 50px !important;
    }

    .chat-message-name {
      font-size: 14px !important;
    }

    .chat-message-content {
      padding: 5px 20px !important;
      font-size: 16px !important;
      line-height: 20px !important;
      min-height: 40px !important;
      border-radius: 5px !important;

      &:before {
        display: none;
        width: 15px;
        height: 15px;
        top: 12px;
        right: 4px;
        line-height: 20px;
      }
    }
  }
}

</style>
