<template>
  <div class="noticeContainer">
    <div class="countDown"  v-if=" freeTime >= 0">
      {{ $t('header.Remaining duration') }}:<TimeTest  ref="timeTestRef" :remainTime="freeTime" @countDowmEnd="countDowmEnd"></TimeTest>
    </div>
    <div style="flex: 8">
      <el-carousel style="width: 100%" indicator-position="none" :interval="3000" height="40px" direction="vertical"
                   :autoplay="true">
        <el-carousel-item :key="index" v-for="(item,index) in noticeList">
          <div class="notic" @click="openTab(item)">{{ item.content }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="flex: 1;font-size: 14px;text-align: right">{{ $t('signa.on line count') }}:{{onLineCount}}</div>
  </div>
</template>

<script>
import TimeTest from '@/components/time/index.vue';
import { mapGetters, mapState } from 'vuex';
import {getNoticeList, getOnlineCount} from '@/api';
import socket from "utils/socket";

export default {
  name: 'notice',
  components: { TimeTest },
  data() {
    return {
      noticeList: [],
      onLineCount: '-' ,
      timer:null
    };
  },
  mounted() {
    this.startTimer();
  },
  computed: {
    ...mapState(['freeTime', 'tourist']),
    ...mapGetters(['currentRoomId']),
  },
  watch: {
    freeTime: {
      handler(v) {
        // if(v === 0){
        //   this.countDowmEnd();
        //   socket.onClose();
        // }
      },
      deep: true,
      immediate: true,
    },
    currentRoomId: {
      handler(id) {
        if (id) {
          getNoticeList({ roomId: id })
              .then((res) => {
                this.noticeList = res.data;
              });
          this.handleGetOnLineCount()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.handleGetOnLineCount();
      }, 5000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    handleGetOnLineCount(){
      if(!this.currentRoomId){
        return
      }
      getOnlineCount(this.currentRoomId).then(res=>{
        this.onLineCount = this.formatNumber(res.data)
      })
    },
    formatNumber(num){
      if(!num){
        return 0;
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M";
      } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + "K";
      } else {
        return num.toString();
      }
    },
    openTab(data) {
      // window.location
      if (data.link) {
        window.open(data.link, '_blank');
      }
    },
    countDowmEnd() {
      this.$eventBus.$emit('freeTimeEnd');
    },
    // freeTimeEnd() {
    //   this.$eventBus.$emit('freeTimeEnd');
    // },


  },
};
</script>

<style scoped lang="less">
.noticeContainer {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 32px;
  background: rgba(40, 40, 40);
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #FFFFFF;

  .countDown {
    width: 200px;
  }
}

.notic {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>

<i18n>
{
  "en": {
    "Open an account": "Remaining Time"
  },
  "zh": {
    "Open an account": "剩余时长"
  }
}
</i18n>
