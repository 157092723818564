// 登录
export const LOGIN = 'LOGIN';
// 注册
export const REGISTER = 'REGISTER';
// 课程表
export const CLASS_SCHEDULE = 'CLASS_SCHEDULE';
// 会员特权
export const MEMBERSHIP_PRIVILEGES = 'hytq';
// 交易内参
export const TRANSACTION_PARAMETERS = 'TRANSACTION_PARAMETERS';
export const TRANSACTION_PARAMETERS_DETAIL = 'TRANSACTION_PARAMETERS_DETAIL';
// 盈利统计
export const PROFIT_STATISTICS = 'yltj';
// 直播间
export const BROADCASTING_ROOM = 'BROADCASTING_ROOM';
export const LUCKY_ACTIVITY = 'LUCKY_ACTIVITY';
//活动
export const ACTIVITY = 'ACTIVITY';
export const CALENDAR = 'CALENDAR';
//关于
export const ABOUT = 'ABOUT';
// 用户
export const USER_INFO = 'USER_INFO';
// 领取信号
export const PICK_UP_SIGNAL = 'PICK_UP_SIGNAL';
// 注册VIP
export const VIP_REGISTER_TIP = 'VIP_REGISTER_TIP';
// 立即开户
export const OPEN_ACCOUNT = 'OPEN_ACCOUNT';
// Banner
export const BANNER_MODEL = 'BANNER_MODEL';
// app菜单栏
export const MENUPOPUP = 'MENUPOPUP';
// 联系客服
export const CUSTOMER = 'CUSTOMER';
// 私聊
export  const PRIVATE_CHAT = 'PRIVATE_CHAT'
//输入直播间访问密码
export  const ROOM_VISIT_PASSWORD = 'ROOM_VISIT_PASSWORD'
// 发送消息倒计时
export const SEND_MESSAGE_COUNTDOWN = 2;
