<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'70%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('header.Calendar')">
    <div class="detailContainer">
        <div id="economicCalendarWidget"></div>
    </div>
  </Popup>
</template>

<script>
// import Popup from '@/components/popup/popup';
import { mapGetters } from 'vuex';
import { getBanners } from '@/api';
import Popup from "@/components/popup/popup.vue";

export default {
  name: 'CalendarModel',
  components: {
    Popup
    // Popup,
  },
  data() {
    return {
      popupShow: false,

    };
  },
  computed: {

  },
  methods: {
    overlayShow(type) {

      this.popupShow = true;
      this.$nextTick(()=>{
        const script = document.createElement('script')
        script.async = true
        script.type = 'text/javascript'
        script.dataset.type = 'calendar-widget'
        script.src = 'https://www.tradays.com/c/js/widgets/calendar/widget.js?v=13'
        script.innerHTML = JSON.stringify({
          width: '100%',
          height: '500',
          mode: '2',
          lang: this.$i18n.locale,
          theme: 1
        })
        document.getElementById('economicCalendarWidget').appendChild(script)
      })
    },
    overlayHide() {

      this.popupShow = false;
      const economicCalendarWidget = document.getElementById('economicCalendarWidget')
      if (economicCalendarWidget) {
        economicCalendarWidget.innerHTML = ''
      }
    },
    openTab(data) {

    },
  },
};
</script>

<style lang="less" scoped>
.detailContainer {
  padding: 20px;
}
.content{
  text-indent: 2em;
  font-size: 14px;
  line-height: 20px;
}
img {
  width: 100%;
  height: 200px;
}

.title {
  bottom: 0px;
  left: 0;
  right: 0;
  position: absolute;
  height: 40px;
  text-indent: 20px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1111;
}
</style>
