<template>
  <div class="H5_h5TabsBox">
    <van-tabs v-model="active">
      <van-tab :title="$t('chatList.Discussion area')"></van-tab>
      <van-tab :title="$t('chatList.On the air')"></van-tab>
      <van-tab :title="$t('chatList.Lecturer introduce')"></van-tab>
    </van-tabs>
    <div id="contentBox" class="contentBox">
<!--      :style="`height: ${chatHeight?chatHeight+'px':'100%'}`"-->
      <div class="contentBox_item" v-show="active==0">
        <app-chat-container/>
      </div>
      <div :style="`height: ${chatHeight?chatHeight+'px':'100%'}`" class="H5_h5TabsBox-r" v-show="active==1">
        <Liveing/>
      </div>
      <div :style="`height: ${chatHeight?chatHeight+'px':'100%'}`" class="H5_h5TabsBox-r" v-show="active==2">
        <div class="H5_title">{{ roomInfo.teacherName }}</div>
        <div class="H5_content">{{ roomInfo.teacherRemark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppChatContainer from '@/payerH5/components/appChat/chatContainer';
import Liveing from '@/payerH5/components/liveing/index.vue';

export default {
  name: 'h5Tabs',
  components: { AppChatContainer, Liveing },
  data() {
    return {
      active: '1',
      chatHeight: 0,
    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'roomInfo', 'userInfo', 'tokenData']),
  },
  mounted() {
    const contentBox = document.querySelector('#contentBox');
    const obs = new ResizeObserver(() => {
      this.chatHeight = contentBox.clientHeight;
    });
    obs.observe(contentBox);
  },
};
</script>

<style lang="less" scoped>
.H5_h5TabsBox {
  //background-color: #1A1A1C;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .contentBox {
    display: flex;
    flex: 1;
    height: 0;
    &_item {
      display: flex;
      flex: 1;
    }

  }

  /deep/ .van-tabs__nav {
    background-color: #212222;

    .van-tab {
      color: #fff;
    }

    .van-tabs__line {
      background-color: #fff;
    }
  }

  .H5_h5TabsBox-r {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #282828;
    padding: 20px 15px;
    overflow-y: auto;

    .H5_title {
      font-size: 18px;
      padding-bottom: 20px;
      color: #D51820;
    }

    .H5_content {
      font-size: 16px;
      line-height: 28px;
      color: #fff;
    }
  }
}
</style>
