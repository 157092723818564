<template>
  <span>
    <slot :disabled="disadle" :massage="msg"/>
  </span>
</template>

<script>
import i18n from '@/locales/i18n';

export default {
  name: 'countDowm',
  props: {
    massage: {
      type: String,
      default: i18n.t('countDowm.massage'),
    },
    downMsg: {
      type: String,
      default: 'countDowm.repGet',
    },
    countNum: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      msg: '',
      disadle: false,
      count: this.countNum,
    };
  },
  watch: {
    massage: {
      handler(v) {
        this.msg = this.$t(v);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getCode() {
      this.msg = `${this.$t(this.downMsg)}(${this.count})`;
      if (this.count <= 0) {
        this.count = this.countNum;
        this.msg = this.massage;
        this.disadle = false;
        this.$emit('timeEnd');
      } else {
        this.disadle = true;
        setTimeout(() => {
          this.getCode();
        }, 1000);
      }
      this.count -= 1;
    },
  },
};
</script>

<style scoped>

</style>
