<template>
  <div class="fixRightContainer">
    <div >
<!--      <img :src="roomInfo.qrCode" class="qrCode">-->
      <div class="fixRightContainer_item" slot="reference">
        <img :src="keFuIcon" @click="openKefu" v-bind:style="{height: '70px', width: '70px'}"/>
<!--        {{ $t('fixRight.Customer') }}-->
<!--        <img :src="WechatIcon"/>-->
<!--        {{ $t('fixRight.Wechat') }}-->
      </div>
    </div>
<!--    <div class="cutOffRule"></div>-->
    <div class="fixRightContainer_item "  v-show="activityShow">
      <img :src="liping" @click="openLicky" v-bind:style="{height: height, width: width}"/>
<!--      {{ $t('fixRight.Customer') }}-->
    </div>
  </div>
</template>

<script>
import WechatIcon from '@/assets/img/wachat-icon.png';
import keFuIcon from '@/assets/img/negative.png';
import liping from '@/assets/img/liping.png';
import qrCode from '@/assets/img/1685978092440.jpg';
import { mapGetters } from 'vuex';
import {checkLuckyActivity} from "@/api";

export default {
  name: 'FixRight',
  props:{
    width:{
      type: String,
      default: '70px'
    },
    height:{
      type: String,
      default: '70px'
    }
  },
  data() {
    return {
      activityShow:false,
      WechatIcon,
      keFuIcon,
      liping,
      qrCode,
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  watch:{
    currentRoomId:function (val1,val2){
      if(val1){
        this.checkHasActivity(val1)
      }
    }
  },
  created() {

  },
  methods:{
    openKefu(){
      window.open('https://t.me/XC600broker1', '_blank');
    },
    openLicky(){
      this.$emit('openLicky')
    },
    checkHasActivity(roomId){
      checkLuckyActivity({
        roomId:roomId
      }).then(res=>{
        this.activityShow = res.data
      })
    }
  }
};
</script>

<style lang="less">
.WechatQrCode {
  .qrCode {
    margin: 12px;
    width: 152px;
    height: 152px;
  }
}
</style>
<style scoped lang="less">
.fixRightContainer {
  position: fixed;
  //display: inline-block;
  //height: 161px;
  height: 80px;
  right: 0;
  top: 80px;
  bottom: 0;
  //background: #fff;
  margin: auto 0;


  .cutOffRule {
    border: 1px solid #E0E0E0;
  }

  .fixRightContainer_item {
    min-width: 80px;
    height: 80px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    color: #BABABA;

    img {
      //width: 70px;
      //height: 70px;
      //margin-bottom: 4px;
    }
  }
}
</style>
