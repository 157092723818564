<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'70%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="activityTitle">
    <div class="detailContainer">
      <LuckyWheel
          style="margin: 0 auto"
          ref="myLucky"
          width="300px"
          height="300px"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          @start="startCallback"
          @end="endCallback"
      />

    </div>
    <div class="desc" v-html="descript"></div>
  </Popup>
</template>

<script>
import Popup from "@/components/popup/popup.vue";
import {doActionLucky, getLuckyActivityInfo} from "@/api";
import {mapGetters} from "vuex";

export default {
  name: 'LuckyModel',
  components: {
    Popup
    // Popup,
  },
  data() {
    return {
      activityTitle:'',
      popupShow: false,
      blocks: [],
      prizes: [],
      buttons: [],
      descript: '',
      activityId:'',
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  watch:{

  },
  methods: {

    // 点击抽奖按钮会触发star回调
    startCallback() {
      doActionLucky({
        activityId:this.activityId
      }).then(res=>{
        let data = res
        if(data.code === 401){
          this.popupShow = false;
          layer.msg('请先注册登录后才可以参与哦');
          this.$eventBus.$emit('showLoginCard')
          return
        }
        if(data.code === 200){
          this.$refs.myLucky.play()
          const index = this.prizes.findIndex(item=>item.id === data.data)
          // 调用stop停止旋转并传递中奖索引
          this.$refs.myLucky.stop(index)
        }
      })
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(prize)
      layer.alert( prize.message,{
        btn: ['联系客服'],
        btn1: function(){
          window.open('https://t.me/XC600broker1', '_blank');
        }
      });
    },

    overlayShow(type) {
      this.popupShow = true;

      console.log('roomId===>',this.currentRoomId)

      getLuckyActivityInfo({
        roomId:this.currentRoomId
      }).then(res => {
        let activity = res.data
        this.activityId = activity.id
        this.activityTitle = activity.title

        this.descript = activity.descript
        let gifts = activity.giftList

        let blocksConfig = JSON.parse(activity.blocksConfig)
        this.blocks = blocksConfig.h5

        let buttonsConfig = JSON.parse(activity.buttonsConfig)
        this.buttons = buttonsConfig.h5

        let prizesConfig = JSON.parse(activity.prizesConfig).h5

        let prizes = []
        gifts.forEach(item => {
          let fonts = {}
          let imgs = {}
          fonts['text'] = item.name
          fonts['fontColor'] = item.fontColor
          fonts['fontSize'] = prizesConfig.fontSize
          fonts['top'] = prizesConfig.textTop
          fonts['fontWeight'] = prizesConfig.fontWeight
          fonts['fontWeight'] = prizesConfig.fontWeight
          fonts['wordWrap'] = prizesConfig.wordWrap

          imgs['src'] = item.image
          imgs['width'] = prizesConfig.width
          imgs['top'] = prizesConfig.imgTop
          prizes.push({
            id:item.id,
            range: item.winRange,
            message:item.message,
            background: item.background,
            fonts: [fonts],
            imgs: [imgs]
          })
        })
        this.prizes = prizes
      })
    },
    overlayHide() {

    },
    openTab(data) {

    },
  },
};
</script>

<style lang="less" scoped>
.detailContainer {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

.desc {
  padding: 20px;
  overflow: auto;
  word-wrap: break-word;
}

.content {
  text-indent: 2em;
  font-size: 14px;
  line-height: 20px;
}

img {
  width: 100%;
  height: 200px;
}

.title {
  bottom: 0px;
  left: 0;
  right: 0;
  position: absolute;
  height: 40px;
  text-indent: 20px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1111;
}
</style>
