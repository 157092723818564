<template>
  <MyDialog :width="'1200px'" :diaVisible="dialog.isShow" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="bannerContainer">
        <el-carousel trigger="click">
          <el-carousel-item v-for="(item,index) in banners" :key="index">
            <div class="bannerItemBox">
              <img :src="item.imgUrl" @click="openTab(item)"/>
              <div v-if="item.title" class="title">{{ item.title }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { mapGetters } from 'vuex';
import { getBanners } from '@/api';

export default {
  name: 'openAnAccountModel',
  components: { MyDialog },
  data() {
    return {
      dialog: {
        isShow: false,
      },
      banners: [],
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  methods: {
    // 关闭dialog
    close() {
      this.dialog.isShow = false;
      this.banners = [];
    },
    // 确定dialog
    show(type) {
      this.dialog.isShow = true;
      if (this.currentRoomId) {
        getBanners({ roomId: this.currentRoomId, extraFlag: type })
            .then((res) => {
              this.banners = res.data;
            });
      }
    },
    openTab(data) {
      console.log(data);
      // window.location
      if (data.link) {
        window.open(data.link, '_blank');
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  background: #712327;

  .el-dialog__body {
  }
}

.container {
  height: 800px;
  //width: 800px;
}

.bannerContainer {
  //width: 1200px;
  height: 800px;
  max-height: 800px;

  img {
    height: 100%;
    object-fit: contain;
  }
}

/deep/ .el-carousel {
  //height: 190px;
  height: 100%;

  .el-carousel__container {
    height: 100%;

    .el-carousel__item {

      .bannerItemBox {
        position: relative;
        width: 100%;
        height: 100%;
        .title {
          bottom: 0px;
          left: 0;
          right: 0;
          position: absolute;
          height: 40px;
          text-indent: 20px;
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #fff;
          background: rgba(0, 0, 0, 0.6);
          z-index: 1111;
        }
        img{
          width: 100%;
          height: 100%;
        }
      }

      //  //display: flex;
      //  //flex-direction: column;
      //  //justify-content: center;
      //  cursor: pointer;
      //

    }
  }

  .el-carousel__indicator {
    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }

    //padding: 0px;

  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  //background-color: #d3dce6;
}
</style>
