<template>
  <div class="signalContainer" v-on="$listeners" v-bind="$attrs">
    <div class="title">
      <img :src="tapIcon" class="tapIcon"/>
      <span>{{ $t('signa.Trading signa') }}</span>
    </div>
    <div class="info">
        {{lastSignalData.varieties}} {{$t('signa.Last Signa content')}}
    </div>
  </div>
</template>

<script>
import tapIcon from '@/assets/img/tap-icon.png';
import { mapGetters } from 'vuex';
// import { getLastSignal } from '@/api';

export default {
  name: 'signal',
  data() {
    return {
      tapIcon,
    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'lastSignalData']),
  },
  watch: {
    currentRoomId: {
      handler(roomId) {
        if (roomId) {
          // getLastSignal({ roomId })
          //     .then((res) => {
          //       console.log(res);
          //       this.dataForm = res.data || { content: '' };
          //     });
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
@keyframes pound {
  50% {
    transform: scale(1.2);
  }
}

.signalContainer {
  flex: 1;
  max-width: 256px;
  height: 190px;
  background: rgba(113, 35, 39);
  opacity: 1;
  cursor: pointer;
  padding: 30px 20px;
  margin-left: 12px;

  .title {
    display: flex;
    height: 28px;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;

    .tapIcon {
      width: 28px;
      height: 28px;
      margin-right: 8px;
      animation: pound .5s infinite alternate;
    }
  }

  .info {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    //letter-spacing: 1px;
    color: #BABABA;
  }

}
</style>
