<template>
  <el-dialog v-bind="$attrs" :width="width || '780px'"  :title="title" :visible.sync="diaVisible" v-if="diaVisible"
             :before-close="close" :top="top || '15vh'">
    <!-- 自定义插槽 noFooter为true的时候，不显示footer的插槽-->
    <slot></slot>
    <slot name="dia_Content"></slot>
    <div slot="footer" class="dialog-footer" v-if="noFooter ? false : true">
      <el-button size="mini" @click="cancel"> 取 消</el-button>
      <el-button type="primary" size="mini" @click="save"> 确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'MyDialog',
  props: ['diaVisible', 'title', 'width', 'noFooter', 'top'],
  data() {
    return {};
  },
  created() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    cancel() {
      this.$emit('cancel');
    },
    save() {
      this.$emit('save');
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  border-radius: 8px !important;

  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      top: -10px;
      right: -12px;

      .el-dialog__close {
        font-size: 22px;
        border: 1px solid;
        color: #fff;
        border-radius: 20px;
      }
    }
  }

  .el-dialog__body {
    padding: 20px;
  }
}
</style>
