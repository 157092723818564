<template>
  <div class="playerContainer" ref="playerContainerRef">
    <vueBaberrage :boxHeight="stageHeight/4" :style="`width:${boxWidth}px`" :barrageList="barrageList">
      <template v-slot:default="slotProps">
        <span class="welcome">
<!--          <img :src="'wellcomeBg'"/>-->
           {{ slotProps.item.msg }}
        </span>
      </template>
    </vueBaberrage>
    <div class="videoContainer" :style="`--boxWidth:${boxWidth}px;--boxHeight:${stageHeight}px`">
      <VideoCom
          v-if="roomInfo.living && source"
          :height="stageHeight-1"
          :source="source"
          ref="VueAliplayerV2"
          :options="options"
          :cover="showCover"
      />
      <div class="noLivIng" :style="`--noLivingBg:url(${roomInfo.cover})`" v-else>
        <img :src="showCover"/>
      </div>
    </div>

  </div>
</template>

<script>
import { vueBaberrage, MESSAGE_TYPE } from 'vue-baberrage';
// import wellcomeBg from '@/assets/img/wellcome-bg.png';
// import Video from 'video.js';
import { MESSAGETYPE } from '@/components/chat/constants/chat';
import { mapGetters, mapMutations, mapState } from 'vuex';
import * as mutationTypes from 'constants/mutation-types';
import VideoCom from '@/components/videoCom/videoCom';

export default {
  name: 'video-pc',
  components: { VideoCom, vueBaberrage },
  data() {
    return {
      // wellcomeBg,
      stageHeight: 0,
      boxWidth: 0,
      videoPlayer: null,
      // source: 'https://gcalic.v.myalicdn.com/gc/hsxkssqdzrqj_1/index.m3u8?contentid=2820180516001',
      sources: {
        type: 'application/x-mpegURL', // 媒体类型，m3u8请给application/x-mpegURL或者application/vnd.apple.mpegURL
        src: 'https://gcalic.v.myalicdn.com/gc/hsxkssqdzrqj_1/index.m3u8?contentid=2820180516001', // this.roomInfo.playUrl, // 视频地址
      },
      cssLink: {
        required: false,
        type: [String],
        default: `https://g.alicdn.com/de/prismplayer/2.16.0/skins/default/aliplayer-min.css`,
      },
      scriptSrc: {
        required: false,
        type: [String],
        default: `https://g.alicdn.com/de/prismplayer/2.16.0/aliplayer-min.js`,
      },
      barrageList: [],
    };
  },
  computed: {
    ...mapGetters(['roomInfo']),
    ...mapState(['tourist', 'freeTime']),
    showCover(){
      if(this.roomInfo.freeTimeEnd){
        return this.roomInfo.cover
      }
      if(this.roomInfo.roomPermission && this.roomInfo.limitPlayer){
        return this.roomInfo.roomPermission.tipsBg
      }else{
        return this.roomInfo.cover
      }
    },
    source: {
      get() {
        return this.roomInfo.playUrl;
      },
      set(value) {
        this.roomInfo.playUrl = value
      }
    },
    // sources() {
    //   return {
    // eslint-disable-next-line max-len
    //     type: 'application/x-mpegURL', // 媒体类型，m3u8请给application/x-mpegURL或者application/vnd.apple.mpegURL，其他的会默认为普通音视频，注：微信不支持m3u8
    //     // src: this.roomInfo.playUrl, // this.roomInfo.playUrl, // 视频地址
    //     // src: 'http://pull.ychpro.com/1662368982976761857/1662368982976761857.m3u8?auth_key=1687329787-0-0-d4911387970898fe3f22192cb5f309aa', // this.roomInfo.playUrl, // 视频地址
    //     src: 'https://gcalic.v.myalicdn.com/gc/hsxkssqdzrqj_1/index.m3u8?contentid=2820180516001', // this.roomInfo.playUrl, // 视频地址
    //     // src: "http://111.59.189.40:8445/tsfile/live/1020_1.m3u8"
    //   };
    // },
    options() {
      return {
        isLive: true,

        skinLayout: [
          { name: 'bigPlayButton', align: 'cc' },
          {
            name: 'controlBar', align: 'blabs', x: 0, y: 0,
            children: [
              { name: 'playButton', align: 'tl', x: 15, y: 12 },
              { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
            ],
          },
        ],
        // controlBarVisibility: 'always',
        // cover: this.roomInfo.cover,
      };
    },
  },
  watch: {
    'roomInfo.playUrl': {
      handler(v) {
        if (v) {
          console.log('this.videoPlayer.src',  v);
          // this.videoPlayer.src(this.sources);
          // this.videoPlayer.play();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    const box = this.$refs.playerContainerRef;
    this.stageHeight = box.clientHeight / 4;
    this.boxWidth = box.clientWidth;

    const sizeObserve = new ResizeObserver(() => {
      this.stageHeight = box.clientHeight;
      this.boxWidth = box.clientWidth;
      this.$forceUpdate();
    });
    sizeObserve.observe(box);
    this.$eventBus.$on(MESSAGETYPE.FULL_SCREAM, (data) => {
      this.barrageList.push({
        id: Date.now(),
        msg: data.messageBodys[0].messageBody,
        type: MESSAGE_TYPE.NORMAL,
        time: 5,
      });
    });
    // this.$refs.VueAliplayerV2.setPreviewTime(2);
    // this.videoPlayer = Video(this.$refs.videoRef, { controls: true });
    console.log(this.videoPlayer, 'this.videoPlayer');
  },
  methods: {
    ...mapMutations([mutationTypes.SET_ROOM_INFO]),
    pause() {
    },
    freeTimeEnd() {
      this.source = '';
      this[mutationTypes.SET_ROOM_INFO]({ ...this.roomInfo, playUrl: '' });
    },
  },

};
</script>

<style scoped lang="less">
.playerContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  .videoContainer {
    width: 100%;
    height: 100%;

    .noLivIng {
      font-size: 0;
      height: 100%;
      width: 100%;
      //background-image: var(--noLivingBg);
      //background-size: 100% 100%;
      //background-repeat: no-repeat;
      background: #1A1A1C;

      img {
        //width: 100%;
        //height: 80%;
        //width: var(--boxWidth);
        //height: var(--boxHeight);
        object-fit: fill;
      }
    }

    /deep/ video {
      //width: var(--boxWidth);
      //height: var(--boxHeight);
      object-fit: fill;
    }
  }
}

.welcome {
  display: inline-flex;
  align-items: center;
  height: 49px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: red;
  background-image: url("../../assets/img/wellcome-bg.png");
  background-size: 267px 48px;
  min-width: 267px;
  min-height: 48px;
  background-repeat: no-repeat;
  padding-left: 70px;

  img {
    height: 100%;
  }
}

</style>
<style>
.video-js {
  width: 100%;
  height: 100%;
}

.vjs-big-play-button {
  margin: auto;
  right: 10px;
  bottom: 10px;
  z-index: 9;
}

.baberrage-lane {
  position: absolute;
  z-index: 19;
}

.prism-controlbar {
  display: none;
}

.prism-controlbar, .prism-big-play-btn {
  /*display: none !important;*/
}
</style>
