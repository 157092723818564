<template>
    <div :class="['chatItemContainer',isAt?'AV_CHAT_ROOM':'']">
        <div class="top">
            <div class="info">
                <img :src="datas.avatar" class="avatar" alt="">
                <div class="level" v-if="datas.levelIcon">
                    <img :src="datas.levelIcon"/>
                </div>
                <div class="name" :style="`color:${datas.nicknameColor}` ">{{ datas.nickname }}</div>
                <div class="time">{{ fomartDate(datas.messageTimeStamp, 'MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="operation" v-if="isAdmin">
                <div class="operation_item" v-for="(item,index) in opts" :key="index">
                    {{ $t(item.type) }}
                </div>
            </div>
        </div>
        <div class="content" :style="`color:${datas.messageColor}` ">
            <template v-for="(item,index) in datas.messageBodys">
                <div :key="index" v-if="item.messageType===MESSAGETYPE.TEXT" v-viewer
                     v-html="item.messageBody && imgTagAddStyle(item.messageBody)"></div>
                <div :key="index" v-else-if="item.messageType===MESSAGETYPE.AT_TEXT"
                     v-viewer
                     v-html="item.messageBody && imgTagAddStyle(item.messageBody)"></div>
                <img
                        class="emoji_img"
                        :key="index"
                        v-else-if="item.messageType===MESSAGETYPE.EMOJI"
                        :src="item.messageBody"
                        width="20"
                        height="20"
                />
                <el-image
                        v-else
                        :key="index"
                        :src="item.messageBody"
                        :preview-src-list="[item.messageBody]"
                        :class="['chat_img',item.messageType]"
                />
            </template>

        </div>
    </div>
</template>

<script>
import logo from '@/assets/img/logo.png';
import assistantIcon from '@/assets/img/assistant.png';
import {MESSAGETYPE} from './constants/chat';
import {fomartDate} from 'utils/utils';
import {mapGetters} from 'vuex';


export default {
    name: 'chatItem',
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        },
        datas: {
            type: Object,
            defaulr: {},
        },
    },
    data() {
        return {
            MESSAGETYPE,
            logo,
            assistantIcon,
            levelIcon: [],
            opts: [
                {type: 'chatItem.Allow'},
                {type: 'chatItem.Refuse'},
                {type: 'chatItem.Delete'},
            ],
        };
    },
    computed: {
        ...mapGetters(['userInfo']),
        isAt() {
            return this.datas.atUserId === this.userInfo.userId;
        }
    },
    methods: {
        fomartDate,
        imgTagAddStyle(htmlstr) {
            let newContent = htmlstr.replace(/<img[^>]*>/gi, (match) => {
                match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                return match;
            });
            newContent = newContent.replace(/style="[^"]+"/gi, (match) => {
                match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
                return match;
            });
            newContent = newContent.replace(/<br[^>]*\/>/gi, '');
            // eslint-disable-next-line no-useless-escape
            newContent = newContent.replace(/\<img/gi, '<img class="im-item-preview" style="max-width:100%;height:auto;display:inline-block;margin:8px 0;"');

            return newContent;
        },
    },
};
</script>

<style scoped lang="less">
.chatItemContainer {
  padding: 16px 20px 10px 20px;
  //border-bo: 1px solid #393939;
  border-bottom: 1px solid #393939;

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .info {
      display: flex;
      align-items: center;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: contain;
      }

      .name {
        font-size: 16px;
        font-weight: 400;
        color: #BABABA;
        margin-right: 8px;
      }

      .level {
        margin-right: 6px;
        width: 66px;
        height: 22px;

        img {
          width: 66px;
          height: 22px;
        }
      }

      .time {
        margin-right: 12px;
        font-size: 14px;
        font-weight: 400;
        color: #767676;
      }

      .area {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #D51820;
        margin-right: 10px;
      }
    }

    .operation {
      display: inline-flex;

      &_item {
        padding: 3px 6px;
        background: #602427;
        border-radius: 2px;
        margin-right: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }


  .content {
    //display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    padding-left: 48px;
    //color: #9FFB55;
    color: #fff;
    opacity: 1;

    .emoji_img {
      width: 20px;
      height: 20px;
    }

    .chat_img {
      max-width: 80%;
    }

    img, span,div {
      display: inline-flex;
      align-items: flex-end;
    }

    .FLOWERS {
      width: 110px;
      height: 22px;
    }

    .ZAN {
      width: 144px;
      height: 18px;
    }
  }
}

.AV_CHAT_ROOM_key_ani {
  //animation: ;
}

@keyframes flicker {
  50% {
    box-shadow: inset 0 0 26px -8px rgb(255 0 0);
  }
}

.AV_CHAT_ROOM {
  animation: flicker .5s 10;
  animation-iteration-count: 10;
}
</style>
