<template>
  <div>
    <MyDialog
        :show-close="!tokenData.tourist"
        :close-on-click-modal="!tokenData.tourist"
        :close-on-press-escape="!tokenData.tourist"
        :diaVisible="dialog.isShow" width="550px" @close="close" :noFooter="true">
      <template #dia_Content>
        <img src="@/assets/img/vip.png" alt="" class="vipImg">
        <div class="content">
          <div v-html="$t('tourists.tips')"></div>
        </div>
        <div style="width: 100%;text-align: center;">
<!--          <el-button type="primary">{{ $t('tourists.contactService') }}</el-button>-->
          <el-button type="primary" @click="handleClick(reg)">
            {{ $t('login.Sign in') }}/{{ $t('login.Sign up') }}
          </el-button>
        </div>
      </template>
    </MyDialog>
  </div>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { mapGetters } from 'vuex';
import { REGISTER } from 'constants/sys';

export default {
  name: 'TipsModel',
  components: { MyDialog },
  data() {
    return {
      dialog: {
        isShow: false,
      },
      reg: REGISTER,
    };
  },
  computed: {
    ...mapGetters(['tokenData']),
  },
  watch: {
    tokenData: {
      handler(v) {
        if (!v.tourist) {
          this.close();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    close() {
      this.dialog.isShow = false;
    },
    show() {
      this.dialog.isShow = true;
    },
    handleClick(type) {
      this.$emit('clickHandle', type);
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #eacc94;
  position: relative;
  height: 380px;
}

.vipImg {
  width: 400px;
  position: absolute;
  top: -39%;
  left: 13%;
}

.content {
  padding: 100px 30px 80px 30px;
}
</style>
