<template>
  <MyDialog :diaVisible="dialogIsShow" width="350px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="avatarBox">
        <div class="top">修改密码</div>
        <el-form :model="dataForm">
          <!--          <el-form-item>-->
          <!--            <el-input v-model="dataForm.name" placeholder="当前密码"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <el-input v-model="dataForm.password" type="password" placeholder="新密码"></el-input>
          </el-form-item>
          <el-form-item class="codeFormContainer">
            <el-row :gutter="10">
              <el-col :span="14">
                <el-input
                    v-model="dataForm.emailCode"
                    placeholder="邮箱验证码"
                    style="width: 100%;"
                />
              </el-col>
              <el-col :span="10">
                <CountDowm ref="countDowmRef">
                  <template slot-scope="{disabled,massage}">
                    <el-button
                        style="width: 100%"
                        type="primary"
                        :disabled="!dataForm.password"
                        @click="sendCode"
                        class="codeBtn"
                    >
                      {{ massage }}
                    </el-button>
                  </template>
                </CountDowm>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <el-button type="primary" class="save" @click="save">保存</el-button>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { getEmailCode } from '@/api';
import CountDowm from '@/components/countDowm';
import { mapActions } from 'vuex';

export default {
  name: 'NicknameAmend',
  components: { CountDowm, MyDialog },
  data() {
    return {
      dialogIsShow: false,
      dataForm: {
        name: '',
        password: '',
        emailCode: '',
      },
    };
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    close() {
      this.dialogIsShow = false;
    },
    show() {
      this.dialogIsShow = true;
    },
    sendCode() {
      getEmailCode({ email: this.dataForm.email })
        .then((res) => {
          if (res.code === 200) {
            this.$refs.countDowmRef.getCode();
          }
        });
    },
    async save() {
      await this.changeUserInfo({ password: this.dataForm.password, emailCode: this.dataForm.emailCode });
      this.close();
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
}

.avatarBox {
  text-align: center;

  .top {
    font-size: 16px;
    color: #fff;
    margin-bottom: 40px;
  }

  .save {
    width: 100%;
    margin-top: 30px;
  }
}

.el-input {
  /deep/ .el-input__inner {
    background-color: #0c0c0d;
    border-color: #0c0c0d;
    color: #bababa;
  }
}
</style>
