<template>
  <Popup v-model="popupShow"  position="bottom"  :closeable="true" :stylePopup="{height:'52%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('header.Curriculum')">
    <van-tabs type="card" :ellipsis="false" style="height: 100%;overflow: auto">
      <van-tab  v-for="(item,index) in weekDateList" :key="index">
        <template #title>
          <p>{{$t(fomartDate(item, 'dd'))}}</p>
          <p>{{ fomartDate(item, 'MM-DD') }}</p>
        </template>
        <div class="H5_courseList">
          <div class="H5_courseList_item" v-for="item in courseData[item]" :key="item.id">
            <img :src="item.cover" class="H5_courseList_item-cover"/>
            <div class="H5_courseList_item_info">
              <div class="H5_courseList_item_info-time">
                {{ item.beginTime }} - {{ item.endTime }}
                <el-tag v-if="item.facilitator" effect="plain" size="small">{{ item.facilitator }}</el-tag>
              </div>
              <div class="H5_courseList_item_info-teacherName">
                {{ item.teacherName }}
              </div>
<!--              <div class="H5_courseList_item_info-Introduction">-->
<!--                {{ item.remark }}{{ item.remark }}{{ item.remark }}-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </Popup>
</template>

<script>
import Popup from '@/components/popup/popup';
import { getCourseList } from '@/api';
import { fomartDate } from 'utils/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'ClassScheduleModel.vue',
  components: {
    Popup,
  },
  data() {
    return {
      popupShow: false,
      weekDateList: [],
      courseData: {},
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  methods: {
    fomartDate,
    getCourseInfo(id) {
      getCourseList({ roomId: id })
        .then((res) => {
          if (res.code === 200) {
            this.weekDateList = Object.keys(res.data).sort();
            this.courseData = res.data;
            console.log(this.courseData, this.weekDateList, 'kkjhjghfd');
          }
        });
    },
    overlayShow() {
      this.popupShow = true;
      this.getCourseInfo(this.currentRoomId);
    },
    overlayHide() {
      this.popupShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .van-tabs__nav{
    background-color:#0C0C0D;
  }
  .van-tabs--card > .van-tabs__wrap, .van-tabs__nav--card {
    height: 50px;
  }
  .van-tabs__nav--card {
    border: 1px solid #0C0C0D;
    .van-tab.van-tab--active {
      color: #D51820;
      background-color: #712327;
      border-radius: 5px;
    }
    .van-tab{
      color: #fff;
      border-right: none;
    }
  }
}
.H5_courseList {
  //overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 12px;
  //padding-top: 20px;
  padding: 20px 10px;

  &_item {
    //width: 170px;
    border-radius: 8px;
    //overflow: hidden;
    font-size: 0;

    &-cover {
      height: 100px;
      width: 100%;
      opacity: 1;
      border-radius: 6px 6px 0px 0px;
    }

    &_info {
      border-radius: 0 0 8px 8px;
      padding: 16px 9px 16px;
      //background: rgba(113,35,39,0.39);
      background: #3a1b20;
      border: 0.5px solid #C3312C;
      border-top: none;

      &-time {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        min-height: 24px;
        color: #FFFFFF;
        margin-bottom: 8px;

        /deep/ .el-tag {
          background-color: transparent;
          margin-left: 4px;
          font-size: 12px;
          font-weight: 300;
          color: #FFFFFF;
          border: 1px solid rgba(213, 24, 32, 0.502);
        }
      }

      &-teacherName {
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        color: #D51820;
        margin-bottom: 4px;
      }

      &-Introduction {
        //width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        //min-height: 16px;
        height: 34px;
        color: #BABABA;
      }
    }
  }
}
</style>
