export default class CustomerService {
  constructor() {
    this.run();
  }

  init(lang) {
    // window._join_name_used = '_echat';
    // window._echatServer = ['e.echatsoft.com'];
    // window._echat = window._echat || function () {
    //   (window._echat.q = window._echat.q || []).push(arguments);
    // };
    // window._echat.l = +new Date;
    // window._echat('initParam', { companyId: 531580, lan: lang, echatTag: `live-${lang}` });
  }

  run() {
    // const joinJs = document.createElement('script');
    // joinJs.type = 'text/javascript';
    // joinJs.async = true;
    // joinJs.id = '_echatmodulejs';
    // joinJs.setAttribute('charset', 'UTF-8');
    // joinJs.src = `${'https:' == document.location.protocol ? 'https://' : 'http://'}www.echatsoft.com/visitor/join.js`;
    // const s = document.getElementById('customerServiceScript');
    // s.innerHTML = '';
    // // s.parentNode.insertBefore(joinJs, s);
    // s.append(joinJs);
  }
}


