<template>
  <MyDialog :diaVisible="dialogIsShow" width="750px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="detailContainer">
        <div class="title">
          {{ dataForm.title }}
        </div>
        <div class="subTit">
          <span class="time">{{dataForm.author}} {{ dataForm.createTime }}</span>
          <span class="readNum">{{ $t('readNum') }}:{{ readNum }}</span>
        </div>
        <div class="detailContainer_content" v-html="dataForm.content">
        </div>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { getTradingInsider, getTradingInsiderInfo } from '@/api';
import { mapGetters } from 'vuex';
import { fomartDate } from 'utils/utils';

export default {
  name: 'DealDetailModel',
  components: { MyDialog },
  data() {
    return {
      dialogIsShow: false,
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
    readNum() {
      return (this.dataForm.readNum || 0) + (this.dataForm.initReaderNum || 0);
    },
  },
  methods: {
    getTradingInsider,
    fomartDate,
    close() {
      this.dialogIsShow = false;
    },
    show(id) {
      this.dialogIsShow = true;
      getTradingInsiderInfo({ id })
          .then((res) => {
            this.dataForm = res.data;
          });
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
  position: relative;
}

.detailContainer {
  padding: 0 20px;

  .title {
    font-size: 30px;
    font-weight:bold;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  .subTit {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    color: #BABABA;
    display: flex;
    justify-content: space-between;
  }

  &_content {
    //word-break: break-all;
    height: 400px;
    overflow-y: auto;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
