// 提示信息
// import { Message } from 'element-ui';
import { localStorage } from 'utils/storage';
import _eventBus from 'utils/_eventBus';
import { MESSAGETYPE } from '@/components/chat/constants/chat';
import {checkIpStatus} from "@/api";
// import i18n from '@/locales/i18n';

const url = process.env.NODE_ENV === 'production' ? 'wss://ychuipro.com/ws' : 'ws://localhost:9326/ws';

let ws;
let tt;
let lockReconnect = false; // 避免重复连接

let params = {};
const websocket = {
// 建立连接
  Init(roomId) {
    const token = localStorage.get('token'); // cookies中获取token值
    if (roomId) {
      params = { roomId };
    }

    if ('WebSocket' in window) {
      if (!ws || ws.readyState === 0) {
        ws = new WebSocket(`${url}/?token=${token}&room_id=${params.roomId}`);
      } else {
        console.log('=====多次init===');
      }
    } else {
      // console.log('您的浏览器不支持 WebSocket!')
      return;
    }
    // websocket 生命周期根据websocket状态自己会执行
    // websocket 成功 失败 错误 断开 这里会自动执行
    // 这个方法后端通过send调用 这个方法会执行和接收参数
    ws.onmessage = function (e) {
      heartCheck.start();
      if (e.data === 'pong') {
        // 心跳消息不做处理
        return;
      }
      const datas = JSON.parse(e.data);
      console.log(datas.messageType, 'messageType===>');
      switch (datas.messageType) {
        case MESSAGETYPE.WELCOME:
        case MESSAGETYPE.FULL_SCREAM:
        case MESSAGETYPE.SYSTEM_NOTICE:
          _eventBus.$emit(MESSAGETYPE.FULL_SCREAM, datas);
          break;
        case MESSAGETYPE.TRADE_SIGNAL_UPDATE:
          _eventBus.$emit(MESSAGETYPE.TRADE_SIGNAL_UPDATE, datas);
          break;
        case MESSAGETYPE.AV_CHAT_ROOM:
          _eventBus.$emit('wsMessage', datas);
          break;
        case MESSAGETYPE.PRIVATE_MESSAGE:
          _eventBus.$emit(MESSAGETYPE.PRIVATE_MESSAGE, datas);
          break;
        case MESSAGETYPE.MESSAGE_REVIEW_RESULT:
          if(datas.reviewStatus === 'DELETE'){
            _eventBus.$emit('deleteMessage', datas);
          }
          break;
        default:
          console.log(datas.messageType, 'messageType==无匹配');
      }

      // Message({
      //   message: e.data,
      //   type: 'success',
      // });
      // messageHandle(e);
    };
    ws.onclose = function (event) {
      console.log('连接已关闭',event);
      //校验是否是ip被封

      checkIpStatus().then(res=>{
        console.log(res)
        if(!res.data.ipIsEffective){
          console.log('ip被封');
          window.location.href = 'invalid.html?ip='+res.data.ip
        }
      })

      // Message({
      //   message: '直播间连接已关闭',
      //   type: 'error',
      // });
      reconnect();
    };
    ws.onopen = function () {
      // console.log('连接成功')
      // Message({
      //   message: '聊天室加载成功',
      //   type: 'success',
      // });
      heartCheck.start();
    };

    ws.onerror = function (e) {
      console.log(e);
      console.log('数据传输发生错误');
      // Message({
      //   message: i18n.t('socketConnectionError'), type: 'error',
      // });
      reconnect();
    };
  }, // 我们单独写了一个方法 调用ws的关闭方法，这样就可以在退出登录的时候主动关闭连接
  // 关闭连接
  onClose() {
    if (ws) {
      console.log('主动关闭连接！');
      // 关闭websocket连接和关闭断开重连机制
      lockReconnect = true;
      // 调用 上面的websocket关闭方法
      ws.close();
      ws = null
    }
  }, // 前端的send给后端发信息
  Send(data) {
    // console.log(data);
    const msg = JSON.stringify(data);
    // console.log('发送消息：' + msg)
    ws.send(msg);
  }, // 返回ws对象
  getWebSocket() {
    return ws;
  }, // websocket 自带的状态码意思提示
  getStatus() {
    if (ws.readyState == 0) {
      return '未连接';
    }
    if (ws.readyState == 1) {
      return '已连接';
    }
    if (ws.readyState == 2) {
      return '连接正在关闭';
    }
    if (ws.readyState == 3) {
      return '连接已关闭';
    }
  },
};

// // 刷新页面后需要重连
// if (window.performance.navigation.type == 1 && token != null) {
//   // 刷新后重连
//   // reconnect(clientId);
//   websocket.Init();
//   // 如果websocket没连接成功，则开始延迟连接
//   if (ws == null) {
//     reconnect();
//   }
// }

export default websocket;

// 根据消息标识做不同的处理
// function messageHandle(message) {
//   const msg = JSON.parse(message);
//   switch (msg.flag) {
//     case 'command':
//       // console.log('指令消息类型')
//       break;
//     case 'inform':
//       // console.log('通知')
//       break;
//     default:
//         // console.log('未知消息类型')
//   }
// }
// 重连方法 刷新页面 连接错误 连接关闭时调用
function reconnect(sname) {
  if (lockReconnect) {
    return;
  }
  lockReconnect = true;
  // 没连接上会一直重连，设置延迟避免请求过多
  tt && clearTimeout(tt);
  tt = setTimeout(() => {
    console.log('执行断线重连...');
    websocket.Init(sname);
    lockReconnect = false;
  }, 4000);
}

// 心跳检测 跟后端是对应的 会进行处理
// 连接成功 和后端推消息时调用
const heartCheck = {
  timeout: 1000 * 3, timeoutObj: null, serverTimeoutObj: null, start() {
    // console.log('开始心跳检测');
    const self = this;
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    this.timeoutObj = setTimeout(() => {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage拿到返回的心跳就说明连接正常
      // console.log('心跳检测...');
      if (!ws) return;
      ws.send('ping');
      self.serverTimeoutObj = setTimeout(() => {
        if (ws.readyState != 1) {
          ws.close();
        }
        // createWebSocket();
      }, self.timeout);
      this.start();
    }, this.timeout);
  },
};
