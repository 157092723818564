<template>
  <MyDialog :width="'800px'" :diaVisible="dialog.isShow" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="bannerContainer">
        <LuckyWheel
            style="margin: 0 auto"
            ref="myLucky"
            width="500px"
            height="500px"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
        />
      </div>
      <div style="text-align: center;color: #fff;font-size: 18px;margin-top: 30px;font-weight: 600">
        <span style="color: #84A3C8;font-weight: 600">--</span> 活动规则 <span style="color: #84A3C8;font-weight: 600">--</span>
      </div>
      <div class="desc ql-editor" v-html="descript"></div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import {mapGetters} from 'vuex';
import {doActionLucky, getBanners, getLuckyActivityInfo} from '@/api';

export default {
  name: 'luckyModel',
  components: {MyDialog},
  data() {
    return {
      dialog: {
        isShow: false,
      },
      blocks: [],
      prizes: [],
      buttons: [],
      descript:'',
      activityId:'',

    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  mounted() {
    console.log('lucky model mounted')
  },
  created() {
    console.log('lucky model created')
  },
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback () {
      // 调用抽奖组件的play方法开始游戏

      doActionLucky({
        activityId:this.activityId
      }).then(res=>{
        let data = res
        if(data.code === 401){
          layer.msg('请先注册登录后才可以参与哦');
          this.$eventBus.$emit('showLoginCard')
          return
        }
        if(data.code === 200){
          this.$refs.myLucky.play()
          const index = this.prizes.findIndex(item=>item.id === data.data)
          // 调用stop停止旋转并传递中奖索引
          this.$refs.myLucky.stop(index)
        }
      })
    },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      console.log(prize)
      layer.alert( prize.message,{
        btn: ['联系客服'],
        btn1: function(){
          window.open('https://t.me/XC600broker1', '_blank');
        }
      });
    },

    // 关闭dialog
    close() {
      this.dialog.isShow = false;
    },
    // 确定dialog
    show(type) {
      this.dialog.isShow = true;
      console.log('roomId===>',this.currentRoomId)

      getLuckyActivityInfo({
        roomId:this.currentRoomId
      }).then(res=>{
        let activity = res.data

        this.activityId = activity.id


        this.descript = activity.descript
        let gifts = activity.giftList
        let blocksConfig = JSON.parse(activity.blocksConfig)
        this.blocks = blocksConfig.pc

        let buttonsConfig = JSON.parse(activity.buttonsConfig)
        this.buttons = buttonsConfig.pc
        let prizesConfig = JSON.parse(activity.prizesConfig).pc
        let prizes = []
        gifts.forEach(item=>{
          let fonts = {}
          let imgs = {}
          fonts['text'] = item.name
          fonts['fontColor'] = item.fontColor
          fonts['fontSize'] = prizesConfig.fontSize
          fonts['top'] = prizesConfig.textTop
          fonts['fontWeight'] = prizesConfig.fontWeight
          fonts['fontWeight'] = prizesConfig.fontWeight
          fonts['wordWrap'] = prizesConfig.wordWrap

          imgs['src'] = item.image
          imgs['width'] = prizesConfig.width
          imgs['top'] = prizesConfig.imgTop
          prizes.push({
            id:item.id,
            range:item.winRange,
            message:item.message,
            background: item.background,
            fonts:[fonts],
            imgs:[imgs]
          })
        })
        this.prizes = prizes
      })

    },
    openTab(data) {

    },
  },
};
</script>

<style scoped lang="less">

.desc{
  padding: 20px;
  overflow: auto;
  word-wrap: break-word;
  color: #fff;
  max-height: 350px;
}

/deep/ .el-dialog {
  position: relative;
  background: rgba(34, 43, 63, 0.8);
  box-shadow: 0 0 20px 0 rgba(64, 158, 255, 0.8);

  .el-dialog__body {
  }
}

.container {
  height: 800px;
  //width: 800px;
}

.bannerContainer {
  //width: 1200px;
  height: 500px;

  img {
    height: 100%;
    object-fit: contain;
  }

  .content {
    text-indent: 2em;
    font-size: 16px;
    color: #fff;
    line-height: 34px;
  }

  .title {
    color: #fff;
    text-align: center;
  }
}

/deep/ .el-carousel {
  //height: 190px;
  height: 100%;

  .el-carousel__container {
    height: 100%;

    .el-carousel__item {

      .bannerItemBox {
        position: relative;
        width: 100%;
        height: 100%;

        .title {
          bottom: 0px;
          left: 0;
          right: 0;
          position: absolute;
          height: 40px;
          text-indent: 20px;
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #fff;
          background: rgba(0, 0, 0, 0.6);
          z-index: 1111;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      //  //display: flex;
      //  //flex-direction: column;
      //  //justify-content: center;
      //  cursor: pointer;
      //

    }
  }

  .el-carousel__indicator {
    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }

    //padding: 0px;

  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  //background-color: #d3dce6;
}
</style>
