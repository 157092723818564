<template>
  <MyDialog :diaVisible="dialogIsShow" width="350px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="avatarBox">
        <div class="top">修改头像</div>
        <el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div class="avatarText" v-if="imageUrl">上传头像</div>
        </el-upload>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { upLoadImg } from '@/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TipsModel',
  components: { MyDialog },
  data() {
    return {
      dialogIsShow: false,
      imageUrl: '',
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    close() {
      this.$emit('updateUserInfo', '');
      this.dialogIsShow = false;
    },
    show() {
      this.dialogIsShow = true;
    },
    async save() {
      console.log(this);
      await this.changeUserInfo({ avatar: this.imageUrl });
      this.close();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是JPG，png格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      const formData = new FormData();
      formData.append('file', file);
      upLoadImg(formData)
          .then((res) => {
            console.log(res);
            this.imageUrl = res.data.url;
          });
      // return isJPG && isLt2M;
      return false;
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
}

.avatarBox {
  text-align: center;

  .top {
    font-size: 16px;
    color: #fff;
    margin-bottom: 40px;
  }

  .el-button {
    width: 100%;
    margin-top: 50px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  border: 1px solid #8c939d;
  border-radius: 6px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.avatarText {
  margin-top: 30px;
  color: #fff;
  background-color: #0c0c0d;
  padding: 3px 0;
  font-size: 12px;
  border-radius: 6px;
}
</style>
