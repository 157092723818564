<template>
  <!--  :style="`height: ${chatHeight?chatHeight+'px':'100%'}`"-->
  <div class="H5_right" ref="rightRef">
    <div class="H5_chatListContainer">
      <ChatList :isScroll="isScroll" ref="chatListRef" :chatList="chatList"/>
      <div class="H5_textContainer">
        <div class="H5_toolsContainer">
          <el-popover
              v-model="emojiContainerVis"
              placement="top"
              width="400"
              trigger="click"
              class="H5_toolsContainer_popover"
          >
            <div class="H5_emojiContainer">
              <img
                  @click="addEmoji(`${emojiUrl}${item}`)"
                  :src="`${emojiUrl}${item}`"
                  v-for="item in emojiMap"
                  :key="item"
              />
            </div>
            <img :src="emojIcon" slot="reference" class="H5_toolsContainer_icon"/>
          </el-popover>
          <el-upload
              action=""
              class="H5_toolsContainer_popover"
              accept="image/*"
              :before-upload="beforeUpload"
          >
            <img :src="imgIcon" class="H5_toolsContainer_icon"/>
          </el-upload>
          <img :src="zanIcon" @click="sendZandAdnFlower(MESSAGETYPE.ZAN)" class="H5_toolsContainer_icon"/>
          <img :src="flowerIcon" @click="sendZandAdnFlower(MESSAGETYPE.FLOWERS)" class="H5_toolsContainer_icon"/>
          <div class="H5_toolsContainer_admin" @click="clearChatMsgs">
            <i class="el-icon-delete"></i>
            {{ $t('chatList.cls') }}
          </div>
          <div class="H5_toolsContainer_admin noneBorder">
            <el-checkbox v-model="isScroll">{{ $t('chatList.scroll') }}</el-checkbox>
          </div>
        </div>
        <div class="H5_inputContainer">
          <ChatInput
              ref="chatInputRef"
              :upLoadHandle="upLoadImg"
              :editor-style="{width: '100%'}"
              @enter="sendEnter"
              @change="handleChatInputChange"
          />
        </div>
        <CountDowm
            @timeEnd="timeEnd"
            :countNum="SEND_MESSAGE_COUNTDOWN" ref="countDowmRef"
            :downMsg="`chatList.send`">
          <template slot-scope="{disabled}">
            <el-button
                ref="sendBtnRef"
                @keyup.e.enter="handleChatSend"
                class="H5_sendBtn"
                type="primary"
                :disabled="disabled"
                @click="handleChatSend"
            >
              {{ $t('chatList.send') }}
            </el-button>
          </template>
        </CountDowm>
        <!--        <div class="H5_sendBtn" @click="handleChatSend">-->
        <!--          {{ $t('chatList.Send') }}-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import ChatInput from '@/payerH5/components/appChat/chatInput.vue';
import emojIcon from './img/emoj-icon.png';
import imgIcon from './img/img-icon.png';
import zanIcon from './img/zan-icon.png';
import flowerIcon from './img/flower-icon.png';
// import BScroll from '@better-scroll/core';
// import Pullup from '@better-scroll/pull-up';
// import PullDown from '@better-scroll/pull-down';
// import mouseWheel from '@better-scroll/mouse-wheel';
import ChatList from '@/payerH5/components/appChat/chatList';
import {imHistoryMessage, upLoadImg} from '@/api';
import socket from 'utils/socket';
import { mapGetters, mapMutations } from 'vuex';
import { emojiFlower, emojiMap, emojiUrl, emojiZan } from './emojiMap.js';
import { ADD_CHAT_HISTORY, ADD_CURRENT_USER_CHAT_MESSAGE, CLEAR_CHAT_HISTORY, MESSAGETYPE } from './constants/chat';
import CountDowm from '@/components/countDowm';
import { SEND_MESSAGE_COUNTDOWN } from 'constants/sys';
// BScroll.use(Pullup);
// BScroll.use(mouseWheel);
// BScroll.use(PullDown);


export default {
  name: 'appChatContainer',
  components: { ChatList, ChatInput, CountDowm },
  props: {
    isAdmin: {
      type: [Boolean],
      default: false,
    },
    chatList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      SEND_MESSAGE_COUNTDOWN,
      isTimeEnd: true,
      emojIcon,
      imgIcon,
      zanIcon,
      flowerIcon,
      tabKey: 'first',
      MESSAGETYPE,
      emojiUrl,
      emojiMap,
      systemMsg: false,
      flyScreenMsg: false,
      isScroll: true,
      chatHeight: 0,
      emojiContainerVis: false,
      chatInputValue: [],

    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'roomInfo', 'userInfo', 'tokenData']),
  },
  mounted() {
    const contentBox = document.querySelector('#contentBox');
    const obs = new ResizeObserver(() => {
      this.chatHeight = contentBox.clientHeight;
    });
    obs.observe(contentBox);

    this.$eventBus.$on('wsMessage', this.wsMessageHandle);
  },
  watch:{
    currentRoomId:function (roomId){
      this[CLEAR_CHAT_HISTORY]
      if(roomId){
        this.getMessageHistory()
      }
    }
  },
  destroyed() {
    try {
      this.$eventBus?.off('wsMessage', this.wsMessageHandle);
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    ...mapMutations([
      ADD_CHAT_HISTORY,
      ADD_CURRENT_USER_CHAT_MESSAGE,
      CLEAR_CHAT_HISTORY,
    ]),
    wsMessageHandle(e) {
      console.log('e.roomId===>',e.roomId)
      this[ADD_CHAT_HISTORY]({ type: 1, list: [e] });
      this.$nextTick(() => {
        this.$refs.chatListRef.scrollToEnd();
      });
    },
    getMessageHistory(){
      imHistoryMessage({
        roomId:this.currentRoomId,
        pageNum:1,
        pageSize:20
      }).then(res=>{
        this[ADD_CHAT_HISTORY]({ type: 1, list: res.rows });
        this.$nextTick(() => {
          setTimeout(()=>{
            this.$refs.chatListRef.scrollToEnd();
          },1000)
        });
      })
    },
    timeEnd() {
      this.isTimeEnd = true;
    },
    sendEnter() {
      this.$refs.sendBtnRef.$el.click();
    },
    upLoadImg,
    showEmoji() {
      this.emojiContainerVis = true;
    },
    adminHandle(type, val) {
      console.log(type, val);
      this.$emit(type, val);
    },
    createMessageBody(type, body) {
      return {
        messageType: type,
        messageBody: body,
      };
    },
    socketSend(messageBody, type) {
      if (this.isTimeEnd) {
        const sendData = {
          userId: '',
          zanAndFlowers: type === MESSAGETYPE.ZAN || type === MESSAGETYPE.FLOWERS,
          messageBodys: messageBody,
        };
        socket.Send(sendData);
        this.$refs.countDowmRef.getCode();
        this.$emit('sendMsg', sendData);
        this.isTimeEnd = false;
      }
    },
    // 发送点赞|送花
    sendZandAdnFlower(type) {
      const message = [this.createMessageBody(type, type === MESSAGETYPE.ZAN ? emojiZan : emojiFlower)];
      this.socketSend(message, type);
    },
    // 清屏
    clearChatMsgs() {
      this[CLEAR_CHAT_HISTORY]();
    },
    beforeUpload(file) {
      this.$refs.chatInputRef.focus();
      this.$nextTick(() => {
        this.$refs.chatInputRef.uploadChatImg(file);
      });
      return false;
    },
    handleChatSend() {
      if (this.chatInputValue.length) {
        console.log(this.chatInputValue, 'this.chatInputValue');
        this.socketSend(this.chatInputValue);
        // this[ADD_CURRENT_USER_CHAT_MESSAGE](this.chatInputValue);
        this.chatInputValue = [];
        this.$refs.chatInputRef.clean();
      }
    },
    addEmoji(emojUrl) {
      this.emojiContainerVis = false;
      this.$refs.chatInputRef.focus();
      this.$nextTick(() => {
        this.$refs.chatInputRef.insertEmoj(emojUrl);
      });
    },
    handleChatInputChange(value) {
      console.log('输入框的值变化', value);
      this.chatInputValue = value ?? [];
    },
  },
};
</script>

<style scoped lang="less">
.H5_right {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #282828;
  opacity: 1;

  .tabsContainer {
    background: rgba(33, 34, 34);

    /deep/ .el-tabs__header {

      padding: 6px 0;
      margin-bottom: 0;

      .el-tabs__item {
        color: #767676;
      }

      .is-active {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 59px;
          height: 3px;
          border-radius: 2px;
          bottom: 0px;
          margin: 0 auto;
          display: inline-block;
          background: #fff;
        }
      }

      .el-tabs__active-bar, .el-tabs__nav-wrap::after {
        height: 0;
      }
    }

  }

  .introduceContainer {
    padding: 24px 20px;

    .name {
      margin-bottom: 21px;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      color: #D51820;
    }

    .introduce {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #FFFFFF;
    }
  }
}

.H5_chatListContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;


  .H5_textContainer {
    position: relative;
    background: #212222;

    .H5_toolsContainer {
      display: flex;
      align-items: center;
      height: 40px;
      display: flex;
      padding: 0 20px;
      border-bottom: 1px solid #393939;

      &_popover {
        width: 22px;
        height: 22px;
        margin-right: 16px;
      }

      &_admin {
        padding: 2px 7px;
        border: 1px solid #767676;
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
        margin-right: 12px;

        /deep/ .el-checkbox__label {
          color: #FFFFFF;
          font-weight: 300;
        }
      }
      &_admin:last-child{
        margin-right: 0px;
      }

      .noneBorder {
        border: none;
      }

      &_icon {
        width: 22px;
        height: 22px;
        margin-right: 16px;
        cursor: pointer;
      }
    }

    .H5_inputContainer {
      height: 80px;
      padding: 10px;
      overflow-y: auto;
      color: #fff;

      &:focus {
        outline: 0px solid red;
      }

      &:focus-visible {
        outline: 0px solid green;
      }

      ///deep/ .w-e-scroll {
      //  .w-e-textarea-1 {
      //    height: 300px;
      //    overflow: auto;
      //  }
      //}
    }

    .H5_sendBtn {
      position: absolute;
      bottom: 16px;
      right: 20px;
      display: inline-block;
      padding: 8px 23px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      border-radius: 2px;
      border: none;
    }
  }

}

.H5_emojiContainer {
  img {
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer;
  }
}

</style>
