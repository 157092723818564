<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'50%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('fixRight.Customer')">
    <div class="H5_detailContainer">
      <img :src="roomInfo.qrCode"/>
      <div class="content" v-html="lastSignalData.content"></div>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex';
import { fomartDate } from 'utils/utils';
import Popup from '@/components/popup/popup';

export default {
  name: 'dealDetailModel',
  components: { Popup },
  data() {
    return {
      popupShow: false,
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'roomInfo', 'lastSignalData']),
  },
  methods: {
    fomartDate,
    overlayShow() {
      this.popupShow = true;
      // getTradingInsiderInfo({ id })
      //   .then((res) => {
      //     this.dataForm = res.data;
      //   });
    },
    overlayHide() {
      this.popupShow = false;
    },
  },
};
</script>

<style scoped lang="less">
.H5_detailContainer {
  padding: 20px;
  text-align: center;

  img {
    width: 150px;
  }

  .content {
    margin-top: 20px;
    max-height: 100px;
    overflow: auto;
  }
}
</style>
