<template>
  <MyDialog :diaVisible="dialogIsShow" width="350px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="avatarBox">
        <div class="top">修改昵称</div>
        <el-input v-model="nickName" :maxlength="5" placeholder="请输入内容"></el-input>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NicknameAmend',
  components: { MyDialog },
  data() {
    return {
      dialogIsShow: false,
      nickName: '',
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    close() {
      this.dialogIsShow = false;
      // this.$emit('changeAmend', '');
    },
    show() {
      this.dialogIsShow = true;
    },
    async save() {
      await this.changeUserInfo({ nickName: this.nickName });
      this.close();
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
}

.avatarBox {
  text-align: center;

  .top {
    font-size: 16px;
    color: #fff;
    margin-bottom: 40px;
  }

  .el-button {
    width: 100%;
    margin-top: 50px;
  }
}

.el-input {
  /deep/ .el-input__inner {
    background-color: #0c0c0d;
    border-color: #0c0c0d;
    color: #bababa;
  }
}
</style>
