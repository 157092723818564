<template>
  <MyDialog width="1044px" :diaVisible="visible" :noFooter="true" @close="close">
    <div class="courseContainer">
      <div class="headerContainer">
        <el-radio-group v-model="radio1">
          <el-radio-button :label="fomartDate(item, 'YYYY-MM-DD')" v-for="(item,index) in weekDateList" :key="index">
            <div class="btnContainer">
              <div class="btnContainer_week">{{ $t(fomartDate(item, 'dd')) }}</div>
              <div class="btnContainer_day">{{ fomartDate(item, 'MM-DD') }}</div>
            </div>
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-tabs type="border-card" style="height: inherit;" tab-position="left" v-model="activeUserId">
        <el-tab-pane v-for="(item,index) in pickUpSignalModelData[radio1]" :key="item.userId" :name="item.userId">
          <div slot="label" class="pickUpSignalModel_tabPan">
            <img :src="item.avatar || logo" class="teacherImg">
            <div class="pickUpSignalModel_tabPan_info">
              <div class="name">{{ item.nickName }}</div>
              <div class="intor">{{ item.profile }}</div>
            </div>
          </div>
          <div class="tradeSignalList">
            <div class="tradeSignalList_item" :key="index" v-for="(listItem,index) in item.tradeSignalList">
              <div class="time">{{ listItem.createTime }}</div>
              <div class="category">{{ listItem.varieties }}</div>
              <div class="content">
                <span class="tradeTime">{{ listItem.tradeDateTime }}</span>
                <span class="txt" v-html="listItem.content"></span>
              </div>
              <div class="warningText">{{ $t('pickUpSignal.warningText') }}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog';
import logo from '@/assets/img/avatar.png';
import { getSignalList } from '@/api';
import { mapGetters } from 'vuex';
import { fomartDate } from 'utils/utils';

export default {
  name: 'pickUpSignalModel',
  components: { MyDialog },
  data() {
    return {
      logo,
      visible: false,
      weekDateList: [],
      pickUpSignalModelData: {},
      radio1: fomartDate(Date.now()),
      activeUserId: '',
    };
  },
  computed: {
    ...mapGetters(['currentRoomId','lastSignalData']),
  },
  methods: {
    fomartDate,
    show() {
      this.visible = true;
      getSignalList({ roomId: this.currentRoomId })
          .then((res) => {
            console.log('getSignalList:',res);
            if (res.code === 200) {
              this.weekDateList = Object.keys(res.data)
                  .sort();
              this.pickUpSignalModelData = res.data;
              this.$nextTick(() => {
                this.radio1 = this.lastSignalData.signalDate || fomartDate(Date.now());
                this.activeUserId = this.lastSignalData.teacherId || this.pickUpSignalModelData[radio1][0].userId;
              });
            }
          });
    },
    close() {
      this.visible = false;
      this.activeUserId = '';
      this.radio1 = '';
    },
  },
};
</script>

<style lang="less">

</style>

<style scoped lang="less">
/deep/ .el-dialog {
  background: #1A1A1C;
  position: relative;
  height: 750px;
}

.courseContainer {
  height: 710px;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  margin-bottom: 32px;

  .btnContainer {
    &_week {
      margin-bottom: 4px;
      line-height: 16px;
      font-size: 14px;
      font-weight: 400;
    }

    &_day {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  /deep/ .is-active {
    .el-radio-button__inner {
      background-color: #712327;
      //border-color: #712327;
      color: #D51820;
      border-radius: 6px;
    }
  }

  /deep/ .el-radio-button__inner {
    background-color: #0C0C0D;
    border-color: #0C0C0D;
    padding: 7px 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #BABABA;
    border: none !important;
  }
}

/deep/ .el-tabs {
  border: none;
  background: #222224;

  .el-tabs__header {
    border: none;
  }

  .el-tabs__content {
    height: 100%;
    overflow: auto;
  }

  .el-tabs__nav-wrap {
    background: #0D0D0E;

    .el-tabs__nav-scroll {


    }

    .el-tabs__item {
      height: 104px;
      display: flex !important;
      align-items: center;
      //justify-content: center;
      border: none !important;

      .pickUpSignalModel_tabPan {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 360px;

        .teacherImg {
          width: 68px;
          height: 68px;
          border-radius: 6px;
          margin-right: 12px;
          object-fit: contain;
        }

        &_info {
          flex: 1;
          text-align: left;
          padding: 8px 0 13px 0;

          .name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: #fff;
            margin-bottom: 8px;
          }

          .intor {
            width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: #767676;
          }
        }
      }
    }

    .is-active {
      background-color: #222224 !important;

      .name {
        color: #D51820 !important;
      }
    }
  }


}

.tradeSignalList {
  //height: 100%;
  //overflow-y: auto;

  &_item {
    margin-bottom: 32px;

    .time {
      margin-bottom: 23px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #767676;
    }

    .category {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #FFFFFF;
    }

    .content {
      display: inline-block;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #BABABA;

      .tradeTime {
        color: #C3312C;
        margin-right: 10px;
      }

      .txt {

      }

    }

    .warningText {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #BABABA;
    }
  }
}


</style>
