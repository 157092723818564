<template>
  <div class="H5_playerH5Box">
    <div class="headerTop">
      <!--    头部-->
      <HeaderApp @menuClick="menuClick"></HeaderApp>
      <!--    <div style="height: 200px;background-color: #1A1A1C">-->
      <!--    <VideoCom-->
      <!--        style="height: 200px;background-color: #1A1A1C"-->
      <!--        :height="200"-->
      <!--        :source="roomInfo.playUrl"-->
      <!--        ref="VueAliplayerV2"-->
      <!--        :options="options"-->
      <!--        :cover="roomInfo.cover"-->
      <!--    />-->
      <!--      {{ roomInfo.rtcPlayerUrl }}-->
      <!--      <RtsVideo
                v-if="roomInfo.rtcPlayerUrl"
                class="rtsVideo"
                :living="roomInfo.living"
                :rts-fallback-source="roomInfo.hlsPlayerUrl"
                :url="roomInfo.rtcPlayerUrl"
                :cover="roomInfo.cover"
            />-->
    </div>
    <video-h5 :height="200" ref="videoH5Ref"/>
    <!--    </div>-->
    <!--    底部标签-->
    <H5Tabs class="H5_playerH5Box-b"></H5Tabs>
    <AppModel ref="appModelRef"></AppModel>
    <div class="footer van-ellipsis" v-if="freeTime >= 0">
      {{ $t('header.Remaining duration') }}：<TimeTest  ref="timeTestRef" :remainTime="freeTime" @countDowmEnd="countDowmEnd"></TimeTest>
    </div>
      <FixRight @openLicky="openLucky" height="70px" width="76px"/>


    <!--    <div class="footer van-ellipsis">
          <van-notice-bar :text="$t('playerFooter.tip')+$t('playerFooter.Risk warning')">

          </van-notice-bar>
        </div>-->


  </div>
</template>

<script>
import AppModel from '@/payerH5/components/model/app';
import HeaderApp from '@/payerH5/components/header/header-app.vue';
import H5Tabs from '@/payerH5/components/h5Tab/h5Tabs.vue';
// import VideoCom from '@/payerH5/components/videoCom/videoCom';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import qs from 'qs';
import { localStorage } from 'utils/storage';
import {checkToken, getLiveRoomInfo} from '@/api';
import { SET_IS_SUPPORT_WEBRTC, SET_TOKEN, UPDATE_LINE_TYPE } from 'constants/mutation-types';
import * as mutationTypes from 'constants/mutation-types';
import {BROADCASTING_ROOM, LOGIN, LUCKY_ACTIVITY, PRIVATE_CHAT, REGISTER, VIP_REGISTER_TIP} from 'constants/sys';
import TRTC from 'trtc-js-sdk';
import { LINE_TYPE } from 'constants/room';
import socket from 'utils/socket';
import {  MESSAGETYPE } from '@/components/chat/constants/chat';
// import RtsVideo from '@/components/rtsVideo';
import VideoH5 from '@/payerH5/components/videoCom/video-player';
import TimeTest from "@/components/time/index.vue";
import FixRight from "@/components/fixRight/index.vue";

export default {
  name: 'playerH5.vue',
  components: {
    FixRight,
    TimeTest,
    // RtsVideo,
    VideoH5,
    HeaderApp,
    H5Tabs,
    AppModel,
    // VideoCom,
  },
  data() {
    return {
      passwordLayerId:undefined,

    };
  },
  computed: {
    ...mapState(['freeTime']),
    ...mapState({
      userInfo: 'userInfo',
      roomId: 'roomId',
      token: 'token',
      tourist: 'tourist',
    }),
    ...mapGetters(['currentRoomId', 'userInfo', 'roomInfo']),
    options() {
      return {
        isLive: true,
        skinLayout: [
          { name: 'bigPlayButton', align: 'cc' },
          {
            name: 'controlBar', align: 'blabs', x: 0, y: 0,
            children: [
              { name: 'playButton', align: 'tl', x: 15, y: 12 },
              { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
            ],
          },
        ],
        // controlBarVisibility: 'always',
        // cover: this.roomInfo.cover,
      };
    },
    ...mapGetters(['roomInfo', 'isUserLogin']),
  },
  watch: {
    token: {
      handler(v) {
        if (v && !this.tourist && !this.userInfo?.userId) {
          this.getUserInfo();
        }
      },
      immediate: true,
      deep: true,
    },
    currentRoomId: {
      handler(v) {
        if (v) {
          this.handlePlayerInfo();
          const roomPassword = sessionStorage.getItem("roomPassword")
          this.getRoomInfo(roomPassword);
        }
      },
      immediate: true,
      deep: true,
    },
    roomInfo: {
      handler(v) {

        layer.close(this.passwordLayerId)
        this.passwordLayerId = null
        //在限制时间段内，被限制播放
        if (v && v.limitPlayer && v.living) {
          // window.ECHAT.customMiniChat();
          this.passwordLayerId = layer.prompt({
            title: this.$t('roomPassword.input title'), formType: 1, shade: 0,
            closeBtn: 0,btn: ['OK'],
          }, (pass, index) => {
            getLiveRoomInfo({id: v.id, password: pass || ''}).then(res => {
              if (res.data.limitPlayer) {
                sessionStorage.removeItem("roomPassword")
                layer.msg(this.$t('roomPassword.password error'));
                setTimeout(()=>{
                  // window.ECHAT.customMiniChat();
                  window.open('https://t.me/XC600broker1', '_blank');

                },2000)
              } else {
                sessionStorage.setItem("roomPassword",pass)
                layer.close(index);
                if(this.freeTime !== 0){
                  this[mutationTypes.SET_ROOM_INFO](res.data);
                }else{
                  const index = layer.alert(this.$t('header.Free time end'),{
                    icon:0,title:'Tips'
                  },()=>{
                    // window.ECHAT.customMiniChat();
                    window.open('https://t.me/XC600broker1', '_blank');

                    layer.close(index)
                  })
                }
              }
            }).catch(err => {
              console.log('err:', err)
            })
          });
        }
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    const { roomId: queryRoomId, source } = qs.parse(window.location.search.replaceAll('?', ''));
    this[mutationTypes.SET_USER_SOURCE](source);
    this.$nextTick(() => {
      const roomId = queryRoomId || localStorage.get('roomId');
      const { tourist, token } = localStorage.get('authData') || {};
      if (!tourist) {
        checkToken()
            .then((res) => {
              if (!res.data) {
                // this.$refs.appModelRef.show(LOGIN);
                this.userLogin({ autoLoad: true });
                this[SET_TOKEN]({ token: '', isTourist: true });
              } else {
                this[SET_TOKEN]({ token, isTourist: tourist });
              }
            });
      } else {
        // this.$refs.appModelRef.show(REGISTER);
        // this.showRegister();
        this[SET_TOKEN]({ token, isTourist: true });
      }
      this[mutationTypes.SET_ROOM_ID](roomId);
      if (!roomId) {
        this.$refs.appModelRef.show(BROADCASTING_ROOM);
      }
    });
  },
  methods: {
    ...mapMutations([mutationTypes.SET_USER_SOURCE]),
    showRegister() {
      setTimeout(() => {
        if (!this.isUserLogin) {
          this.$refs.appModelRef.show(REGISTER);
        }
      }, 60 * 1000);
    },
    countDowmEnd() {
      this.$eventBus.$emit('freeTimeEnd');
    },
    menuClick(type) {
      this.$refs.appModelRef.show(type);
    },
    ...mapActions(['getRoomInfo', 'userLogin', 'getUserInfo', 'getTempUserInfo', 'getLastsignal','getPrivateChatMsgList']),
    ...mapMutations([SET_TOKEN, mutationTypes.SET_ROOM_ID, mutationTypes.SET_FREE_TIME, mutationTypes.SET_ROOM_INFO]),
    // 处理是否支持 webRTC
    async handleSupportWebRTC() {
      const supportResult = await TRTC.checkSystemRequirements();
      const { isWebRTCSupported, isH264DecodeSupported } = supportResult.detail;
      if (!isWebRTCSupported || !isH264DecodeSupported) {
        this.$store.commit(SET_IS_SUPPORT_WEBRTC, false);
        this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
      }
    },
    // 处理页面数据
    handlePlayerInfo() {
      this.getTempUserInfo();
    },
    // 退出直播间
    async handleExit() {
      // todo: 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // todo: 处理退出登录
    },
    headerClick(type) {
      this.$refs.modelRef.show(type);
    },
    handleFreeTimeEnd() {
      // this.$refs.modelRef.show(VIP_REGISTER_TIP);
      // this[mutationTypes.SET_ROOM_INFO]({});
      this[mutationTypes.SET_ROOM_INFO]({ ...this.roomInfo, freeTimeEnd: true });
      this.$refs.videoH5Ref.freeTimeEnd();
      const index = layer.alert(this.$t('header.Free time end'),{
        icon:0,title:'Tips'
      },()=>{
        // window.ECHAT.customMiniChat();
        window.open('https://t.me/XC600broker1', '_blank');

        layer.close(index)
      })
      // this.$refs.modelRef.show(VIP_REGISTER_TIP);
      // this[mutationTypes.SET_ROOM_INFO]({});
      socket.onClose();
    },
    openLucky(){
      this.$refs.appModelRef?.show(LUCKY_ACTIVITY);
    },
  },
  created() {


    // 判断是否支持 webrtc, 不支持 webrtc 的网页采用【线路三：标准直播】播放，且不可切换线路
    this.handleSupportWebRTC();

    // 处理监听事件
    this.$eventBus.$on('freeTimeEnd', this.handleFreeTimeEnd);
    this.$eventBus.$on('showLoginCard', ()=>{
      this.$refs.appModelRef?.show(LOGIN);
    });
    this.$eventBus.$on(MESSAGETYPE.TRADE_SIGNAL_UPDATE, this.getLastsignal);
    this.$eventBus.$on(MESSAGETYPE.PRIVATE_MESSAGE, (data) => {
      // this[ADD_PRIVATE_CHAT_MESSAGE](data)
      this.$nextTick(()=>{
        // this.getPrivateChatMsgList()
        this.$refs.appModelRef?.show(PRIVATE_CHAT,data);

      })
    });


    // this.$eventBus.$on('exit', this.handleExit);
    // this.$eventBus.$on('logout', this.handleLogout);
    // this.$eventBus.$on('showLoginCard', this.handleShowLoginCard);
  },
  beforeDestroy() {
    this.$eventBus.$off('freeTimeEnd', this.handleFreeTimeEnd);
    this.$eventBus.$off(MESSAGETYPE.TRADE_SIGNAL_UPDATE, this.getLastsignal);
    // this.$eventBus.$off('exit', this.handleExit);
    // this.$eventBus.$off('logout', this.handleLogout);
    // this.$eventBus.$off('showLoginCard', this.handleShowLoginCard);
  },
};
</script>

<style>
html, body {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
  background: #f7f7f7;
  flex-direction: column;
}

.van-popup--bottom {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="less" scoped>
.H5_playerH5Box {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;

  .headerTop {
    display: flex;
    //flex: 1;
    flex-direction: column;
  }

  .H5_playerH5Box-b {
    flex: 1;
    display: flex;
    overflow-y: auto;
  }
}

.rtsVideo {
  height: 200px !important;
  flex: unset !important;
}
.fix-right{
  position: absolute;
  top: 280px;
  right: 0;
}
.footer {
  display: flex;
  height: 30px;

  //padding: 0 15px;
  /deep/ .van-notice-bar {
    width: 100%;
    font-size: 14px;
    background: transparent;
    color: #fff;

    .van-notice-bar__wrap {
      /*width: 100%;
      text-align: center;*/
      justify-content: center;
    }
  }
}
</style>
