<template>
  <div class="bannerContainer">
    <el-carousel trigger="click" height="190px">
      <el-carousel-item v-for="(item,index) in banners" :key="index">
        <img @click="openTab(item)" :src="item.imgUrl"/>
        <div v-if="item.title" class="title">{{ item.title }}</div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getBanners } from '@/api';

export default {
  name: 'Banner',
  data() {
    return {
      banners: [],
    };
  },
  computed: {
    ...mapGetters(['currentRoomId']),
  },
  watch: {
    currentRoomId: {
      handler(id) {
        if (id) {
          getBanners({ roomId: id, extraFlag: 'zlb' })
              .then((res) => {
                this.banners = res.data;
              });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openTab(data) {
      if (data.link) {
        window.open(data.link, '_blank');
      }
      // window.location
    },
  },
};
</script>

<style scoped lang="less">
.bannerContainer {
  flex:2;
  //width: 100%;
  //width: 700px;
  margin-right: 12px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

/deep/ .el-carousel {
  height: 190px;


  .el-carousel__container {
    height: 100%;

    //.el-carousel__item {
    //  position: relative;
    //  display: flex;
    //  flex-direction: column;
    //  justify-content: center;
    //  cursor: pointer;
    //
    //  .title {
    //    bottom: 0px;
    //    left: 0;
    //    right: 0;
    //    position: absolute;
    //    height: 40px;
    //    text-indent: 20px;
    //    line-height: 40px;
    //    font-size: 18px;
    //    font-weight: 400;
    //    text-align: left;
    //    color: #fff;
    //    background: rgba(0, 0, 0, 0.6);
    //    z-index: 1111;
    //  }
    //}
  }

  .el-carousel__indicator {
    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
