<template>
  <MyDialog :diaVisible="dialogIsShow" width="750px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="listItemBox">
        <LoadPageList :params="{roomId:currentRoomId}" v-if="dialogIsShow" :reqHandle="getTradingInsider">
          <template slot-scope="{itemData,$index}">
              <div @click="showDetail(itemData)" class="listItem" :key="$index">
                <div class="dealTime">
                  <div class="dealTime-t">{{ fomartDate(itemData.createTime, 'hh:mm') }}</div>
                  <div class="dealTime-b">{{ fomartDate(itemData.createTime, 'YYYY.MM.DD') }}</div>
                </div>
                <div class="listItem-r">
                  <p class="roomTitle">{{ itemData.title }}</p>
                  <div class="describe">{{ itemData.summary }}</div>
                </div>
              </div>
          </template>
        </LoadPageList>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { getTradingInsider } from '@/api';
import { mapGetters } from 'vuex';
import { fomartDate } from 'utils/utils';
import LoadPageList from '@/components/loadPageList/index.vue';

export default {
  name: 'DealModel',
  components: { MyDialog, LoadPageList },
  data() {
    return {
      dialogIsShow: false,
      liveListArr: [],
    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'roomInfo', 'userInfo']),
  },
  methods: {
    getTradingInsider,
    fomartDate,
    showDetail(data) {
      this.$emit('showDetail', data);
    },
    close() {
      this.dialogIsShow = false;
    },
    show() {
      this.dialogIsShow = true;
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
  position: relative;
}

.listItemBox {
  height: 450px;
  overflow-y: auto;
}

.listItem {
  background: #0c0c0d;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 15px;
  cursor: pointer;

  .dealTime {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    margin-right: 12px;
    background-color: #712327;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dealTime-t {
      font-size: 26px;
      color: #fff;
    }

    .dealTime-b {
      font-size: 12px;
      color: #bababa;
    }
  }
}

.listItem-r {
  height: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 0;
  .roomTitle {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
  }

  .describe {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    line-height: 14px;
    color: #bababa;
  }
}
</style>
