<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'70%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('signa.Trading signa')">
    <div class="courseContainer">
      <div class="headerContainer">
        <el-radio-group v-model="radio1">
          <el-radio-button :label="fomartDate(item, 'YYYY-MM-DD')" v-for="(item,index) in weekDateList" :key="index">
            <div class="btnContainer">
              <div class="btnContainer_week">{{ $t(fomartDate(item, 'dd')) }}</div>
              <div class="btnContainer_day">{{ fomartDate(item, 'MM-DD') }}</div>
            </div>
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-tabs type="border-card" style="height: inherit;" tab-position="left" v-model="activeUserId">
        <el-tab-pane v-for="(item,index) in pickUpSignalModelData[radio1]" :key="item.userId" :name="item.userId">
          <div slot="label" class="pickUpSignalModel_tabPan">
            <img :src="item.avatar || logo" class="teacherImg">
            <div class="pickUpSignalModel_tabPan_info">
              <div class="name">{{ item.nickName }}</div>
<!--              <div class="intor">{{ item.profile }}</div>-->
            </div>
          </div>
          <div class="tradeSignalList">
            <div class="tradeSignalList_item" :key="index"
                 v-for="(listItem,index) in item.tradeSignalList">
              <div class="time">{{ listItem.createTime }}</div>
              <div class="category">{{ listItem.varieties }}</div>
              <div class="content">
                <span class="tradeTime">{{ listItem.tradeDateTime }}</span>

                <span class="txt" v-html="listItem.content"></span>
              </div>
              <div class="warningText">{{ $t('pickUpSignal.warningText') }}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </Popup>
</template>

<script>
import logo from '@/assets/img/avatar.png';
import { getSignalList } from '@/api';
import { mapGetters } from 'vuex';
import { fomartDate } from 'utils/utils';
import Popup from '@/components/popup/popup';

export default {
  name: 'pickUpSignalModel',
  components: { Popup },
  data() {
    return {
      logo,
      popupShow: false,
      radio1: fomartDate(Date.now()),
      activeUserId: '',
      weekDateList: [],
      pickUpSignalModelData: {},
    };
  },
  computed: {
    ...mapGetters(['currentRoomId', 'lastSignalData']),
  },
  methods: {
    fomartDate,
    show() {
      this.visible = true;
    },
    overlayShow() {
      this.popupShow = true;
      getSignalList({ roomId: this.currentRoomId })
          .then((res) => {
            console.log(res);
            if (res.code === 200) {
              this.weekDateList = Object.keys(res.data)
                  .sort();
              this.pickUpSignalModelData = res.data;
              this.$nextTick(() => {
                this.radio1 = this.lastSignalData.signalDate || fomartDate(Date.now());
                this.activeUserId = this.lastSignalData.teacherId || this.pickUpSignalModelData[radio1][0].userId;
              });
            }
          });
    },
    overlayHide() {
      this.popupShow = false;
      this.activeUserId = '';
      this.radio1 = '';
    },
  },
};
</script>

<!--<style lang="less" scoped>-->
<!--::v-deep {-->
<!--  .van-tabs__nav{-->
<!--    background-color:#0C0C0D;-->
<!--  }-->
<!--  .van-tabs&#45;&#45;card > .van-tabs__wrap, .van-tabs__nav&#45;&#45;card {-->
<!--    height: 50px;-->
<!--  }-->
<!--  .van-tabs__nav&#45;&#45;card {-->
<!--    border: 1px solid #0C0C0D;-->
<!--    .van-tab.van-tab&#45;&#45;active {-->
<!--      color: #D51820;-->
<!--      background-color: #712327;-->
<!--      border-radius: 5px;-->
<!--    }-->
<!--    .van-tab{-->
<!--      color: #fff;-->
<!--      border-right: none;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->


<style scoped lang="less">
.courseContainer {
  flex: 1;
  overflow: auto;
  //height: 430px;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  margin-bottom: 32px;

  .btnContainer {
    &_week {
      margin-bottom: 4px;
      line-height: 16px;
      font-size: 12px;
      font-weight: 400;
    }

    &_day {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  /deep/ .is-active {
    .el-radio-button__inner {
      background-color: #712327;
      //border-color: #712327;
      color: #D51820;
      border-radius: 6px;

    }
  }

  /deep/ .el-radio-button__inner {
    background-color: #0C0C0D;
    border-color: #0C0C0D;
    padding: 3px 9px;
    min-width: 70px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #BABABA;
    border: none !important;
  }
}

/deep/ .el-tabs {
  border: none;
  background: #222224;
  height: 50%;

  .el-tabs__header {
    border: none;
  }

  .el-tabs__content {
    height: 100%;
    overflow: auto;
  }

  .el-tabs__nav-wrap {
    background: #0D0D0E;

    .el-tabs__nav-scroll {


    }

    .el-tabs__item {
      height: 60px;
      padding: 0 8px;
      display: flex !important;
      align-items: center;
      //justify-content: center;
      border: none !important;

      .pickUpSignalModel_tabPan {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 128px;

        .teacherImg {
          width: 44px;
          height: 44px;
          border-radius: 4px;
          margin-right: 4px;
          //object-fit: contain;
        }

        &_info {
          display: flex;
          flex: 1;
          //width: 68px;
          flex-direction: column;
          text-align: left;
          //padding: 8px 0 13px 0;
          width: 0;

          & > * {

          }

          .name {
            display: inline-flex;
            min-width: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: #D51820;
            margin-bottom: 8px;
          }

          .intor {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            color: #767676;
          }
        }
      }
    }

    .is-active {
      background-color: #222224 !important;

      .name {
        color: #D51820 !important;
      }
    }
  }


}

.tradeSignalList {
  //height: 100%;
  //overflow-y: auto;

  &_item {
    margin-bottom: 32px;

    .time {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #767676;
      opacity: 1;
      margin-bottom: 8px;
    }

    .category {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #FFFFFF;
      opacity: 1;
      margin-bottom: 12px;
    }

    .content {
      display: inline-block;
      height: 56px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #BABABA;

      .tradeTime {
        color: #C3312C;
        margin-right: 5px;
        width: 216px;
        height: 59px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }

      .txt {
        word-break: break-word;
      }

    }

    .warningText {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #BABABA;
    }
  }
}


</style>
