<template>
  <div>
    <!-- 个人中心 -->
    <CenterHome ref="centerHomeRef" @changeAmend="openAmend"></CenterHome>
    <!-- 头像修改 -->
    <AvatarAmend ref="avatarAmendRef" @changeAmend="openAmend"></AvatarAmend>
    <!-- 昵称修改 -->
    <NicknameAmend ref="nicknameAmendRef" @changeAmend="openAmend"></NicknameAmend>
    <!-- 邮箱修改-->
    <EmailAmend ref="emailAmendRef"  @changeAmend="openAmend"></EmailAmend>
    <!-- 密码修改 -->
    <PasswordAmend ref="passwordAmendRef" @changeAmend="openAmend"></PasswordAmend>
  </div>
</template>

<script>
import CenterHome from '@/components/model/pc/userCenter/centerHome.vue';
import AvatarAmend from '@/components/model/pc/userCenter/avatarAmend.vue';
import EmailAmend from '@/components/model/pc/userCenter/emailAmend.vue';
import NicknameAmend from '@/components/model/pc/userCenter/nicknameAmend.vue';
import PasswordAmend from '@/components/model/pc/userCenter/passwordAmend.vue';

export default {
  name: 'userCenter',
  components: {
    AvatarAmend,
    CenterHome,
    EmailAmend,
    NicknameAmend,
    PasswordAmend,
  },
  data() {
    return {
      centerAmendShow: false,
      avatarAmendShow: false,
      emailAmendShow: false,
      passwordAmendShow: false,
      amendName: '',

    };
  },
  methods: {
    show() {
      this.$refs.centerHomeRef.show();
    },
    openAmend(e) {
      // this.amendName = e;
      this.$refs[`${e}Ref`].show();
    },
    ifModelShow(val) {
      if (val == this.amendName) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
</style>
