<template>
  <MyDialog :diaVisible="dialogIsShow" width="350px" @close="close" :noFooter="true">
    <template #dia_Content>
      <div class="avatarBox">
        <div class="top">修改邮箱</div>
        <el-form :model="dataForm">
          <el-form-item>
            <el-input v-model="userInfo.email" disabled placeholder="当前邮箱"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.email" placeholder="新邮箱"></el-input>
          </el-form-item>
          <el-form-item class="codeFormContainer">
            <el-row>
              <el-col span="14">
                <el-input v-model="dataForm.emailCode" placeholder="邮箱验证码" style="width: 100%;"></el-input>
              </el-col>
              <el-col span="10">
                <CountDowm ref="countDowmRef">
                  <template slot-scope="{disabled,massage}">
                    <el-button type="primary" @click="sendCode" :disabled="disabled||!dataForm.email" class="codeBtn">
                      {{ massage }}
                    </el-button>
                  </template>
                </CountDowm>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="save" class="save">保存</el-button>
      </div>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/dialog/myDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import CountDowm from '@/components/countDowm';
import { getCaptchaEmail } from '@/api';

export default {
  name: 'NicknameAmend',
  components: { CountDowm, MyDialog },
  data() {
    return {
      dialogIsShow: false,
      dataForm: {
        name: '',
        email: '',
        emailCode: '',
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    close() {
      this.dialogIsShow = false;
      this.dataForm = {
        name: '',
        email: '',
        emailCode: '',
      };
    },
    show() {
      this.dialogIsShow = true;
    },
    save() {
      this.changeUserInfo(this.dataForm)
        .then((res) => {
          if (res.code === 200) {
            this.close();
          }
        });
    },
    sendCode() {
      getCaptchaEmail({ email: this.dataForm.email })
        .then((res) => {
          if (res.code === 200) {
            this.$refs.countDowmRef.getCode();
          }
        });
    },
  },

};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: #1a1a1c;
}

.avatarBox {
  text-align: center;

  .top {
    font-size: 16px;
    color: #fff;
    margin-bottom: 40px;
  }

  .save {
    width: 100%;
    margin-top: 30px;
  }
}

.el-input {
  /deep/ .el-input__inner {
    background-color: #0c0c0d;
    border-color: #0c0c0d;
    color: #bababa;
  }
}

.codeFormContainer {
  .codeBtn {
    margin-left: 10px;
  }
}

/deep/ .el-form-item {
  margin-bottom: 14px;
}
</style>
